import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Tab, Tabs, Card } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
// import Loading from "../component/Loading";
// import ErrorMessage from "../component/ErrorMessage";

import { useNavigate } from "react-router-dom";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "@davzon/react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  // Button,
  // Row,
  // Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
} from "react-bootstrap";

import BatukLogo from "../images/Batuk-logo.png";

const Register = () => {
  const navigate = useNavigate();

  const [selectedEntityType, setSelectedEntityType] = useState("");
  const [label, setLabel] = useState("Company Type Incorporation no.");

  // Function to handle changes in the dropdown selection
  const handleEntityTypeChange = (e) => {
    const selectedEntityType = e.target.value;
    // Update the label based on the selected entity type
    const newLabel = getLabel(selectedEntityType);
    // Set the label dynamically
    setLabel(newLabel);
  };

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [cityId, setCityId] = useState("");

  // const country = stateList[e.target.value]; // here you will get full country object.
  // setCountryid(country.id);

  // const [values, setValues] = useState({
  //     entityType: "",
  //     entityName: "",
  //     corpNo: "",
  //     entityEmail: "",
  //     password: "",
  //     confirmPass: "",
  //     panNo: "",
  //     gistin: "",
  //     name: "",
  //     email: "",
  //     contact: "",
  //     designation: ""
  // });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  // Function to get the label based on the selected entity type
  const getLabel = (selectedEntityType) => {
    switch (selectedEntityType) {
      case "PivL":
        return "Individual Company Incorporation no.";
      case "PubL":
        return "Sole Proprietorship Limited Company Incorporation no.";
      case "Ltd":
        return "Partnership Company Incorporation no.";
      case "LLP":
        return "Private Limited  Incorporation no.";
      case "Trust":
        return "Public Limited Incorporation no.";
      case "Partnership":
        return "LLP (Limited Liability Partnership) Incorporation no.";
      case "OPC":
        return "Trust Incorporation no.";
      case "OPC":
        return "Society Incorporation no.";
      case "OPC":
        return "Government Incorporation no.";
      case "OPC":
        return "Hindu Undivided Famliy Incorporation no.";
      case "OPC":
        return "Local Authority Incorporation no.";
      case "OPC":
        return "NGO Incorporation no.";
      case "OPC":
        return "One Person Comppany Incorporation no.";
      case "OPC":
        return "Artificial Juridical Person";
      default:
        return "Company Type Incorporation no.";
    }
  };

  const registerUser = async (userData) => {
    try {
      const requestBody = {
        registerType: "entity",
        EntityType: userData.entitytype,
        Entityname: userData.entityname,
        Entityemail: userData.entitymail,
        companyAddress: userData.address,
        EntityidentificationNo: userData.incorpno,
        accHoldername: userData.accHolName,

        accNo: userData.accNo,
        confirmaccNo: userData.accNo,
        authpersonName: userData.name,
        Ifsccode: userData.ifsc,
        Gstin: userData.gstin,
        panNo: userData.pan,
        mobileNo: userData.mobile,
        password: userData.password,
        confirmPassword: userData.confirmpassword,
        city: userData.city.name,
        state: userData.state.name,
      };
      console.log("city", userData.city.name);
      console.log("body", requestBody);
      const response = await axios.post(
        "https://app.batuk.in/partner_registeration",
        requestBody
      );
      
      console.log(response);
      if (response.status == 200) {
        // Optionally log the response
        // Redirect or handle successful registration here
        Swal.fire({
          title: "Thank You!",
          text: "Registered Successfully!",
          icon: "success"
        });
        navigate("/login");
      }// Assuming you're using react-router-dom
    } catch (error) {
      console.error("Error registering user:", error);
      // Handle error (display message, etc.)
    }
  };

  // Inside the Register component

  const registerSubmit = (data) => {
    console.log("registerData", data.entityname);
    registerUser(data); // Call the registerUser function with form data
  };

  const handleCountryChange = (country) => {
    setCountryid(country.id);
    setstateid("");
    setCityId("");
  };

  const handleStateChange = (state) => {
    setstateid(state.id);
    setCityId("");
  };

  const handleCityChange = (city) => {
    setCityId(city.id);
  };

  // ============================================================





  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (token) {
      handleShow();
    }
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    // <div className="container-fluid">
    //   <div className="container mt-5">
    //     <div className="card p-5 shadow">
    //       <div className="col text-center">
    //         <p> Register with us & be the part of great oppertunity.</p>
    //       </div>
    //       <div className="container mt-5">
    //         <div className="row">
    //           <div className="col-md-7 mx-auto">
    //             <Form onSubmit={handleSubmit(registerSubmit)} className=" mt-4">
    //               <Form.Group className="mb-2">
    //                 <Form.Label>Entity Type</Form.Label>

    //                 <Form.Select
    //                   onChange={handleEntityTypeChange}
    //                   {...register("entitytype", { required: true })}>
    //                   <option>Select</option>
    //                   <option value="PivL">Individual Company </option>
    //                   <option value="PubL">Sole Proprietorship</option>
    //                   <option value="PubL">Partnership</option>
    //                   <option value="LLP">Private Limited</option>
    //                   <option value="Trust">Public Limited</option>
    //                   <option value="Partnership">
    //                     LLP (Limited Liability Partnership)
    //                   </option>
    //                   <option value="OPC">Trust</option>
    //                   <option value="OPC">Society</option>
    //                   <option value="OPC">Government</option>
    //                   <option value="OPC">Hindu Undivided Famliy</option>
    //                   <option value="OPC">Local Authority</option>
    //                   <option value="OPC">NGO</option>
    //                   <option value="OPC">One Person Comppany</option>
    //                   <option value="OPC">Artificial Juridical Person</option>
    //                 </Form.Select>
    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.entitytype?.type === "required" &&
    //                     "Select your entity type"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Company Name / Entity Name</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   {...register("entityname", { required: true })}
    //                   placeholder="Entity Name"
    //                 />
    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.entityname?.type === "required" &&
    //                     "Entity name is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>{label}</Form.Label>
    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Incoporation No."
    //                   {...register("incorpno", { required: true })}
    //                 />
    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.incorpno?.type === "required" &&
    //                     "Incorporation no is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Entity Email</Form.Label>

    //                 <Form.Control
    //                   type="email"
    //                   placeholder="Entity Email"
    //                   {...register("entitymail", { required: true })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.entitymail?.type === "required" &&
    //                     "Entity email is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Password</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter your password"
    //                   {...register("password", { required: true })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.password?.type === "required" &&
    //                     "Password is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Confirm Password</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Confirm password"
    //                   {...register("confirmpassword", {
    //                     required: true,
    //                     validate: (val) => {
    //                       if (watch("password") != val) {
    //                         return "Your passwords do no match";
    //                       }
    //                     },
    //                   })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.confirmpassword?.type === "required" &&
    //                     "Confirm password is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>PAN no.</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter PAN"
    //                   {...register("pan", { required: true })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.pan?.type === "required" && "PAN no is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>GSTIN (optional)</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter your GSTIN"
    //                   {...register("gstin")}
    //                 />
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label> Authorized Person Name</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter Full Name"
    //                   {...register("name", { required: true })}
    //                 />
    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.name?.type === "required" &&
    //                     "Enter full name required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Mobile no</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter PAN"
    //                   {...register("mobile", { required: true })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.mobile?.type === "required" &&
    //                     "mobile no is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Company Address</Form.Label>

    //                 <Form.Control
    //                   as="textarea"
    //                   placeholder="Enter Full Address"
    //                   {...register("address", { required: true })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.address?.type === "required" &&
    //                     "Address is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Country</Form.Label>
    //                 <Controller
    //                   name="country"
    //                   control={control}
    //                   rules={{ required: true }}
    //                   render={({ field }) => (
    //                     <CountrySelect
    //                       {...field}
    //                       onChange={(country) => {
    //                         field.onChange(country);
    //                         handleCountryChange(country);
    //                       }}
    //                       placeHolder="Select Country"
    //                     />
    //                   )}
    //                 />
    //                 {errors.country && (
    //                   <span
    //                     className="text-danger"
    //                     style={{ fontSize: "12px" }}>
    //                     Country is required
    //                   </span>
    //                 )}
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>State</Form.Label>
    //                 <Controller
    //                   name="state"
    //                   control={control}
    //                   rules={{ required: true }}
    //                   render={({ field }) => (
    //                     <StateSelect
    //                       countryid={countryid}
    //                       {...field}
    //                       onChange={(state) => {
    //                         field.onChange(state);
    //                         handleStateChange(state);
    //                       }}
    //                       placeHolder="Select State"
    //                     />
    //                   )}
    //                 />
    //                 {errors.state && (
    //                   <span
    //                     className="text-danger"
    //                     style={{ fontSize: "12px" }}>
    //                     State is required
    //                   </span>
    //                 )}
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>City</Form.Label>
    //                 <Controller
    //                   name="city"
    //                   control={control}
    //                   rules={{ required: true }}
    //                   render={({ field }) => (
    //                     <CitySelect
    //                       countryid={countryid}
    //                       stateid={stateid}
    //                       {...field}
    //                       onChange={(city) => {
    //                         field.onChange(city);
    //                         handleCityChange(city);
    //                       }}
    //                       placeHolder="Select City"
    //                     />
    //                   )}
    //                 />
    //                 {errors.city && (
    //                   <span
    //                     className="text-danger"
    //                     style={{ fontSize: "12px" }}>
    //                     City is required
    //                   </span>
    //                 )}
    //               </Form.Group>

    //               <h6>Bank Details</h6>

    //               <Form.Group className="mb-2">
    //                 <Form.Label> Account Holder's Name</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter Account Holder Name"
    //                   {...register("accHolName", { required: true })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.accHolName?.type === "required" &&
    //                     "Account holder name is required"}
    //                 </span>
    //               </Form.Group>
    //               <Form.Group className="mb-2">
    //                 <Form.Label>Account No.</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter Account No"
    //                   {...register("accNo", { required: true })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.accNo?.type === "required" &&
    //                     "Account no is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>Confirm Account No.</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Confirm Account"
    //                   {...register("accNo", {
    //                     required: true,
    //                     validate: (val) => {
    //                       if (watch("accNo") != val) {
    //                         return "Your account no does not match";
    //                       }
    //                     },
    //                   })}
    //                 />

    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.conAccNo?.type === "required" &&
    //                     "Confirm account is required"}
    //                 </span>
    //               </Form.Group>

    //               <Form.Group className="mb-2">
    //                 <Form.Label>IFSC Code</Form.Label>

    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Enter Bank IFSC Code"
    //                   {...register("ifsc", { required: true })}
    //                 />
    //                 <span className="text-danger" style={{ fontSize: "12px" }}>
    //                   {errors.ifsc?.type === "required" &&
    //                     "IFSC code is required"}
    //                 </span>
    //               </Form.Group>

    //               <div className="d-grid gap-2 mt-3">
    //                 <button
    //                   className="btn text-light fw-bold"
    //                   style={{ backgroundColor: "#10A37F" }}>
    //                   Register
    //                 </button>
    //               </div>
    //             </Form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // =========================================================================================================

    <div
      className="container-fluid body w-100 pt-5 pb-5 bg-white"
      style={{
        height: "100vh",
      }}>
      <Row className="d-flex justify-content-center">
        <Col md={6} lg={6} xl={6} className="pt-2">
          <Card
            className="rounded"
            style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.2)" }}>
            <CardHeader
              className="text-center p-3 "
              style={{ backgroundColor: "#4a2a7d", color: "white" }}>
              <Col>
                <img src={BatukLogo} alt="Logo" width={100} />
              </Col>
            Batuk Partner
            </CardHeader>
            <CardBody className="w-100" width={100}>
              <div className="p-4 mx-auto">
              <h4 className="bank_details_header_login">Register as 
            Entity Partner</h4>
                <Form onSubmit={handleSubmit(registerSubmit)} className=" mt-4">

                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Group className="mb-2">
                        <Form.Label>Entity Type</Form.Label>

                        <Form.Select
                          onChange={handleEntityTypeChange}
                          {...register("entitytype", { required: true })}>
                          <option>Select</option>
                          <option value="Individual Company">Individual Company </option>
                          <option value="Sole Proprietorship">Sole Proprietorship</option>
                          <option value="Partnership">Partnership</option>
                          <option value="Private Limited">Private Limited</option>
                          <option value="Public Limited">Public Limited</option>
                          <option value="LLP">
                            LLP (Limited Liability Partnership)
                          </option>
                          <option value="Trust">Trust</option>
                          <option value="Society">Society</option>
                          <option value="Government">Government</option>
                          <option value="Hindu Undivided Famliy">Hindu Undivided Famliy</option>
                          <option value="Local Authority">Local Authority</option>
                          <option value="NGO">NGO</option>
                          <option value="One Person Comppany">One Person Comppany</option>
                          <option value="Artificial Juridical Person">
                            Artificial Juridical Person
                          </option>
                        </Form.Select>
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.entitytype?.type === "required" &&
                            "Select your entity type"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group className="mb-2">
                        <Form.Label>Company Name / Entity Name</Form.Label>

                        <Form.Control
                          type="text"
                          {...register("entityname", { required: true })}
                          placeholder="Entity Name"
                        />
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.entityname?.type === "required" &&
                            "Entity name is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Incoporation No."
                          {...register("incorpno", { required: true })}
                        />
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.incorpno?.type === "required" &&
                            "Incorporation no is required"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>Entity Email</Form.Label>

                        <Form.Control
                          type="email"
                          placeholder="Entity Email"
                          {...register("entitymail", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.entitymail?.type === "required" &&
                            "Entity email is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>Password</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter your password"
                          {...register("password", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.password?.type === "required" &&
                            "Password is required"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>Confirm Password</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Confirm password"
                          {...register("confirmpassword", {
                            required: true,
                            validate: (val) => {
                              if (watch("password") != val) {
                                return "Your passwords do no match";
                              }
                            },
                          })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.confirmpassword?.type === "required" &&
                            "Confirm password is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>PAN no.</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter PAN"
                          {...register("pan", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.pan?.type === "required" &&
                            "PAN no is required"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>GSTIN (optional)</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter your GSTIN"
                          {...register("gstin")}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label> Authorized Person Name</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter Full Name"
                          {...register("name", { required: true })}
                        />
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.name?.type === "required" &&
                            "Enter full name required"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group className="mb-2">
                        <Form.Label>Mobile no</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter PAN"
                          {...register("mobile", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.mobile?.type === "required" &&
                            "mobile no is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                    <Form.Group className="mb-2">
                        <Form.Label>Country</Form.Label>
                        <Controller
                          name="country"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <CountrySelect
                              style={{ border: "0px solid white" }}
                              {...field}
                              onChange={(country) => {
                                field.onChange(country);
                                handleCountryChange(country);
                              }}
                              placeHolder="Select Country"
                            />
                          )}
                        />
                        {errors.country && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}>
                            Country is required
                          </span>
                        )}
                      </Form.Group>
                   
                    </div>
                    <div className="col-sm-6">
                      {" "}
                   


                      <Form.Group className="mb-2">
                        <Form.Label>State</Form.Label>
                        <Controller
                          name="state"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <StateSelect
                              countryid={countryid}
                              {...field}
                              onChange={(state) => {
                                field.onChange(state);
                                handleStateChange(state);
                              }}
                              placeHolder="Select State"
                            />
                          )}
                        />
                        {errors.state && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}>
                            State is required
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">


                      {/* =======================  address company================================= */}

                      <Form.Group className="mb-2">
                        <Form.Label>City</Form.Label>
                        <Controller
                          name="city"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <CitySelect
                              countryid={countryid}
                              stateid={stateid}
                              {...field}
                              onChange={(city) => {
                                field.onChange(city);
                                handleCityChange(city);
                              }}
                              placeHolder="Select City"
                            />
                          )}
                        />
                        {errors.city && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}>
                            City is required
                          </span>
                        )}
                      </Form.Group>


                      {/* ========================================================================== */}
                      {" "}
                  
                    </div>
                    <div className="col-sm-6">


                    <Form.Group className="mb-2">
                        <Form.Label>Company Address</Form.Label>

                        <Form.Control
                          as="textarea"
                          placeholder="Enter Full Address"
                          {...register("address", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.address?.type === "required" &&
                            "Address is required"}
                        </span>
                      </Form.Group>



                     
                    </div>
                  </div>
                  <h2  className="bank_details_header">Bank Details</h2>
                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label> Account Holder's Name</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter Account Holder Name"
                          {...register("accHolName", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.accHolName?.type === "required" &&
                            "Account holder name is required"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>Account No.</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter Account No"
                          {...register("accNo", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.accNo?.type === "required" &&
                            "Account no is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>Confirm Account No.</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Confirm Account"
                          {...register("accNo", {
                            required: true,
                            validate: (val) => {
                              if (watch("accNo") != val) {
                                return "Your account no does not match";
                              }
                            },
                          })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.conAccNo?.type === "required" &&
                            "Confirm account is required"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group className="mb-2">
                        <Form.Label>IFSC Code</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter Bank IFSC Code"
                          {...register("ifsc", { required: true })}
                        />
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.ifsc?.type === "required" &&
                            "IFSC code is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="d-grid gap-2 mt-3">
                    <button
                      className="btn text-light fw-bold"
                      style={{
                        backgroundColor: "rgb(74 42 125)",
                        padding: "10px 0px",
                        fontSize: "15px",
                      }}>
                      Register
                    </button>
                  </div>
                </Form>
                <Row className="py-3 text-center">
                  <p
                    style={{
                      color: " #4a2a7d",
                      fontSize: "15px",
                      paddingBottom: "0px",
                      fontWeight: "500",
                      marginBottom: "12px",
                    }}>
                    Already have an account ? <Link to="/login">Login</Link>
                  </p>
                  
                </Row>
              </div>
            </CardBody>
           
          </Card>
        </Col>
      </Row>
    </div>

    // =================================================================================================================
  );
};

export default Register;
