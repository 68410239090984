import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'

// import Loading from "../component/Loading";
// import ErrorMessage from "../component/ErrorMessage";
// import "./register.css";
import { useNavigate } from "react-router-dom";
import {
  // Button,
  // Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
} from "react-bootstrap";
import BatukLogo from "../images/Batuk-logo.png";

import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "@davzon/react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const IndRegister = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const [selectedEntityType, setSelectedEntityType] = useState("");
  const [label, setLabel] = useState("Company Type Incorporation no.");

  // Function to handle changes in the dropdown selection
  const handleEntityTypeChange = (e) => {
    const selectedEntityType = e.target.value;
    // Update the label based on the selected entity type
    const newLabel = getLabel(selectedEntityType);
    // Set the label dynamically
    setLabel(newLabel);
  };

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [cityId, setCityId] = useState("");

  const handleCountryChange = (country) => {
    setCountryid(country.id);
    setstateid("");
    setCityId("");
  };

  const handleStateChange = (state) => {
    setstateid(state.id);
    setCityId("");
  };

  const handleCityChange = (city) => {
    setCityId(city.id);
  };

  // Function to get the label based on the selected entity type
  const getLabel = (selectedEntityType) => {
    switch (selectedEntityType) {
      case "PivL":
        return "Individual Company Incorporation no.";
      case "PubL":
        return "Sole Proprietorship Limited Company Incorporation no.";
      case "Ltd":
        return "Partnership Company Incorporation no.";
      case "LLP":
        return "Private Limited  Incorporation no.";
      case "Trust":
        return "Public Limited Incorporation no.";
      case "Partnership":
        return "LLP (Limited Liability Partnership) Incorporation no.";
      case "OPC":
        return "Trust Incorporation no.";
      case "OPC":
        return "Society Incorporation no.";
      case "OPC":
        return "Government Incorporation no.";
      case "OPC":
        return "Hindu Undivided Famliy Incorporation no.";
      case "OPC":
        return "Local Authority Incorporation no.";
      case "OPC":
        return "NGO Incorporation no.";
      case "OPC":
        return "One Person Comppany Incorporation no.";
      case "OPC":
        return "Artificial Juridical Person";
      default:
        return "Company Type Incorporation no.";
    }
  };

  const registerUser = async (userData) => {
    try {
      const requestBody = {
        registerType: "individual",
        name: userData.IndfullName,
        email: userData.Indemail,
        Address: userData.Indaddress,
        dob: userData.dob,
        accHoldername: userData.accHolName,
        aadharNo: userData.addadhar,
        accNo: userData.accNo,
        confirmaccNo: userData.accNo,
        Ifsccode: userData.ifsc,
        panNo: userData.Indpan,
        mobileNo: userData.IndMob,
        password: userData.pwd,
        confirmPassword: userData.confpwd,
        city: userData.city.name,
        state: userData.state.name,
      };
      console.log("city", userData.city.name);
      console.log("body", requestBody);
      const response = await axios.post(
        "https://app.batuk.in/partner_registeration",
        requestBody
      );
      console.log(response);
      
      if (response.status == 200) {
        // Optionally log the response
        // Redirect or handle successful registration here
        Swal.fire({
          title: "Thank You!",
          text: "Registered Successfully!",
          icon: "success"
        });
        navigate("/login");
      } else {
        console.log("error in status")
      }
      // Optionally log the response
      // Redirect or handle successful registration here
      navigate("/login"); // Assuming you're using react-router-dom
    } catch (error) {
      console.error("Error registering user:", error);
      // Handle error (display message, etc.)
    }
  };

  const IndRegisterSubmit = async (data) => {
    try {
      // Your registration logic here
      console.log("Registration data:", data);
      registerUser(data);
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };

  return (
    // <div className='container-fluid'>
    //     <div className="container mt-5">
    //         <div className="card p-5 shadow" >
    //             <div className="col text-center">
    //                 <p> Register with us & be the part of great oppertunity.</p>
    //             </div>
    //             <div className="container mt-5">
    //                 <div className='row'>
    //                     <div className='col-md-7 mx-auto'>

    //                         <Form className=" mt-4" onSubmit={handleSubmit(IndRegisterSubmit)}>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Name</Form.Label>

    //                                 <Form.Control type="text" placeholder='Enter Full Name' {...register("IndfullName", { required: true })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fullName?.type === "required" && "Full name is required"}</span>
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Email</Form.Label>

    //                                 <Form.Control type="email" placeholder='Enter Email' {...register("Indemail", { required: true })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.Indemail?.type === "required" && "Email is required"}</span>
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>DOB</Form.Label>

    //                                 <Form.Control type="date" {...register("dob", { required: true })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.Indemail?.type === "required" && "Email is required"}</span>
    //                             </Form.Group>

    //                             {/* <Form.Group className='mb-2'>
    //                                 <Form.Label>Gender</Form.Label>
    //                                 <Form.Select aria-label="Default select example" {...register("gender", { required: true })}>
    //                                     <option>Open this select menu</option>
    //                                     <option value="M">Male</option>
    //                                     <option value="F">Female</option>
    //                                     <option value="O">Other</option>
    //                                 </Form.Select>

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.gender?.type === "required" && "Gender is required"}</span>
    //                             </Form.Group> */}

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Password</Form.Label>

    //                                 <Form.Control type="password" placeholder='Enter password' {...register("pwd", { required: true })} />
    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.pwd?.type === "required" && "Password is required"}</span>

    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Confirm Password</Form.Label>

    //                                 <Form.Control type="password" placeholder='Confirm password' {...register("confpwd", { required: true,
    //                                     validate: (val) => {
    //                                         if (watch('pwd') != val) {
    //                                             return "Your passwords do no match";
    //                                         }
    //                                     }, })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.confpwd?.type === "required" && "Confirm password is required"}</span>
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Address</Form.Label>

    //                                 <Form.Control as="textarea" placeholder='Enter Your Address' {...register("Indaddress", { required: true })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.Indaddress?.type === "required" && "Address is required"}</span>
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Country</Form.Label>
    //                                 <Controller
    //                                     name="country"
    //                                     control={control}
    //                                     rules={{ required: true }}
    //                                     render={({ field }) => (
    //                                         <CountrySelect
    //                                             {...field}
    //                                             onChange={(country) => {
    //                                                 field.onChange(country);
    //                                                 handleCountryChange(country);
    //                                             }}
    //                                             placeHolder="Select Country"
    //                                         />
    //                                     )}
    //                                 />
    //                                 {errors.country && <span className='text-danger' style={{ fontSize: "12px" }}>Country is required</span>}
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>State</Form.Label>
    //                                 <Controller
    //                                     name="state"
    //                                     control={control}
    //                                     rules={{ required: true }}
    //                                     render={({ field }) => (
    //                                         <StateSelect
    //                                             countryid={countryid}
    //                                             {...field}
    //                                             onChange={(state) => {
    //                                                 field.onChange(state);
    //                                                 handleStateChange(state);
    //                                             }}
    //                                             placeHolder="Select State"
    //                                         />
    //                                     )}
    //                                 />
    //                                 {errors.state && <span className='text-danger' style={{ fontSize: "12px" }}>State is required</span>}
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>City</Form.Label>
    //                                 <Controller
    //                                     name="city"
    //                                     control={control}
    //                                     rules={{ required: true }}
    //                                     render={({ field }) => (
    //                                         <CitySelect
    //                                             countryid={countryid}
    //                                             stateid={stateid}
    //                                             {...field}
    //                                             onChange={(city) => {
    //                                                 field.onChange(city);
    //                                                 handleCityChange(city);
    //                                             }}
    //                                             placeHolder="Select City"
    //                                         />
    //                                     )}
    //                                 />
    //                                 {errors.city && <span className='text-danger' style={{ fontSize: "12px" }}>City is required</span>}
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>PAN no.</Form.Label>

    //                                 <Form.Control type="text" placeholder='Enter PAN' {...register("Indpan", { required: true })} />

    //                             </Form.Group><span className='text-danger' style={{ fontSize: "12px" }}>{errors.Indpan?.type === "required" && "PAN no is required"}</span>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Aadhar no.</Form.Label>

    //                                 <Form.Control type="text" placeholder='Enter Aadhar no' {...register("addadhar", { required: true })} />
    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.addadhar?.type === "required" && "Aadhar no is required"}</span>
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Phone no.</Form.Label>

    //                                 <Form.Control type="number" placeholder='Enter Mobile No' {...register("IndMob", { required: true })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.IndMob?.type === "required" && "Mobile no is required"}</span>
    //                             </Form.Group>

    //                             <h6>Bank Details</h6>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label> Account Holder's Name</Form.Label>

    //                                 <Form.Control type="text" placeholder='Enter Account holder name' {...register("accHolName", { required: true })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.accHolName?.type === "required" && "Account holder name is required"}</span>
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Account No.</Form.Label>

    //                                 <Form.Control type="text" placeholder='Enter Bank Account no.' {...register("accNo", { required: true })} />

    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.accNo?.type === "required" && "Account No is required"}</span>
    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>Confirm Account No.</Form.Label>

    //                                 <Form.Control type="text" placeholder='Confirm Bank Account no.' {...register("accNo", { required: true })} />
    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.accNo?.type === "required" && "Confirm account is required"}</span>

    //                             </Form.Group>

    //                             <Form.Group className='mb-2'>
    //                                 <Form.Label>IFSC Code</Form.Label>

    //                                 <Form.Control type="text" placeholder='Enter Bank IFSC Code' {...register("ifsc", { required: true })} />
    //                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ifsc?.type === "required" && "IFSC code is required"}</span>
    //                             </Form.Group>

    //                             <div className='d-grid gap-2 mt-3'>
    //                                 <button className='btn text-light fw-bold' style={{ backgroundColor: "#10A37F" }}>Register</button>
    //                             </div>
    //                         </Form>

    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    // ===================================================================
    <div
      className="container-fluid body w-100 pt-5 pb-5 bg-white"
      style={{
        height: "100vh",
      }}>
      <Row className="d-flex justify-content-center">
        <Col md={6} lg={6} xl={6} className="pt-2">
          <Card
            className="rounded"
            style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.2)" }}>
            <CardHeader
              className="text-center p-3 "
              style={{ backgroundColor: "#4a2a7d", color: "white" }}>
              <Col>
                <img src={BatukLogo} alt="Logo" width={100} />
              </Col>
          Batuk Partner
            </CardHeader>
                      <CardBody className="w-100" width={100}>
                          <h4 className="bank_details_header_login">Register as 
                          Individual Partner</h4>
              <div className="p-4 mx-auto pt-0">
                <Form
                  className=" mt-4"
                  onSubmit={handleSubmit(IndRegisterSubmit)}>
                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>Name</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter Full Name"
                          {...register("IndfullName", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.fullName?.type === "required" &&
                            "Full name is required"}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group className="mb-2">
                        <Form.Label>Email</Form.Label>

                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          {...register("Indemail", { required: true })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.Indemail?.type === "required" &&
                            "Email is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      {" "}
                      <Form.Group className="mb-2">
                        <Form.Label>Password</Form.Label>

                        <Form.Control
                          type="password"
                          placeholder="Enter password"
                          {...register("pwd", { required: true })}
                        />
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.pwd?.type === "required" &&
                            "Password is required"}
                        </span>
                      </Form.Group>{" "}
                    </div>
                    <div className="col-sm-6">
                      <Form.Group className="mb-2">
                        <Form.Label>Confirm Password</Form.Label>

                        <Form.Control
                          type="password"
                          placeholder="Confirm password"
                          {...register("confpwd", {
                            required: true,
                            validate: (val) => {
                              if (watch("pwd") != val) {
                                return "Your passwords do no match";
                              }
                            },
                          })}
                        />

                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}>
                          {errors.confpwd?.type === "required" &&
                            "Confirm password is required"}
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">     <Form.Group className="mb-2">
                    <Form.Label>DOB</Form.Label>

                    <Form.Control
                      type="date"
                      {...register("dob", { required: true })}
                    />

                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.Indemail?.type === "required" &&
                        "Email is required"}
                    </span>
                  </Form.Group></div>
                    <div className="col-sm-6">     <Form.Group className="mb-2">
                    <Form.Label>Country</Form.Label>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CountrySelect
                          {...field}
                          onChange={(country) => {
                            field.onChange(country);
                            handleCountryChange(country);
                          }}
                          placeHolder="Select Country"
                        />
                      )}
                    />
                    {errors.country && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}>
                        Country is required
                      </span>
                    )}
                  </Form.Group>
</div>
                                  </div>
                                  
                                  <div className="row">
                                      <div className="col-sm-6"> <Form.Group className="mb-2">
                    <Form.Label>State</Form.Label>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <StateSelect
                          countryid={countryid}
                          {...field}
                          onChange={(state) => {
                            field.onChange(state);
                            handleStateChange(state);
                          }}
                          placeHolder="Select State"
                        />
                      )}
                    />
                    {errors.state && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}>
                        State is required
                      </span>
                    )}
                                      </Form.Group> </div>
                                      <div className="col-sm-6"> 
                                      
                                      
                                      
                                      
                                      <Form.Group className="mb-2">
                    <Form.Label>City</Form.Label>
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CitySelect
                          countryid={countryid}
                          stateid={stateid}
                          {...field}
                          onChange={(city) => {
                            field.onChange(city);
                            handleCityChange(city);
                          }}
                          placeHolder="Select City"
                        />
                      )}
                    />
                    {errors.city && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}>
                        City is required
                      </span>
                    )}
                                      </Form.Group>
                                      
                                      
                                      
                                      </div>
                                  </div>
                  {/* <Form.Group className='mb-2'>
                                <Form.Label>Gender</Form.Label>
                                <Form.Select aria-label="Default select example" {...register("gender", { required: true })}>
//                                     <option>Open this select menu</option>
//                                     <option value="M">Male</option>
//                                     <option value="F">Female</option>
//                                     <option value="O">Other</option>
//                                 </Form.Select>

//                                 <span className='text-danger' style={{ fontSize: "12px" }}>{errors.gender?.type === "required" && "Gender is required"}</span>
//                             </Form.Group> */}

             

                

             
                                  <div className="row">
                                      <div className="col-sm-6">   
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                      
                                          {/* ========================================= */}
                                          
                                          <Form.Group className="mb-2">
                    <Form.Label>Address</Form.Label>

                    <Form.Control
                      as="textarea"
                      placeholder="Enter Your Address"
                      {...register("Indaddress", { required: true })}
                    />

                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.Indaddress?.type === "required" &&
                        "Address is required"}
                    </span>
                                          </Form.Group>
                                          </div>
                                      <div className="col-sm-6">
                                      <Form.Group className="mb-2">
                    <Form.Label>PAN no.</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Enter PAN"
                      {...register("Indpan", { required: true })}
                    />
                  </Form.Group>
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.Indpan?.type === "required" && "PAN no is required"}
                  </span>

                                      </div>
                 </div>

               
                                  <div className="row">
                                      <div className="col-sm-6">  <Form.Group className="mb-2">
                    <Form.Label>Aadhar no.</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Enter Aadhar no"
                      {...register("addadhar", { required: true })}
                    />
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.addadhar?.type === "required" &&
                        "Aadhar no is required"}
                    </span>
                  </Form.Group></div>
                                      <div className="col-sm-6"><Form.Group className="mb-2">
                    <Form.Label>Phone no.</Form.Label>

                    <Form.Control
                      type="number"
                      placeholder="Enter Mobile No"
                      {...register("IndMob", { required: true })}
                    />

                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.IndMob?.type === "required" &&
                        "Mobile no is required"}
                    </span>
                  </Form.Group></div>
</div>
              
                

                  
<h2  className="bank_details_header">Bank Details</h2>


              
                                  <div className="row">
                                      <div className="col-sm-6">
                                      <Form.Group className="mb-2">
                    <Form.Label> Account Holder's Name</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Enter Account holder name"
                      {...register("accHolName", { required: true })}
                    />

                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.accHolName?.type === "required" &&
                        "Account holder name is required"}
                    </span>
                  </Form.Group>
                                      </div>
                                      <div className="col-sm-6">
                                      <Form.Group className="mb-2">
                    <Form.Label>Account No.</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Enter Bank Account no."
                      {...register("accNo", { required: true })}
                    />

                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.accNo?.type === "required" &&
                        "Account No is required"}
                    </span>
                  </Form.Group>
                                      </div>
</div>
                 
                                  <div className="row">
                                      <div className="col-sm-6">       <Form.Group className="mb-2">
                    <Form.Label>Confirm Account No.</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Confirm Bank Account no."
                      {...register("accNo", { required: true })}
                    />
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.accNo?.type === "required" &&
                        "Confirm account is required"}
                    </span>
                  </Form.Group></div>
                                      <div className="col-sm-6">    <Form.Group className="mb-2">
                    <Form.Label>IFSC Code</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Enter Bank IFSC Code"
                      {...register("ifsc", { required: true })}
                    />
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.ifsc?.type === "required" &&
                        "IFSC code is required"}
                    </span>
                  </Form.Group></div>
</div>
        

           

              

                  <div className="d-grid gap-2 mt-3">
                                      <button
                                            className="btn py-2 w-100"
                                            style={{
                                              backgroundColor: "#4a2a7d",
                                              color: "white",
                                              marginTop: "20px",
                                            }}>




                 
Register
                    </button>
                  </div>
                </Form>
                <Row className="py-3 text-center">
                  <p
                    style={{
                      color: " #4a2a7d",
                      fontSize: "15px",
                      paddingBottom: "0px",
                      fontWeight: "500",
                      marginBottom: "12px",
                    }}>
                    Already have an account ? <Link to="/login">Login</Link>
                  </p>
                  
                </Row>
              </div>
            </CardBody>
           
          </Card>
        </Col>
      </Row>
    </div>

    // =======================================================================
  );
};

export default IndRegister;
