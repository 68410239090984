import React, { useEffect, useState } from "react";

import MoneyBag from "../images/prev/money-bag.png";

import Investor from "../images/prev/income.png";
import SIP from "../images/prev/sip.png";
import SIPCount from "../images/prev/SIPcount.png";
import SIPAmount from "../images/prev/sipamount.png";
import { PiCurrencyInrBold } from "react-icons/pi";
import axios from "axios";
// ================================

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DELETE,
  DEPARTMENT,
  LIST,
  RETAIL_OUTLET,
  DASHBOARD,
} from "../helpers/url_helper";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
// ================================================
function Dashboard() {
  const Token = sessionStorage.getItem("token");
  const token = sessionStorage.getItem("token");

  const message = sessionStorage.getItem("message");
  console.log("This is Client page", Token);

  const [list, setList] = useState([]);

  const [totalCli, setTotalCli] = useState();
  const [totalEmp, setTotalEmp] = useState();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");

  const [TotalAugmontData, setTotalAugmontData] = useState("");
  const [graphdata, setgraphdata] = useState("");
  const [topInvesters, settopInvesters] = useState("");
  const [dashboarddata, setdashboarddata] = useState({});
  // console.log("dashboarddata: ", dashboarddata);

  useEffect(() => {
    // getTotalAugmontData();
    // getgraphdata();
    // gettopInvesters();
    DashBoardData();
  }, []);

  const DashBoardData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + RETAIL_OUTLET + DASHBOARD, {
        headers,
      });
      console.log("response: ", response);
      const list = response.data.data;
      console.log("Employee list=", list);
      setdashboarddata(list);
      // setList(list);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const getTotalAugmontData = async () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };

    const response = await axios.get(
      "https://app.batuk.in/getTotalInvestments",
      { headers }
    );
    setTotalAugmontData(response.data.data);
    console.log("response of totalAumamount", response.data.data);
  };

  const getgraphdata = async () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };

    const response = await axios.get("https://app.batuk.in/graphdata", {
      headers,
    });
    setgraphdata(response.data.data);
    console.log("response of graphdata", response.data.data);
  };

  const gettopInvesters = async () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };

    const response = await axios.get("https://app.batuk.in/topInvesters", {
      headers,
    });
    settopInvesters(response.data.data);
    console.log("response of gettopInvesters", response.data.data);
  };
  // ==============================================

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + DEPARTMENT + LIST, {
        headers,
      });
      const list = response.data.data;
      console.log("Employee list=", list);

      setList(list);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const openEditModal = (data) => {
    setSelectedRow(data);
    setName(data.name);
    setEditModalIsOpen(true);
  };

  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + DEPARTMENT + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  const cardsData = [
    { title: "Total RO No.", value: 0 },
    { title: "Shortlisted RO", value: 0 },
    { title: "Surveyed RO", value: 0 },
    { title: "Total LOI Sent", value: 0 },
    { title: "Total Side Letter", value: 0 },
    { title: "Total Employee", value: 0 },
    { title: "Total SML", value: 0 },
    { title: "Total Sales", value: 0 },
  ];

  return (
    <>
      {/* new card dashboard  */}
      <div>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Grid container spacing={3} sx={{ textAlign: "center" }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  style={{ width: "450vw" }}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="h6" component="div">
                    Shortlisted RO{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {dashboarddata?.smlBookingCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "450vw" }}
                >
                  <Typography variant="h6" component="div">
                    Total RO
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {dashboarddata?.totalROCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "450vw" }}
                >
                  <Typography variant="h6" component="div">
                    Surveyed RO
                  </Typography>
                  <Typography
                    style={{ justifyContent: "center", alignItems: "center" }}
                    variant="h6"
                  >
                    {/* {dashboarddata.smlBookingCount} */}
                    {dashboarddata?.feasibilityCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  style={{ width: "450vw" }}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="h6" component="div">
                    Total LOI Sent
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {dashboarddata?.loiCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "450vw" }}
                >
                  <Typography variant="h6" component="div">
                    Total Side Letter{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {dashboarddata?.sideLetterCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "450vw" }}
                >
                  <Typography variant="h6" component="div">
                    Total Employee{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {dashboarddata?.employeeCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "450vw" }}
                >
                  <Typography variant="h6" component="div">
                    Total SML
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {dashboarddata?.smlCount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "450vw" }}
                >
                  <Typography variant="h6" component="div">
                    Total Sales
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {dashboarddata?.totalAmount}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* 
          {cardsData.map((card, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <Typography variant="h6" component="div">
                      {card.title}
                    </Typography>
                    <Typography variant="h4">{card.value}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))} */}
        </Grid>
      </div>

      {/* table  */}
      <div className="col-md-12" style={{ marginTop: "30px" }}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                <TableRow>
                  <TableCell align="center">RO Name</TableCell>
                  <TableCell align="center">SML Name</TableCell>
                  <TableCell align="center">Sale Type</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: "#ffff",
                    }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>

                    <TableCell align="center">
                      <Button
                        style={{ marginRight: "20px" }}
                        variant="contained"
                        onClick={() => openEditModal(row)}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="contained"
                        onClick={() => deleteRow(row)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* =========================================================================================== */}
      </div>

      {/* <section className="dashboard section ">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 bg-white rounded "
              style={{ boxShadow: "rgba(0, 0, 0, 0.6) 0px 1px 4px 0px" }}>
              <div className="d-flex justify-content-center">
                <div className="col-md-4 px-3 px-md-2 mb-3 mb-md-0">
                  <Card
                    className="text-center me-md-2 px-2"
                    style={{ border: "none", background: "transparent" }}>
                    <Card.Body>
                      <img
                        src={MoneyBag}
                        alt="moneybag"
                        style={{ width: "88px", padding: "10px" }}
                        className="img-fluid"
                      />
                      <h6 style={{ color: "#8e8e8e" }}>Total Amount</h6>
                      <p
                        className="h5"
                        style={{
                          color: "#242d75",
                          fontWeight: "700",
                          fontSize: "19px",
                        }}>
                        <PiCurrencyInrBold />
                        {TotalAugmontData.totalAumAmount}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4 px-3 px-md-2 mb-3 mb-md-0">
                  <Card
                    className="me-md-2 mt-md-5"
                    style={{ border: "none", background: "transparent" }}>
                    <Card.Body>
                      <Table borderless>
                        <tbody>
                          <tr>
                            <td
                              className="pe-3 pe-md-5 h6"
                              style={{ color: "#8e8e8e" }}>
                              <strong>Total Gold Amount</strong>
                              <br />
                              <p
                                className="h5"
                                style={{
                                  color: "#242d75",
                                  fontWeight: "600",
                                  marginTop: "5px",
                                  marginBottom: "0px",
                                  fontSize: "19px",
                                }}>
                                {"₹ " + TotalAugmontData.totalGoldAmount}
                              </p>
                            </td>
                            <td className="ps-3 ps-md-5">
                              <img
                                src={Investor}
                                alt="Investor"
                                style={{ width: "50px" }}
                                className="img-fluid"
                              />
                            </td>
                          </tr>
                          <tr className="border-bottom"></tr>
                          <tr>
                            <td
                              className="pe-3 pe-md-5 pt-3 h6"
                              style={{ color: "#8e8e8e" }}>
                              <strong>Total Silver Amount</strong>
                              <br />
                              <p
                                className="h5"
                                style={{
                                  color: "#242d75",
                                  fontWeight: "600",
                                  marginTop: "5px",
                                  marginBottom: "0px",
                                  fontSize: "19px",
                                }}>
                                {"₹ " + TotalAugmontData.totalSilverAmount}
                              </p>
                            </td>
                            <td className="ps-3 ps-md-5 pt-3">
                              <img
                                src={SIP}
                                alt="SIP"
                                style={{ width: "50px" }}
                                className="img-fluid"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4 px-3 px-md-2">
                  <Card
                    className="mt-md-5"
                    style={{ border: "none", background: "transparent" }}>
                    <Card.Body>
                      <Table borderless>
                        <tbody>
                          <tr>
                            <td className="h6" style={{ color: "#8e8e8e" }}>
                              <strong
                                style={{
                                  marginBottom: "20px",
                                  paddingBottom: "20px",
                                  fontSize: "15px",
                                }}>
                                Total Gold (grams)
                              </strong>
                              <br />
                              <p
                                className="h5"
                                style={{
                                  color: "#242d75",
                                  fontWeight: "600",
                                  marginTop: "5px",
                                  marginBottom: "0px",
                                  fontSize: "19px",
                                }}>
                                {TotalAugmontData.totalGoldGrams}
                              </p>
                            </td>
                            <td>
                              <img
                                src={SIPAmount}
                                alt="SIP Amount"
                                style={{ width: "50px" }}
                                className="img-fluid"
                              />
                            </td>
                          </tr>
                          <tr className="border-bottom"></tr>
                          <tr>
                            <td
                              className="pt-3 h6"
                              style={{ color: "#8e8e8e" }}>
                              <strong
                                style={{
                                  marginBottom: "20px",
                                  paddingBottom: "20px",
                                  fontSize: "15px",
                                }}>
                                Total Silver (grams)
                              </strong>
                              <br />
                              <p
                                className="h5"
                                style={{
                                  color: "#242d75",
                                  fontWeight: "600",
                                  marginTop: "5px",
                                  marginBottom: "0px",
                                  fontSize: "19px",
                                }}>
                                {TotalAugmontData.totalSilverGrams}
                              </p>
                            </td>
                            <td className="pt-3">
                              <img
                                src={SIPCount}
                                alt="SIP Count"
                                style={{ width: "50px" }}
                                className="img-fluid"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 bg-white shadow-sm d-flex mt-3 rounded"></div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Dashboard;
