export const BASE_URL = "https://development.batukit.com";
export const LOGIN = "/user/login";
export const LOGOUT = "/user/logout";
export const FORGOT = "/user/forgot";
export const REGION = "/region";
export const DEPARTMENT = "/department";
export const DESIGNATION = "/designation";
export const OMC_EMPLOYEE = "/omc_employee";
export const OMC = "/omc";
export const STATE = "/state";
export const CITY = "/city";
export const TERRITORY = "/territory";
export const EMPLOYEE = "/employee";
export const CREATE = "/create";
export const UPDATE = "/update";
export const LIST = "/list";
export const DELETE = "/delete";
export const DETAIL = "/detail";
export const SML = "/sml";
export const RETAIL_OUTLET = "/retail_outlet";
export const RO_SALES = "/ro_sale";
export const RO_BOOKING = "/ro_booking";
export const DASHBOARD = "/dashboard";

export const RETAIL_OUTLET_FEASIBILITY = "/feasibiltyUpdate";
export const RETAIL_OUTLET_FEASIBILITY_LIST = "/feasibiltydDetail";
export const RETAIL_OUTLET_LOI = "/loiUpdate";
export const RETAIL_OUTLET_LOI_LIST = "/feasibiltyLoiDetail";
export const RETAIL_OUTLET_SIDE_LETTER_LIST = "/feasibiltyLoiSideLetterDetail";
export const RETAIL_OUTLET_SIDE_LETTER = "/sideLetterUpdate";
export const LOICREATE = "/loi/create";
export const LOISAVE = "/loi/save";
export const LOILIST = "/loi/list";
export const STATEDATA = "/stateData/";
export const CITYDATA = "/cityData/";
export const TERRITORYDATA = "/territoryData/";
export const LISTSOTCTMRIRHQ = "/ListSoTcTmRiRhq/";
export const OMCTYPE = "/area/list/";
export const LOI_SIDE_LETTER_UPLOAD = "/loi/sideLetter/upload";
export const RO_BY_OMC = "/retail_outlet/roListbyOmc/";
export const RO_BY_OMC_ALLSTATUSONE = "/retail_outlet/roListAllStatusOne/";
export const OMC_INVOICE = "/invoice";
export const BPCLIOCLHPCL_REPORTS = "/reports/";
// https://development.batukit.com/retail_outlet/reports/667fa10c2f3efded3ced15d8
// https://development.batukit.com/user/logout
// https://development.batukit.com/retail_outlet/feasibleRo
