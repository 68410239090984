import React, { useState } from "react";
import InProgressTable from "./Inprogresstable";
import ExecutedTable from "./ExecutedTable";
import ExpiredTable from "./ExpiredTable";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import axios from 'axios';

const Proposal = () => {
  const Token = sessionStorage.getItem('token');
  const [activeTab, setActiveTab] = useState("InProgress");

  const renderTable = () => {
    switch (activeTab) {
      case "InProgress":
        return <InProgressTable />;
      case "Executed":
        return <ExecutedTable />;
      case "Expired":
        return <ExpiredTable />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();


  const handleCreatePurposal =async (id) => {

    // try {
    //   const url = `https://app.batuk.in/proposal_create/${id}`;
    //   const headers = {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: `Bearer ${Token}`,
    //   };
    //   const response = await axios.get(url, { headers });
 
    //   console.log("prposal create response", response);
    // } catch (error) {
    //   console.error("Error connecting to API", error);
    // }



    navigate("/create_purposal");
  };

  const [value, setValue] = React.useState("InProgress");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  
  console.log("value tabs", value);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 ms-2 mt-2">
          <Button
            variant="contained"
            className="btn btn-primary"
            onClick={handleCreatePurposal}
            style={{ textTransform: "capitalize" }}>
            Create New Proposal
          </Button>
        </div>
      </div>

      <div className="row  mt-2 p-2 d-flex justify-content-center">
        <div className="col-sm-12 d-flex justify-content-center">
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            TabIndicatorProps={{
              sx: {
                height: "3px",
              },
            }}>
            <Tab
              value="InProgress"
              label="In Progress"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            />
            <Tab
              value="Executed"
              label=" Executed"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            />
            <Tab
              value="Expired"
              label="Expired"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            />
          </Tabs>
        </div>

        {/* ================================================================ */}

        {/* ============================================================== */}
      </div>

      <div className="row mt-4">
        <div className="col-md-12">{renderTable()}</div>
      </div>
    </div>
  );
};

export default Proposal;
