import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "react-modal";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";

const ExecutedTable = () => {
  const Token = sessionStorage.getItem("token");
  const [excutedPurposal, setexcutedPurposal] = useState();
  useEffect(() => {
    GetExecutedAPI();
  }, []);

  const GetExecutedAPI = async () => {
    console.log("get executed", Token);
    try {
      const url = "https://app.batuk.in/getProposal";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      // const employees = response.data.data;
      console.log("Executed  Api data", response.data.data.ExecutedProposals);
      setexcutedPurposal(response.data.data.ExecutedProposals);
      // console.log("employee")
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("data input search", query);
  };

  const [EditSelectedPurposal, setEditSelectedPurposal] = useState(false);

  const closeEditModal = () => {
    setEditSelectedPurposal(false);
  };
  const handleSave = () => {
    setEditSelectedPurposal(false);
  };

  const deletePurposal =async  (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          const url = `https://app.batuk.in/proposalDelete/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Purposal has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          GetExecutedAPI();
  
    
          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }


        
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your Purposal is safe",
          icon: "error",
        });
      }
    });
  };

  return (
    <div className="conatiner-fluid  p-2">
      <div className="row">
        <div className="col-md-12">
          {/* Table Header */}
          <Paper>
            <TableContainer>
            <Table>
                <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                  <TableRow>
                  <TableCell align="center">#</TableCell>

                    <TableCell align="center">Proposal Number</TableCell>
                    <TableCell align="center">Client</TableCell>
                    <TableCell align="center">Metal Type</TableCell>

                    <TableCell align="center">Status</TableCell>

                    <TableCell align="center">Amount</TableCell>

                    {/* <TableCell align="center">Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excutedPurposal &&
                    excutedPurposal.map((row, index) => {
                      return (
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: "#ffff",
                          }}>
                          <TableCell align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {row.proposal_no}
                          </TableCell>
                          <TableCell align="center">
                            {row.client_name}
                          </TableCell>
                          <TableCell align="center">
                            {row.metal_type
}
                          </TableCell>
                          <TableCell align="center">
                            <span
                              style={{
                                backgroundColor: "rgb(74 42 125)",
                                padding: " 7px 14px",
                                borderRadius: "12px",
                                fontSize: "13px",
                                color: "white",
                              }}>
                              {row.status}
                            </span>
                          </TableCell>
                          <TableCell align="center">{"₹ "+ row.amount}</TableCell>

                          {/* <TableCell align="center">
                            
                            <Button
                              variant="contained"
                              onClick={() => deletePurposal(row)}>
                              Delete
                            </Button>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                                 

                  {/* ================================================================ */}
                </TableBody>
              </Table>
             
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={15}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default ExecutedTable;
