import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  REGION,
  RETAIL_OUTLET,
  RETAIL_OUTLET_FEASIBILITY,
  STATE,
  CITY,
  TERRITORY,
  EMPLOYEE,
  SML,
  STATEDATA,
  CITYDATA,
  TERRITORYDATA,
  OMC,
  LISTSOTCTMRIRHQ,
  OMCTYPE,
} from "../helpers/url_helper.js";
import {
  Container,
  TextField,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Box,
  TextareaAutosize,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import { mkConfig, generateCsv, download } from "export-to-csv";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import UseFetch from "../components/UseFetch.js";
import { IconButton } from "rsuite";
Modal.setAppElement("#root"); // Required for accessibility

const Ro = () => {
  const token = sessionStorage.getItem("token");
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NjZlZTgwNTRkMTZiMWI1YWE4MGQ1YSIsImlhdCI6MTcxODg4MDU3MywiZXhwIjoxNzE4OTY2OTczfQ.6NHZRmuAZ2KhGUFQkgQABCBRa85e6wkX2lLys8XyO50";
  const [Loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  // const [newName, setNewName] = useState("");

  // edit by jaspal
  const [showForm, setShowForm] = useState(false);
  const [newName, setNewName] = useState({
    outlet_code: "",
    name: "",
    omc_id: "",
    so_id: "",
    tc_id: "",
    tm_id: "",
    ri_id: "",
    rhq1_id: "",
    rhq2_id: "",
    employee_id: "",
    sml_id: "",
    address: "",
  });
  console.log("newName: ", newName);

  const {
    outlet_code,
    name,
    // omc_id,
    so_id,
    tc_id,
    tm_id,
    ri_id,
    rhq1_id,
    rhq2_id,
    employee_id,
    sml_id,
    address,
  } = newName;
  // console.log(newName);
  const [showSuccessMessage, setshowSuccessMessage] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + RETAIL_OUTLET + LIST, {
        headers,
      });
      const lists = response.data.data;

      console.log(lists, "chandurankryyyyyyyyyyyyyyyyyyyyyyyyyyyyyy");

      setLoading(false);

      setList(lists);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + DEPARTMENT + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: Name,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + RETAIL_OUTLET + CREATE;
      // const url = "https://development.batukit.com/retail_outlet/create";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        outlet_code: outlet_code,
        name: name,
        // omc_id: omc_id,
        omc_id: omcId,
        omc_type: omcType,
        region: regionId,
        city: cityId,
        territory: territoryId,
        state: stateId,
        so_id: so_id,
        tc_id: tc_id,
        tm_id: tm_id,
        ri_id: ri_id,
        rhq1_id: rhq1_id,
        rhq2_id: rhq2_id,
        employee_id: employee_id,
        sml_id: sml_id,
        address: address,
      };

      console.log("request body of create new RO", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      console.log("create new RO response", response);
      if (response.data.message == "retail outlet inserted successfully") {
        toast.success(response.data.message);
        setShowForm(false);
      }
      const data = response.data.message;
      console.log("data: ", data);

      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  //---------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + RETAIL_OUTLET + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };
  const feasibilityUpdate = (row, number) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure this RO is feasible?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${
            BASE_URL + RETAIL_OUTLET + RETAIL_OUTLET_FEASIBILITY
          }/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const requestBody = {
            feasibility_status: number,
          };
          const response = await axios.post(url, requestBody, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Updated!",
            text: "RO Feasibility is updated.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };
  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    setSelectedRow(data);
    setName(data.name);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  // const handleNewNameChange = (e) => {
  //   setNewName(e.target.value);
  // };
  const handleNewNameChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };

  const handleCreate = () => {
    createNew();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };

  const [omcList, setomcList] = useState([]);
  const [omcTypeList, setomcTypeList] = useState([]);
  // const [omcRate, setomcRate] = useState([]);

  const [newregionList, setnewregionList] = useState([]);
  const [stateList, setstateList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [territoryList, setterritoryList] = useState([]);
  const [employeIdList, setemployeIdList] = useState([]);
  const [smlList, setsmlList] = useState([]);
  const [soList, setsoList] = useState([]);
  const [tcList, settcList] = useState([]);
  const [tmList, settmList] = useState([]);
  const [siList, setsiList] = useState([]);
  const [rhq1List, setrhq1List] = useState([]);
  const [rhq2List, setrhq2List] = useState([]);

  const [omcId, setomcId] = useState("");
  const [omcType, setomcType] = useState("");
  const [rate, setrate] = useState("");
  const [regionId, setregionId] = useState("");
  const [stateId, setstateId] = useState("");
  const [cityId, setcityId] = useState("");
  const [territoryId, setterritoryId] = useState("");
  // console.log("stateId: ", stateId);
  // console.log("cityId: ", cityId);
  // console.log("cityList: ", cityList);

  const omcURL = BASE_URL + OMC + LIST;
  const regionURL = BASE_URL + REGION + LIST;
  const employeeURL = BASE_URL + EMPLOYEE + LIST;
  const smlURL = BASE_URL + SML + LIST;
  const listSoTcTmRiRhqURL = BASE_URL + RETAIL_OUTLET + LISTSOTCTMRIRHQ;

  const [fetchedomcList] = UseFetch(omcURL);
  const [fetchednewRegionList] = UseFetch(regionURL);
  const [fetchedEmployeeIdList] = UseFetch(employeeURL);
  const [fetchedSmlIdList] = UseFetch(smlURL);

  // console.log("cityList: ", cityList);
  // console.log("territoryList: ", territoryList);
  // console.log("state datalist: ", stateList);
  // console.log("newregionList: ", newregionList);
  // console.log("employeIdList: ", employeIdList);
  // console.log("smlList: ", smlList);

  useEffect(() => {
    if (fetchedomcList) {
      setomcList(fetchedomcList);
    }
  }, [fetchedomcList]);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    if (omcId) {
      const getList = async () => {
        const omcTypeURL = BASE_URL + OMC + OMCTYPE + omcId;
        try {
          const data = await axios.get(omcTypeURL, { headers });
          console.log("omc data List: ", data);
          setomcTypeList(data.data.data);
          // setstateList(data.data.data);
          // setrate(data.data.data.rate)
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getList();

      const getsotctmsirhqList = async () => {
        try {
          const data = await axios.get(listSoTcTmRiRhqURL + omcId, { headers });
          console.log("listSoTcTmRiRhqURL ", data);
          setsoList(data.data.salesOfficers);
          settcList(data.data.territoryCoordinators);
          settmList(data.data.territoryManagers);
          setsiList(data.data.stateRetailInitiatives);
          setrhq1List(data.data.retailHQRoleHolder);
          setrhq2List(data.data.headRetailInitiativesBrand);
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getsotctmsirhqList();
    }
  }, [omcId]);

  useEffect(() => {
    if (fetchedEmployeeIdList) {
      setemployeIdList(fetchedEmployeeIdList);
    }
  }, [fetchedEmployeeIdList]);

  useEffect(() => {
    if (fetchedSmlIdList) {
      setsmlList(fetchedSmlIdList);
    }
  }, [fetchedSmlIdList]);

  useEffect(() => {
    if (fetchednewRegionList) {
      setnewregionList(fetchednewRegionList);
    }
  }, [fetchednewRegionList]);

  useEffect(() => {
    if (regionId) {
      const stateURL = BASE_URL + STATE + STATEDATA + regionId;
      const getList = async () => {
        try {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const data = await axios.get(stateURL, { headers });
          console.log("state data: ", data);
          setstateList(data.data.data);
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getList();
    }
  }, [regionId]);

  useEffect(() => {
    if (stateId) {
      const territoryURL = BASE_URL + TERRITORY + TERRITORYDATA + stateId;
      // const cityURL = BASE_URL + CITY + CITYDATA +stateId
      const getList = async () => {
        try {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const data = await axios.get(territoryURL, { headers });
          console.log("city data: ", data);
          setterritoryList(data.data.data);
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getList();
    }
  }, [stateId]);

  useEffect(() => {
    if (territoryId) {
      const cityURL = BASE_URL + CITY + CITYDATA + territoryId;
      const getList = async () => {
        try {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const data = await axios.get(cityURL, { headers });
          console.log("city data: ", data);
          setcityList(data.data.data);
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getList();
    }
  }, [territoryId]);

  // useEffect(() => {
  //   const getList = async () => {
  //     try {
  //       const headers = {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization: `Bearer ${token}`,
  //       };
  //       const data = await axios.get(listSoTcTmRiRhqURL, { headers });
  //       console.log("listSoTcTmRiRhqURL ", data);
  //       setsoList(data.data.salesOfficers);
  //       settcList(data.data.territoryCoordinators);
  //       settmList(data.data.territoryManagers);
  //       setsiList(data.data.stateRetailInitiatives);
  //       setrhq1List(data.data.retailHQRoleHolder);
  //       setrhq2List(data.data.headRetailInitiativesBrand);
  //     } catch (error) {
  //       console.log("error: ", error);
  //     }
  //   };
  //   getList();
  // }, []);

  const handleRegionChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
  //  console.log("hiiiiiiiiiii", list);
    const rowData = list.map((row) => ({
      Name: row.name,
      Outlet_Code: row.outlet_code,
      Omc_Name: row.omc_id.name,
      Region_Name: row.region.name,
      Feasibility_Status: row.feasibility_status,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    // console.log("hiiichanduiiiii", rows[0].original);
    // const rowData = rows.map((row) => row.original);
    const rowData = rows.map((row) => ({
      Name: row.original.name,
      Outlet_Code: row.original.outlet_code,
      Omc_Name: row.original.omc_id?.name,
      Region_Name: row.original.region.name,
      Feasibility_Status: row.original.feasibility_status,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("outlet_code", {
      header: "Outlet Code",
      size: 30,
    }),
    columnHelper.accessor("omc_id.name", {
      header: "OMC Name",
      size: 30,
    }),
    columnHelper.accessor("region.name", {
      header: "Region",
      size: 30,
    }),
    columnHelper.accessor("feasibility_status", {
      header: "Feasibility",
      size: 30,
      Cell: (cell) =>
        cell.row.original.feasibility_status == 0 ? (
          <>
            <Button
              style={{ marginRight: "10px" }}
              variant="contained"
              onClick={() => feasibilityUpdate(cell.row.original, 1)}
            >
              Yes
            </Button>
            <Button
              style={{}}
              variant="contained"
              onClick={() => feasibilityUpdate(cell.row.original, 2)}
            >
              No
            </Button>
          </>
        ) : cell.row.original.feasibility_status == 1 ? (
          "Yes"
        ) : (
          "No"
        ),
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    // enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        {row.feasibility_status === 0 ? (
          <Button
            style={{ marginRight: "20px" }}
            variant="contained"
            onClick={() => feasibilityUpdate(row)}
          >
            Yes
          </Button>
        ) : row.feasibility_status === 1 ? (
          "Yes"
        ) : (
          "No"
        )}
        {/* <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>
        <Button
                              style={{ marginRight: "20px" }}
                              variant="contained"
                              // onClick={(e) => handleDelete(row._id)}
                              onClick={() => deleteRow(row)}

                            >
                              Delete
                            </Button>
         */}
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className="row">
        <div className="col-sm-6">
          {/* ADD RO button */}
          <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            // onClick={openCreateModal}import useFetchNew from './../components/useFetchNew';

            // edit by jaspal
            onClick={() => setShowForm(!showForm)}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            {showForm ? "Retail Outlet List" : "Add Retail Outlet"}
            {/* {showForm ? "Hide Form" : "Show Form"} */}
          </Button>
        </div>
        {/* RO Form */}
        <Container>
          {showForm && (
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="outlet_code">Outlet Code</InputLabel>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        type="number"
                        id="outlet_code"
                        name="outlet_code"
                        value={newName.outlet_code}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="name"
                        name="name"
                        value={newName.name}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="omc_id">OMC</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="omc_id"
                        name="omc_id"
                        value={omcId}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setomcId(e.target.value)}
                      >
                        {omcList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="omcType">Type</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="omcType"
                        name="omcType"
                        value={omcType}
                        onChange={(e) => setomcType(e.target.value)}
                        disabled={!omcId}
                      >
                        {omcTypeList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.area}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="region">Region</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="region"
                        name="region"
                        value={regionId}
                        onChange={(e) => setregionId(e.target.value)}
                      >
                        {newregionList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="state"
                        name="state"
                        // value={newName.state}
                        value={stateId}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setstateId(e.target.value)}
                        disabled={!regionId}

                        // value={selectedState}
                        // onChange={handleStateChange}
                        // disabled={!selectedRegion}
                      >
                        {stateList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="territory">Territory</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="territory"
                        name="territory"
                        value={territoryId}
                        onChange={(e) => setterritoryId(e.target.value)}
                        disabled={!stateId}
                      >
                        {territoryList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="city">City</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="city"
                        name="city"
                        value={cityId}
                        onChange={(e) => setcityId(e.target.value)}
                        disabled={!territoryId}
                      >
                        {cityList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* {fields.map((field, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <InputLabel htmlFor={field.name}>{field.name}</InputLabel>
          <TextField
            fullWidth
            size="small"
            select
            id={field.name}
            name={field.name}
            value={field.name}
            onChange={(e) =>console.log(e.target.value) }
          >
            {field.data.map((option, idx) => (
              <MenuItem key={idx} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ))} */}

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="so_id">
                        Sales Officer (Optional)
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="so_id"
                        name="so_id"
                        value={newName.so_id}
                        onChange={handleNewNameChange}
                      >
                        {soList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="tc_id">
                        Territory Coordinator (Optional)
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="tc_id"
                        name="tc_id"
                        value={newName.tc_id}
                        onChange={handleNewNameChange}
                      >
                        {tcList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="tm_id">
                        Territory Manager (Optional)
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="tm_id"
                        name="tm_id"
                        value={newName.tm_id}
                        onChange={handleNewNameChange}
                      >
                        {tmList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="ri_id">
                        State Retail Initiative (Optional)
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="ri_id"
                        name="ri_id"
                        value={newName.ri_id}
                        onChange={handleNewNameChange}
                      >
                        {siList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="rhq1_id">
                        Retail HQ Role Holder (Optional)
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="rhq1_id"
                        name="rhq1_id"
                        value={newName.rhq1_id}
                        onChange={handleNewNameChange}
                      >
                        {rhq1List.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="rhq2_id">
                        Head Retail Initiative & Brand (Optional)
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="rhq2_id"
                        name="rhq2_id"
                        value={newName.rhq2_id}
                        onChange={handleNewNameChange}
                      >
                        {rhq2List.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="employee_id">Employee</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="employee_id"
                        name="employee_id"
                        value={newName.employee_id}
                        onChange={handleNewNameChange}
                      >
                        {employeIdList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="sml_id">SML (Optional)</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="sml_id"
                        name="sml_id"
                        value={newName.sml_id}
                        onChange={handleNewNameChange}
                      >
                        {smlList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Address</InputLabel>
                      <TextareaAutosize
                        minRows={3}
                        id="address"
                        name="address"
                        value={newName.address}
                        onChange={handleNewNameChange}
                        style={{
                          width: "100%",
                          padding: "8px",
                          boxSizing: "border-box",
                        }}
                      />
                    </Grid>

                    {/* {[
                      // "region",
                      // "state",
                      // "city",
                      // "territory",
                      "so_id",
                      "tc_id",
                      "tm_id",
                      "ri_id",
                      "rhq1_id",
                      "rhq2_id",
                      "employee_id",
                      "sml_id",
                    ].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          id={field}
                          name={field}
                          value={newName[field]}
                          onChange={handleNewNameChange}
                        >
                          <MenuItem value="">
                            <em>Select {field}</em>
                          </MenuItem>
                        </TextField>
                      </Grid>
                    ))} */}
                  </Grid>
                  <div className="div m-3">
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-secondary mt-2"
                      // onClick={closeCreateModal}
                      onClick={() => setShowForm(!showForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-primary mt-2"
                      style={{ float: "right" }}
                      onClick={handleCreate}
                    >
                      Create
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          )}
        </Container>

        {/* new TABLE */}
        {!showForm && (
          <Row>
            {/* <Col xs={12} className="index">
              <MaterialReactTable table={table} />
            </Col> */}
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {Loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
          </Row>
        )}

        {/* End RO Form */}
        {/* Employee Table */}
        {/* <div className="col-sm-12 client">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                  <TableRow>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Outlet Code</TableCell>
                    <TableCell align="center">OMC Name</TableCell>
                    <TableCell align="center">Region</TableCell>
                    <TableCell align="center">State</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: "#ffff",
                    }}
                  >
                    <TableCell align="center">jassa</TableCell>
                    <TableCell align="center">abc</TableCell>
                    <TableCell align="center">omc name</TableCell>
                    <TableCell align="center">jksdnckh</TableCell>
                    <TableCell align="center">State</TableCell>
                    <TableCell align="center">click</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              // count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div> */}
        {/* new table */}

        {/* =========================================================================== */}
        {/* {!showForm && (
          <div className="col-md-12">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                    <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Outlet Code</TableCell>
                      <TableCell align="center">OMC Name</TableCell>
                      <TableCell align="center">Region</TableCell>
                      <TableCell align="center">Feasibility</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.outlet_code}</TableCell>
                        <TableCell align="center">{row.omcName}</TableCell>
                        <TableCell align="center">{row.region}</TableCell>
                        <TableCell align="center">{row.feasibility_status==0?<Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={() => feasibilityUpdate(row)}
                          >
                            Yes
                          </Button>:row.feasibility_status==1?'Yes':'No'}</TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

          </div>
        )} */}

        {/* =========================================================================================== */}
        {/*End Employee List */}
      </div>
      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Department"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Department</h3>
          <form>
            <input type="hidden" name="name" value={selectedRow._id} readOnly />

            <div className="form-group p-2">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}
    </div>
  );
};

export default Ro;
