import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  STATE,
  CITY,
  TERRITORY,
  SML,
  CITYDATA,
  TERRITORYDATA,
  RETAIL_OUTLET,
  BPCLIOCLHPCL_REPORTS,
} from "../helpers/url_helper.js";
import {
  Container,
  TextField,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Box,
  TextareaAutosize,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Col, Row } from "react-bootstrap";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { IconButton } from "rsuite";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinearProgress from "@mui/material/LinearProgress";
import UseFetch from "../components/UseFetch.js";
Modal.setAppElement("#root");
const BPCL = () => {
  // const token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NjZlZTgwNTRkMTZiMWI1YWE4MGQ1YSIsImlhdCI6MTcxODg4MDU3MywiZXhwIjoxNzE4OTY2OTczfQ.6NHZRmuAZ2KhGUFQkgQABCBRa85e6wkX2lLys8XyO50";
  const token = sessionStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  console.log("bpcl list: ", list);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  // const [newName, setNewName] = useState("");

  // edit by jaspal
  const [showForm, setShowForm] = useState(false);
  // const [newName, setNewName] = useState({
  //   name: "",
  //   phone: "",
  //   email: "",
  //   address: "",
  //   territory: "",
  //   state: "",
  //   city: "",
  // });
  // console.log(newName);
  const [showSuccessMessage, setshowSuccessMessage] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    const bpclid = "667fa10c2f3efded3ced15d8";
    
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        BASE_URL + RETAIL_OUTLET + BPCLIOCLHPCL_REPORTS + bpclid,
        {
          headers,
        }
      );
      const list = response.data.data;
      console.log("bpcl list =", list);
      
      setList(list);
      setLoading(false);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + SML + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: name,
        phone: phone,
        email: email,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.message == "sml updated successfully") {
        toast.success(data.message);
      }
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + SML + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: name,
        phone: phone,
        email: email,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      console.log("create new SML response", response);
      const { message } = response.data;

      if ((response.data.message = "sml inserted successfully")) {
        toast.success(message);

        setShowForm(false);
      }
      const data = response.data;
      console.log("data: ", data);

      fetchList();
    } catch (error) {
      toast.error(error);

      console.error("Error connecting to API", error);
    }
  };

  //---------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + SML + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    console.log("data: ", data);

    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewNameChange = (e) => {
    // setNewName(e.target.value);
  };
  // const handleNewNameChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewName({
  //     ...newName,
  //     [name]: value,
  //   });
  // };

  const handleCreate = () => {
    createNew();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const rowData = list.map((row) => ({
      Outlet_code: row.outlet_code,
      Ro_Name: row.roName,
      So_Name: row.soName,
      Region_Name: row.regionName,
      City_Name: row.cityName,
      ClassOfMarket: row.classOfMarket,
      TerritoryName: row.territoryName,
      State: row.stateName,
      Area_square_Feet: row.areasquareFeet,
      PricePerSquareFeet: row.pricePerSquareFeet,
      Rentals: row.rentals,
      SecurityDeposit: row.securityDeposit,
      SecurityDepositGiven: row.securityDepositGiven,
      CommissioningMonth: row.commissioningMonth,
      CommissioningYear: row.commissioningYear,
      ActualDateOfCommissioning: row.actualDateOfCommissioning,
      InvoiceReceivedStatus: row.invoiceReceivedStatus,
      PaymentStatus: row.paymentStatus,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      Outlet_code: row.original.outlet_code,
      Ro_Name: row.original.roName,
      So_Name: row.original.soName,
      Region_Name: row.original.regionName,
      City_Name: row.original.cityName,
      ClassOfMarket: row.original.classOfMarket,
      TerritoryName: row.original.territoryName,
      State: row.original.stateName,
      Area_square_Feet: row.original.areasquareFeet,
      PricePerSquareFeet: row.original.pricePerSquareFeet,
      Rentals: row.original.rentals,
      SecurityDeposit: row.original.securityDeposit,
      SecurityDepositGiven: row.original.securityDepositGiven,
      CommissioningMonth: row.original.commissioningMonth,
      CommissioningYear: row.original.commissioningYear,
      ActualDateOfCommissioning: row.original.actualDateOfCommissioning,
      InvoiceReceivedStatus: row.original.invoiceReceivedStatus,
      PaymentStatus: row.original.paymentStatus,
    }));
    // const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("outlet_code", {
      header: "Customer Code",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.outlet_code}
        </div>
      ),
    }),
    columnHelper.accessor("roName", {
      header: "Retail Outlet Name",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>{cell.row.original.roName}</div>
      ),
    }),
    columnHelper.accessor("soName", {
      header: "SO Name",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>{cell.row.original.soName}</div>
      ),
    }),
    columnHelper.accessor("regionName", {
      header: "Region",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.regionName}
        </div>
      ),
    }),
    columnHelper.accessor("cityName", {
      header: "Name of City ",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>{cell.row.original.cityName}</div>
      ),
    }),
    columnHelper.accessor("classOfMarket", {
      header: "Class of Market",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.classOfMarket}
        </div>
      ),
    }),
    columnHelper.accessor("territoryName", {
      header: "Territory",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.territoryName}
        </div>
      ),
    }),
    columnHelper.accessor("stateName", {
      header: "State",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>{cell.row.original.stateName}</div>
      ),
    }),
    columnHelper.accessor("areasquareFeet", {
      header: "Area of kiosk",
      size: 7,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.areasquareFeet}
        </div>
      ),
    }),
    columnHelper.accessor("pricePerSquareFeet", {
      header: "Signange Fee",
      size: 7,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.pricePerSquareFeet}
        </div>
      ),
    }),
    columnHelper.accessor("rentals", {
      header: "Rentals (C = A X B)",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>{cell.row.original.rentals}</div>
      ),
    }),
    columnHelper.accessor("securityDeposit", {
      header: "Security Deposit",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.securityDeposit}
        </div>
      ),
    }),
    columnHelper.accessor("securityDepositGiven", {
      header: "Security Given",
      size: 7,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.securityDepositGiven}
        </div>
      ),
    }),
    columnHelper.accessor("commissioningMonth", {
      header: "Commissioning Month",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.commissioningMonth}
        </div>
      ),
    }),
    columnHelper.accessor("commissioningYear", {
      header: "Commissioning Year",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.commissioningYear}
        </div>
      ),
    }),
    columnHelper.accessor("actualDateOfCommissioning", {
      header: "Actual Date Commissioning",
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.actualDateOfCommissioning}
        </div>
      ),
      Cell: (cell) => cell.row.original.actualDateOfCommissioning.split("T")[0],
    }),
    columnHelper.accessor("invoiceReceivedStatus", {
      header: "Invoice Received",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.invoiceReceivedStatus}
        </div>
      ),
    }),
    columnHelper.accessor("paymentStatus", {
      header: "Payment Status",
      size: 30,
      Cell: ({ cell }) => (
        <div style={{ textAlign: "center" }}>
          {cell.row.original.paymentStatus}
        </div>
      ),
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,

    // enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        color: "#4a2a7d",
        background: "#DCDAFF",
        // background: 'red',
        fontWeight: "normal",
        fontSize: "2px",
        zIndex: "auto",
        // Remove z-index for the header
      },

      // sx: (theme) => ({
      //   color: "#4a2a7d",
      //   background: "#DCDAFF",
      //   fontWeight: "normal",
      //   fontSize: "10px",
      // }),
    },

    muiTableFooterCellProps: {
      sx: {
        zIndex: "auto", // Remove z-index for the footer
      },
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton
          style={{
            border: "white",
            backgroundColor: "transparent",
          }}
          onClick={() => openEditModal(row.original)}
        >
          <EditIcon color="error" />
        </IconButton>
        <IconButton
          style={{
            border: "white",
            backgroundColor: "transparent",
          }}
          onClick={() => deleteRow(row.original)}
        >
          <DeleteIcon color="error" />
        </IconButton>
        {/* <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          // onClick={(e) => handleDelete(row._id)}
          onClick={() => deleteRow(row.original)}
        >
          Delete
        </Button> */}
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
          zIndex: "auto",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    const headerCells = document.querySelectorAll(".MuiTableCell-head");
    const footerCells = document.querySelectorAll(".MuiTableCell-footer");

    headerCells.forEach((cell) => {
      cell.style.zIndex = "auto";
    });

    footerCells.forEach((cell) => {
      cell.style.zIndex = "auto";
    });
  }, []);

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* {showSuccessMessage.length > 0 ? (
        <Alert variant="outlined" severity="success">
          {showSuccessMessage}
        </Alert>
      ) : (
        <></>
      )} */}

      <div className="row">
        <div className="col-sm-6">
          {/* ADD RO button */}
          {/* <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            
            onClick={() => setShowForm(!showForm)}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            {showForm ? "BPCL List" : "ADD BPCL"}
          </Button> */}
        </div>
        {/* RO Form */}

        {/* End RO Form */}

        {/* new table */}
        {!showForm && (
          <Row className="ro_phone">
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
          </Row>
        )}

        {/* Employee List */}
        {/* {!showForm && (
          <div className="col-md-12">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Phone</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">State</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.state}</TableCell>

                        <TableCell align="center">
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={() => openEditModal(row)}
                          >
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() => deleteRow(row)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        )} */}
        {/* =========================================================================================== */}
        {/*End Employee List */}
      </div>
      {/* Edit Region Modal */}

      {/* End Edit Region Modal */}
    </div>
  );
};

export default BPCL;
