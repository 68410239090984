import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Swal from "sweetalert2";
import LinearProgress from "@mui/material/LinearProgress";
import {
  BASE_URL,
  EMPLOYEE,
  TERRITORY,
  CITY,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  STATE,
  DEPARTMENT,
  REGION,
  DESIGNATION,
} from "../helpers/url_helper.js";
import AddIcon from "@mui/icons-material/Add";

import {
  Container,
  TextField,
  Card,
  CardContent,
  MenuItem,
  TextareaAutosize,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import Button from "@mui/material/Button";

import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseFetch from "../components/UseFetch.js";
import { Link } from "react-router-dom";
import zIndex from "@mui/material/styles/zIndex.js";
Modal.setAppElement("#root"); // Required for accessibility

const Employee = () => {
  const token = sessionStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  console.log("list: ", list);
  const [regionList, setRegionList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [state, setstate] = useState("");
  const [joining_date, setjoining_date] = useState("");
  const [designation, setdesignation] = useState("");
  const [region, setregion] = useState("");
  const [department, setdepartment] = useState("");
  // const [role, setrole] = useState([{name:"Admin"},{name:"Employee"},{name:"Manager"}]);
  const [role, setrole] = useState("");
  const [reporting_boss, setreporting_boss] = useState("");
  const [city, setcity] = useState("");
  const [territory, setterritory] = useState("");
  const [address, setaddress] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [newRegionName, setRegionName] = useState("");
  const [newName, setnewName] = useState("");

  // edit by jaspal
  const [showForm, setShowForm] = useState(false);
  // const [newName, setNewName] = useState({
  //   name: "",
  //   phone: "",
  //   email: "",
  //   joining_date: "",
  //   designation: "",
  //   region: "",
  //   department: "",
  //   address: "",
  //   role: "",
  //   reporting_boss: "",
  // });

  useEffect(() => {
    fetchList();
    fetchStateList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + EMPLOYEE + LIST, { headers });
      // console.log("response: ", response);
      const list = response.data.data;
      console.log("Employee list=", list);

      setList(list);
    setLoading(false);

    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  const fetchStateList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + CITY + LIST, { headers });
      const regionList = response.data.data;
      // console.log("Employee list=", regionList);

      setRegionList(regionList);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + EMPLOYEE + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: name,
        phone: phone,
        email: email,
        joining_date: joining_date,
        designation: designation,
        region: region,
        department: department,
        address: address,
        role: role,
        reporting_boss: reporting_boss,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      if (response.data.message == "employee updated successfully") {
        toast.success(response.data.message);
      }
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // -------------------------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + EMPLOYEE + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: name,
        phone: phone,
        email: email,
        joining_date: joining_date,
        designation: designation,
        region: region,
        department: department,
        address: address,
        role: role,
        reporting_boss: reporting_boss,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      if (response.data.message == "employee created successfully") {
        toast.success(response.data.message);

        setShowForm(false);
      }
      console.log("create new employye response", response);
      fetchList();
    } catch (error) {
      toast.error(error);
      console.error("Error connecting to API", error);
    }
  };

  //-----------------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + EMPLOYEE + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    console.log(data);
    setSelectedRow(data);
    setname(data.name);
    setphone(data.phone);
    setemail(data.email);
    setjoining_date(data.joining_date);
    setaddress(data.address);

    setdesignation(data.designation);
    setregion(data.region);
    setdepartment(data.department);
    setrole(data.role);
    setreporting_boss(data.reporting_boss);

    // setRegionName(data.city_id);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewNameChange = (e) => {
    // setNewName(e.target.value);
  };

  // edit by jaspal
  // const handleNewNameChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewName({
  //     ...newName,
  //     [name]: value,
  //   });
  // };
  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };

  const handleCreate = () => {
    createNew();
    closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRegionChange = (e) => {
    setRegionName(e.target.value);
  };

  const [designationList, setdesignationList] = useState([]);
  const [newregionList, setnewregionList] = useState([]);
  const [departmentList, setdepartmentList] = useState([]);

  const designationURL = BASE_URL + DESIGNATION + LIST;
  const regionURL = BASE_URL + REGION + LIST;
  const departmentURL = BASE_URL + DEPARTMENT + LIST;

  const [fetchedDesignationList] = UseFetch(designationURL);
  const [fetchednewRegionList] = UseFetch(regionURL);
  const [fetchedDepartmentList] = UseFetch(departmentURL);
  // console.log("newregionList: ", newregionList);
  // console.log("departmentList: ", departmentList);
  // console.log("designationList: ", designationList);

  useEffect(() => {
    if (fetchedDesignationList) {
      setdesignationList(fetchedDesignationList);
    }
  }, [fetchedDesignationList]);

  useEffect(() => {
    if (fetchednewRegionList) {
      setnewregionList(fetchednewRegionList);
    }
  }, [fetchednewRegionList]);

  useEffect(() => {
    if (fetchedDepartmentList) {
      setdepartmentList(fetchedDepartmentList);
    }
  }, [fetchedDepartmentList]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = (rows) => {
    const rowData = rows.map((row) => ({
      Name: row.original.name,
      Phone: row.original.phone,
      Email: row.original.email,
      DesignationName: row.original.designationName,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("phone", {
      header: "Phone",
      size: 30,
    }),
    columnHelper.accessor("email", {
      header: "Email",
      size: 30,
    }),
    columnHelper.accessor("designationName", {
      header: "Designation",
      size: 30,
    }),

    // columnHelper.accessor("Designation", {
    //   header: "Designation",
    //   size: 30,
    // }),
    // columnHelper.accessor("TaggedClient", {
    //   header: "Tagged Client",
    //   size: 60,
    //   Cell: (cell) => (
    //     <IconButton onClick={() => openViewModal(cell.row.original)}>
    //       <RemoveRedEye color="error" />
    //     </IconButton>
    //   ),
    // }),
  ];
  const [employeeList, setEmployeeList] = useState([]);

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: {
        color: "#4a2a7d",
        background: "#DCDAFF",
        // background: 'red',
        fontWeight: "normal",
        fontSize: "10px",
        zIndex: "auto", // Remove z-index for the header
      },
      //simple styling with the `sx` prop, works just like a style prop in this example

      // sx: (theme) => ({
      //   color: "#4a2a7d",
      //   background: "#DCDAFF",
      //   fontWeight: "normal",
      //   fontSize: "10px",
      //   zIndex:"auto"
      // }),
    },
    muiTableFooterCellProps: {
      sx: {
        zIndex: "auto", // Remove z-index for the footer
      },
    },

    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>
        <IconButton onClick={() => deleteRow(row.original)}>
          <DeleteIcon color="error" />
        </IconButton>

        {/* <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          // onClick={(e) => handleDelete(row._id)}
          onClick={() => deleteRow(row.original)}
        >
          Delete
        </Button> */}
        {/* <IconButton onClick={() => openEditModal(row.original)}>
          <Link to="/client_kyc" state={row.original}>
            <Button style={{ marginRight: "30px" }} variant="contained">
              KYC
            </Button>
          </Link>
        </IconButton> */}
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
          zIndex: "auto",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    const headerCells = document.querySelectorAll(".MuiTableCell-head");
    const footerCells = document.querySelectorAll(".MuiTableCell-footer");

    headerCells.forEach((cell) => {
      cell.style.zIndex = "auto";
    });

    footerCells.forEach((cell) => {
      cell.style.zIndex = "auto";
    });
  }, []);
  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* Employee List */}
      <div className="row">
        <div className="col-sm-6">
          <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            // onClick={openCreateModal}
            onClick={() => setShowForm(!showForm)}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            {showForm ? "Employee List" : "Create Employee"}
          </Button>
        </div>

        {/* Employee Form Card input details */}

        {showForm && (
          <Container className="ro">
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Phone</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setphone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Email</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="joining_date">
                        Joining Date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="joining_date"
                        name="joining_date"
                        value={joining_date}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setjoining_date(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="designation">Designation</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="designation"
                        name="designation"
                        value={designation}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setdesignation(e.target.value)}
                      >
                        {designationList &&
                          designationList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="region">Region</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="region"
                        name="region"
                        value={region}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setregion(e.target.value)}
                      >
                        {newregionList &&
                          newregionList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="department">Department</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="department"
                        name="department"
                        value={department}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setdepartment(e.target.value)}
                      >
                        {departmentList &&
                          departmentList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="role">Role</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="role"
                        name="role"
                        value={role}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setrole(e.target.value)}
                      >
                        {/* {role && role.map((field,index) =><MenuItem key={index} value={field.name}>
                      <em>{field.name} </em>
                    </MenuItem> )} */}
                        <MenuItem value="Admin">
                          <em>Admin </em>
                        </MenuItem>
                        <MenuItem value="Manager">
                          <em>Manager </em>
                        </MenuItem>
                        <MenuItem value="Employee">
                          <em>Employee </em>
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="reporting_boss">
                        Reporting Boss
                      </InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="reporting_boss"
                        name="reporting_boss"
                        value={reporting_boss}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setreporting_boss(e.target.value)}
                      >
                        {list &&
                          list.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    {/* for text fields */}
                    {/* {["name", "phone", "email", "joining_date"].map(
                      (field, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <InputLabel key={index} htmlFor={field}>
                            {transformString(field)}
                          </InputLabel>
                          <TextField  
                            fullWidth
                            size="small"
                            type="text"
                            id={field}
                            name={field}
                            value={newName[field]}
                            onChange={handleNewNameChange}
                          />
                        </Grid>
                      )
                    )} */}

                    {/* {[
                      "designation",
                      "region",
                      "department",
                      "role",
                      "reporting_boss",
                    ].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          id={field}
                          name={field}
                          value={newName[field]}
                          onChange={handleNewNameChange}
                        >
                          <MenuItem value="">
                            <em>Select {field}</em>
                          </MenuItem>
                        </TextField>
                      </Grid>
                    ))} */}

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Address</InputLabel>
                      <TextareaAutosize
                        minRows={3}
                        id="address"
                        name="address"
                        value={address}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setaddress(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px",
                          boxSizing: "border-box",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div className="div m-3">
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-secondary mt-2"
                      // onClick={closeCreateModal}
                      onClick={() => setShowForm(!showForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-primary mt-2"
                      style={{ float: "right" }}
                      onClick={handleCreate}
                    >
                      Create
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Container>
        )}

        {/* New Table Inserrted */}
        {!showForm && (
          <Row className="ro_phone">
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>{" "}
          </Row>
        )}

        {/* End Employee Form card */}
        {/* show Employee list */}
        {/* {!showForm && (
          <div className="col-md-12">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Phone</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Designation</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.designation}</TableCell>

                        <TableCell align="center">
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={() => openEditModal(row)}
                          >
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() => deleteRow(row)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        )} */}

        {/* =========================================================================================== */}
        {/* End show Employee list */}
      </div>
      {/* End Region List */}

      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          // className="modelZindex"
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit City"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Employee</h3>
          <form>
            <Container>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Phone</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Email</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="joining_date">
                        Joining Date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="joining_date"
                        name="joining_date"
                        value={joining_date}
                        onChange={(e) => setjoining_date(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="designation">Designation</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="designation"
                        name="designation"
                        value={designation}
                        onChange={(e) => setdesignation(e.target.value)}
                      >
                        {designationList &&
                          designationList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="region">Region</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="region"
                        name="region"
                        value={region}
                        onChange={(e) => setregion(e.target.value)}
                      >
                        {newregionList &&
                          newregionList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="department">Department</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="department"
                        name="department"
                        value={department}
                        onChange={(e) => setdepartment(e.target.value)}
                      >
                        {departmentList &&
                          departmentList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="role">Role</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="role"
                        name="role"
                        value={role}
                        onChange={(e) => setrole(e.target.value)}
                      >
                        <MenuItem value="Admin">
                          <em>Admin </em>
                        </MenuItem>
                        <MenuItem value="Manager">
                          <em>Manager </em>
                        </MenuItem>
                        <MenuItem value="Employee">
                          <em>Employee </em>
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="reporting_boss">
                        Reporting Boss
                      </InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="reporting_boss"
                        name="reporting_boss"
                        value={reporting_boss}
                        onChange={(e) => setreporting_boss(e.target.value)}
                      >
                        {list &&
                          list.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Address</InputLabel>
                      <TextareaAutosize
                        minRows={3}
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px",
                          boxSizing: "border-box",
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Container>

            <input type="hidden" value={selectedRow._id} readOnly />

            {/* <div className="form-group p-2">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>
            <div className="form-group p-2">
              <label>City</label>
              <select
                className="form-control"
                value={newRegionName}
                onChange={handleRegionChange}
              >
                <option value="">Select City</option>
                {regionList.map((region) => (
                  <option key={region._id} value={region._id}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div> */}

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}

      {/* Create New Region Modal */}
      {/* <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeCreateModal}
        contentLabel="Create Territory"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
          },
        }}
      >
        <h3 className="text-center fw-bold form_header">Create Territory</h3>
        <form>
          <div className="row">
            <div className="col">
              <div className="form-group p-2">
                <label>Name</label>
                <input type="text" onChange={handleNewNameChange} required />
              </div>
              <div className="form-group p-2">
                <label>City</label>
                <select
                  className="form-control"
                  value={newRegionName}
                  onChange={handleRegionChange}
                >
                  <option value="">Select City</option>
                  {regionList.map((region) => (
                    <option key={region._id} value={region._id}>
                      {region.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="div m-3">
            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              onClick={closeCreateModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={handleCreate}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal> */}
      {/* End Create New Region Modal */}
    </div>
  );
};

export default Employee;
