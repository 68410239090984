import React, { useState, useEffect, useRef } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  REGION,
  RETAIL_OUTLET,
  RETAIL_OUTLET_FEASIBILITY,
  STATE,
  CITY,
  TERRITORY,
  EMPLOYEE,
  RETAIL_OUTLET_FEASIBILITY_LIST,
  RETAIL_OUTLET_LOI

} from "../helpers/url_helper.js";
import {
  Container,
  TextField,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Box,
  IconButton,

} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";

import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";









import UseFetch from "../components/UseFetch.js";
Modal.setAppElement("#root"); // Required for accessibility

const FeasibleRO = () => {
  const token = sessionStorage.getItem("token");
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NjZlZTgwNTRkMTZiMWI1YWE4MGQ1YSIsImlhdCI6MTcxODg4MDU3MywiZXhwIjoxNzE4OTY2OTczfQ.6NHZRmuAZ2KhGUFQkgQABCBRa85e6wkX2lLys8XyO50";

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  // const [newName, setNewName] = useState("");

  // edit by jaspal
  const [showForm, setShowForm] = useState(false);
  const [newName, setNewName] = useState({
    outlet_code: "",
    name: "",
    omc_id: "",
    region: "",
    city: "",
    territory: "",
    state: "",
    so_id: "",
    tc_id: "",
    tm_id: "",
    ri_id: "",
    rhq1_id: "",
    rhq2_id: "",
    employee_id: "",
    sml_id: "",
  });
  // console.log(newName);
  const [showSuccessMessage, setshowSuccessMessage] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
   
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + RETAIL_OUTLET + RETAIL_OUTLET_FEASIBILITY_LIST, {
        headers,
      });
      const list = response.data.data;
      // console.log("Employee list=", list);
      setLoading(false)
      setList(list);

    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + DEPARTMENT + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: Name,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -------------create new region----------------------
  const createNew = async () => {
    try {
      // const url = BASE_URL + DEPARTMENT + CREATE;
      const url = "https://development.batukit.com/retail_outlet/create";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      // const requestBody = {
      //   name:"Prslsbh",
      //   omc_id:"223err5"

      // };

      // console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, newName, { headers });
      console.log("create new employye response", response);
      if (response) {
        setshowSuccessMessage(response.data.message);
      }
      const data = response.data.message;
   //   console.log("data: ", data);

      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  //---------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + RETAIL_OUTLET + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };
  const feasibilityUpdate = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure you LOI is sent?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + RETAIL_OUTLET + RETAIL_OUTLET_LOI}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
         
          const response = await axios.get(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Updated!",
            text: "LOI status updated.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };
  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    setSelectedRow(data);
    setName(data.name);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  // const handleNewNameChange = (e) => {
  //   setNewName(e.target.value);
  // };
  const handleNewNameChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };

  const handleCreate = () => {
    createNew();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };
  
  
  
  
  
//   const [stateList, setstateList] = useState([]);
//   const [cityList, setcityList] = useState([]);
//   const [territoryList, setterritoryList] = useState([]);
//   const [newregionList, setnewregionList] = useState([]);
// const [employeIdList, setemployeIdList] = useState([]);
  
  
  // const regionURL = BASE_URL + REGION + LIST;
  // const stateURL = BASE_URL + STATE + LIST;
  // const cityURL = BASE_URL + CITY + LIST;
  // const territoryURL = BASE_URL + TERRITORY + LIST;
  // const employeeURL = BASE_URL + EMPLOYEE + LIST;


  
  // const [fetchedStateList] = UseFetch(stateURL);
  // const [fetchedCityList] = UseFetch(cityURL);
  // const [fetchedTerritoryList] = UseFetch(territoryURL);
  // const [fetchednewRegionList] = UseFetch(regionURL);
  // const [fetchedEmployeeIdList] = UseFetch(employeeURL);
  // console.log("cityList: ", cityList);
  // console.log("territoryList: ", territoryList);
  // console.log("stateData: ", stateList);
  // console.log("newregionList: ", newregionList);
  // console.log("fetchEdemployeeList: ", fetchedEmployeeIdList);

  // useEffect(() => {
  //   if (fetchedStateList) {
  //     setstateList(fetchedStateList);
  //   }
  // }, [fetchedStateList]);

  // useEffect(() => {
  //   if (fetchedCityList) {
  //     setcityList(fetchedCityList);
  //   }
  // }, [fetchedCityList]);

  // useEffect(() => {
  //   if (fetchedTerritoryList) {
  //     setterritoryList(fetchedTerritoryList);
  //   }
  // }, [fetchedTerritoryList]);
  
  // useEffect(() => {
  //   if (fetchednewRegionList) {
  //     setnewregionList(fetchednewRegionList);
  //   }
  // }, [fetchednewRegionList]);

  // useEffect(() => {
  //   if (fetchedEmployeeIdList) {
  //     setemployeIdList(fetchedEmployeeIdList);
  //   }
  // }, [fetchedEmployeeIdList]);


  // const fields = [
  //   { name: 'state', data: stateList },
  //   { name: 'city', data: cityList },
  //   { name: 'region', data: newregionList },
  //   { name: 'territory', data: territoryList }
  // ];



  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const rowData = list.map((row) => ({
      Name: row.name,
      Outlet_code: row.outlet_code,
      Omc_Name: row.omcName,
      Region_Name: row.regionName,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
   // const rowData = rows.map((row) => row.original);
   const rowData = rows.map((row) => ({
    Name: row.original.name,
    Outlet_code: row.original.outlet_code,
    Omc_Name: row.original.omcName,
    Region_Name: row.original.regionName,
  }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("outlet_code", {
      header: "Outlet Code",
      size: 30,
    }),
    columnHelper.accessor("omcName", {
      header: "OMC Name",
      size: 30,
    }),
    columnHelper.accessor("regionName", {
      header: "Region",
      size: 30,
    }),
    // columnHelper.accessor("loi_status", {
    //   header: "LOI Sent",
    //   size: 30,
    //   Cell: (cell)=>(cell.row.original.loi_status==0?<Button
    //       style={{ marginRight: "20px" }}
    //       variant="contained"
    //       onClick={() => feasibilityUpdate(cell)}
    //     >
    //        Yes
    //      </Button>:cell.row.original.loi_status==1?'Sent':'No')

    // }),

    // {row.loi_status==0?<Button
    //   style={{ marginRight: "20px" }}
    //   variant="contained"
    //   onClick={() => feasibilityUpdate(row)}
    // >
    //   Yes
    // </Button>:row.loi_status==1?'Sent':'No'}
    

    // columnHelper.accessor("Designation", {
    //   header: "Designation",
    //   size: 30,
    // }),
    // columnHelper.accessor("TaggedClient", {
    //   header: "Tagged Client",
    //   size: 60,
    //   Cell: (cell) => (
    //     <IconButton onClick={() => openViewModal(cell.row.original)}>
    //       <RemoveRedEye color="error" />
    //     </IconButton>
    //   ),
    // }),
  ];
  const [employeeList, setEmployeeList] = useState([]);

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    // enableRowActions: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: {
        color: "#4a2a7d",
        background: "#DCDAFF",
        // background: 'red',
        fontWeight: "normal",
        fontSize: "10px",
        zIndex: "auto", // Remove z-index for the header
      },
      //simple styling with the `sx` prop, works just like a style prop in this example

      // sx: (theme) => ({
      //   color: "#4a2a7d",
      //   background: "#DCDAFF",
      //   fontWeight: "normal",
      //   fontSize: "10px",
      //   zIndex:"auto"
      // }),
    },
    muiTableFooterCellProps: {
      sx: {
        zIndex: "auto", // Remove z-index for the footer
      },
    },

    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>
        <IconButton onClick={() => deleteRow(row.original)}>
          <DeleteIcon color="error" />
        </IconButton>

        {/* <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          // onClick={(e) => handleDelete(row._id)}
          onClick={() => deleteRow(row.original)}
        >
          Delete
        </Button> */}
        {/* <IconButton onClick={() => openEditModal(row.original)}>
          <Link to="/client_kyc" state={row.original}>
            <Button style={{ marginRight: "30px" }} variant="contained">
              KYC
            </Button>
          </Link>
        </IconButton> */}
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
          zIndex: "auto",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });





  return (
    <div className="container-fluid">
      

      <div className="row">
        <div className="col-sm-6 ">
          <h4>Feasible RO List</h4>
        </div>
        {/* RO Form */}
          {showForm && (
        <Container>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="outlet_code">Outlet Code</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        id="outlet_code"
                        name="outlet_code"
                        value={newName.outlet_code}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="name"
                        name="name"
                        value={newName.name}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="omc_id">OMC ID</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        id="omc_id"
                        name="omc_id"
                        value={newName.omc_id}
                        onChange={handleNewNameChange}
                      />
                    </Grid>



                    {/* {fields.map((field, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <InputLabel htmlFor={field.name}>{field.name}</InputLabel>
          <TextField
            fullWidth
            size="small"
            select
            id={field.name}
            name={field.name}
            value={field.name}
            onChange={(e) =>console.log(e.target.value) }
          >
            {field.data.map((option, idx) => (
              <MenuItem key={idx} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ))} */}


                    {[
                      // "region",
                      // "state",
                      // "city",
                      // "territory",
                      "so_id",
                      "tc_id",
                      "tm_id",
                      "ri_id",
                      "rhq1_id",
                      "rhq2_id",
                      "employee_id",
                      "sml_id",
                    ].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          id={field}
                          name={field}
                          value={newName[field]}
                          onChange={handleNewNameChange}
                        >
                          <MenuItem value="">
                            <em>Select {field}</em>
                          </MenuItem>
                        </TextField>
                      </Grid>
                    ))}
                  </Grid>
                  <div className="div m-3">
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-secondary mt-2"
                      onClick={closeCreateModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-primary mt-2"
                      style={{ float: "right" }}
                      onClick={handleCreate}
                    >
                      Create
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
        </Container>
          )}


        {/* New Table Inserrted */}
        {!showForm && (
          <Row >
            
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
            {" "}
          </Row>
        )}




        {/* End RO Form */}
        {/* Employee Table */}
        {/* <div className="col-sm-12 client">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                  <TableRow>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Outlet Code</TableCell>
                    <TableCell align="center">OMC Name</TableCell>
                    <TableCell align="center">Region</TableCell>
                    <TableCell align="center">State</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: "#ffff",
                    }}
                  >
                    <TableCell align="center">jassa</TableCell>
                    <TableCell align="center">abc</TableCell>
                    <TableCell align="center">omc name</TableCell>
                    <TableCell align="center">jksdnckh</TableCell>
                    <TableCell align="center">State</TableCell>
                    <TableCell align="center">click</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              // count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div> */}


        {/* new table */}

        {/* =========================================================================== */}
        {/* {!showForm && (
          <div className="col-md-12">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                    <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Outlet Code</TableCell>
                      <TableCell align="center">OMC Name</TableCell>
                      <TableCell align="center">Region</TableCell>
                      <TableCell align="center">LOI sent?</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.outlet_code}</TableCell>
                        <TableCell align="center">{row.omcName}</TableCell>
                        <TableCell align="center">{row.region}</TableCell>
                        <TableCell align="center">{row.loi_status==0?<Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={() => feasibilityUpdate(row)}
                          >
                            Yes
                          </Button>:row.loi_status==1?'Sent':'No'}</TableCell>
                        <TableCell align="center">
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={() => openEditModal(row)}
                          >
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() => deleteRow(row)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

          </div>
        )} */}
        {/* =========================================================================================== */}
        {/*End Employee List */}
      </div>
      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Department"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Department</h3>
          <form>
            <input type="hidden" name="name" value={selectedRow._id} readOnly />

            <div className="form-group p-2">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}
    </div>
  );
};

export default FeasibleRO;
