import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  STATE,
  CITY,
  TERRITORY,
  SML,
  CITYDATA,
  TERRITORYDATA,
  RETAIL_OUTLET,
  BPCLIOCLHPCL_REPORTS,
} from "../helpers/url_helper.js";
import {
  Container,
  TextField,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Box,
  TextareaAutosize,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Col, Row } from "react-bootstrap";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { IconButton } from "rsuite";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UseFetch from "../components/UseFetch.js";
Modal.setAppElement("#root");
const IOCL = () => {
  // const token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NjZlZTgwNTRkMTZiMWI1YWE4MGQ1YSIsImlhdCI6MTcxODg4MDU3MywiZXhwIjoxNzE4OTY2OTczfQ.6NHZRmuAZ2KhGUFQkgQABCBRa85e6wkX2lLys8XyO50";
  const token = sessionStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  console.log("iocl list: ", list);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  // const [newName, setNewName] = useState("");

  // edit by jaspal
  const [showForm, setShowForm] = useState(false);
  // const [newName, setNewName] = useState({
  //   name: "",
  //   phone: "",
  //   email: "",
  //   address: "",
  //   territory: "",
  //   state: "",
  //   city: "",
  // });
  // console.log(newName);
  const [showSuccessMessage, setshowSuccessMessage] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setLoading(false);
    const ioclid = "668b9f98b5a7045b6258a701";

    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        BASE_URL + RETAIL_OUTLET + BPCLIOCLHPCL_REPORTS + ioclid,
        {
          headers,
        }
      );
      const list = response.data.data;
      console.log("bpcl list =", list);

      setList(list);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + SML + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: name,
        phone: phone,
        email: email,
        state: stateId,
        city: cityId,
        territory: territoryId,
        address: address,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.message == "sml updated successfully") {
        toast.success(data.message);
      }
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + SML + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: name,
        phone: phone,
        email: email,
        state: stateId,
        city: cityId,
        territory: territoryId,
        address: address,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      console.log("create new SML response", response);
      const { message } = response.data;

      if ((response.data.message = "sml inserted successfully")) {
        toast.success(message);

        setShowForm(false);
      }
      const data = response.data;
      console.log("data: ", data);

      fetchList();
    } catch (error) {
      toast.error(error);

      console.error("Error connecting to API", error);
    }
  };

  //---------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + SML + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    console.log("data: ", data);

    setSelectedRow(data);
    setName(data.name);
    setname(data.name);
    setphone(data.phone);
    setemail(data.email);
    setaddress(data.address);
    setstateId(data.state);
    setcityId(data.city);
    setterritoryId(data.territory);

    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewNameChange = (e) => {
    // setNewName(e.target.value);
  };
  // const handleNewNameChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewName({
  //     ...newName,
  //     [name]: value,
  //   });
  // };

  const handleCreate = () => {
    createNew();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };

  const [stateList, setstateList] = useState([]);
  const [territoryList, setterritoryList] = useState([]);
  const [cityList, setcityList] = useState([]);

  const [stateId, setstateId] = useState("");
  const [territoryId, setterritoryId] = useState("");
  const [cityId, setcityId] = useState("");

  const stateURL = BASE_URL + STATE + LIST;

  const [fetchedStateList] = UseFetch(stateURL);

  useEffect(() => {
    if (fetchedStateList) {
      setstateList(fetchedStateList);
    }
  }, [fetchedStateList]);
  useEffect(() => {
    if (stateId) {
      const territoryURL = BASE_URL + TERRITORY + TERRITORYDATA + stateId;
      // const cityURL = BASE_URL + CITY + CITYDATA +stateId
      const getList = async () => {
        try {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const data = await axios.get(territoryURL, { headers });
          console.log("city data: ", data);
          setterritoryList(data.data.data);
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getList();
    }
  }, [stateId]);

  useEffect(() => {
    if (territoryId) {
      const cityURL = BASE_URL + CITY + CITYDATA + territoryId;
      const getList = async () => {
        try {
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const data = await axios.get(cityURL, { headers });
          console.log("city data: ", data);
          setcityList(data.data.data);
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getList();
    }
  }, [territoryId]);

  // useEffect(() => {
  //   if (fetchedCityList) {
  //     setcityList(fetchedCityList);
  //   }
  // }, [fetchedCityList]);

  // useEffect(() => {
  //   if (fetchedTerritoryList) {
  //     setterritoryList(fetchedTerritoryList);
  //   }
  // }, [fetchedTerritoryList]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = (rows) => {
    const rowData = rows.map((row) => ({
      Outlet_code: row.original.outlet_code,
      Ro_Name: row.original.roName,
      So_Name: row.original.soName,
      Region_Name: row.original.regionName,
      City_Name: row.original.cityName,
      ClassOfMarket: row.original.classOfMarket,
      TerritoryName: row.original.territoryName,
      State: row.original.stateName,
      Area_square_Feet: row.original.areasquareFeet,
      PricePerSquareFeet: row.original.pricePerSquareFeet,
      Rentals: row.original.rentals,
      SecurityDeposit: row.original.securityDeposit,
      SecurityDepositGiven: row.original.securityDepositGiven,
      CommissioningMonth: row.original.commissioningMonth,
      CommissioningYear: row.original.commissioningYear,
      ActualDateOfCommissioning: row.original.actualDateOfCommissioning,
      InvoiceReceivedStatus: row.original.invoiceReceivedStatus,
      PaymentStatus: row.original.paymentStatus,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("outlet_code", {
      header: "Customer Code",
      size: 30,
    }),
    columnHelper.accessor("roName", {
      header: "Retail Outlet Name",
      size: 30,
    }),
    columnHelper.accessor("soName", {
      header: "So Name",
      size: 30,
    }),
    columnHelper.accessor("regionName", {
      header: "Region",
      size: 30,
    }),
    columnHelper.accessor("cityName", {
      header: "Name of City ",
      size: 30,
    }),
    columnHelper.accessor("classOfMarket", {
      header: "Class of Market",
      size: 30,
    }),
    columnHelper.accessor("territoryName", {
      header: "Territory",
      size: 30,
    }),
    columnHelper.accessor("stateName", {
      header: "State",
      size: 30,
    }),
    columnHelper.accessor("areasquareFeet", {
      header: "Area of kiosk",
      size: 30,
    }),
    columnHelper.accessor("pricePerSquareFeet", {
      header: "Signange Fee",
      size: 30,
    }),
    columnHelper.accessor("rentals", {
      header: "Rentals (C=A X B)",
      size: 30,
    }),
    columnHelper.accessor("securityDeposit", {
      header: "Security Deposit",
      size: 30,
    }),
    columnHelper.accessor("securityDepositGiven", {
      header: "Security Given",
      size: 30,
    }),
    columnHelper.accessor("commissioningMonth", {
      header: "Commissioning Month",
      size: 30,
    }),
    columnHelper.accessor("commissioningYear", {
      header: "Commissioning Year",
      size: 30,
    }),
    columnHelper.accessor("actualDateOfCommissioning", {
      header: "Actual Date Commissioning",
      size: 30,
    }),
    columnHelper.accessor("invoiceReceivedStatus", {
      header: "Invoice Received",
      size: 30,
    }),
    columnHelper.accessor("paymentStatus", {
      header: "Payment Status",
      size: 30,
    }),
  ];
  const [employeeList, setEmployeeList] = useState([]);

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    // enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        color: "#4a2a7d",
        background: "#DCDAFF",
        // background: 'red',
        fontWeight: "normal",
        fontSize: "10px",
        zIndex: "auto", // Remove z-index for the header
      },
      // sx: (theme) => ({
      //   color: "#4a2a7d",
      //   background: "#DCDAFF",
      //   fontWeight: "normal",
      //   fontSize: "10px",
      // }),
    },

    muiTableFooterCellProps: {
      sx: {
        zIndex: "auto", // Remove z-index for the footer
      },
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton
          style={{
            border: "white",
            backgroundColor: "transparent",
          }}
          onClick={() => openEditModal(row.original)}
        >
          <EditIcon color="error" />
        </IconButton>
        <IconButton
          style={{
            border: "white",
            backgroundColor: "transparent",
          }}
          onClick={() => deleteRow(row.original)}
        >
          <DeleteIcon color="error" />
        </IconButton>
        {/* <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          // onClick={(e) => handleDelete(row._id)}
          onClick={() => deleteRow(row.original)}
        >
          Delete
        </Button> */}
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
          zIndex: "auto",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    const headerCells = document.querySelectorAll(".MuiTableCell-head");
    const footerCells = document.querySelectorAll(".MuiTableCell-footer");

    headerCells.forEach((cell) => {
      cell.style.zIndex = "auto";
    });

    footerCells.forEach((cell) => {
      cell.style.zIndex = "auto";
    });
  }, []);

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* {showSuccessMessage.length > 0 ? (
        <Alert variant="outlined" severity="success">
          {showSuccessMessage}
        </Alert>
      ) : (
        <></>
      )} */}

      <div className="row">
        <div className="col-sm-6">
          {/* ADD RO button */}
          {/* <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            onClick={() => setShowForm(!showForm)}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            {showForm ? "IOCL List" : "ADD IOCL"}
          </Button> */}
        </div>
        {/* RO Form */}
        <Container>
          {showForm && (
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Phone</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setphone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Email</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="state"
                        name="state"
                        // value={newName.state}
                        value={stateId}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setstateId(e.target.value)}
                        // disabled={!regionId}

                        // value={selectedState}
                        // onChange={handleStateChange}
                        // disabled={!selectedRegion}
                      >
                        {stateList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="territory">Territory</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="territory"
                        name="territory"
                        value={territoryId}
                        onChange={(e) => setterritoryId(e.target.value)}
                        disabled={!stateId}
                      >
                        {territoryList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="city">City</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="city"
                        name="city"
                        value={cityId}
                        onChange={(e) => setcityId(e.target.value)}
                        disabled={!territoryId}
                      >
                        {cityList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="state"
                        name="state"
                        value={state}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setstate(e.target.value)}
                      >
                        {stateList &&
                          stateList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="city">City</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="city"
                        name="city"
                        value={city}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setcity(e.target.value)}
                      >
                        {cityList &&
                          cityList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="territory">Territory</InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        type="text"
                        id="territory"
                        name="territory"
                        value={territory}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setterritory(e.target.value)}
                      >
                        {territoryList &&
                          territoryList.map((field, index) => (
                            <MenuItem key={index} value={field._id}>
                              <em>{field.name} </em>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="name">Address</InputLabel>
                      <TextareaAutosize
                        minRows={3}
                        id="address"
                        name="address"
                        value={address}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setaddress(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px",
                          boxSizing: "border-box",
                        }}
                      />
                    </Grid>
                    {/* {["state", "city", "territory"].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          id={field}
                          name={field}
                          // value={newName[field]}
                          onChange={handleNewNameChange}
                        >
                          <MenuItem value="">
                            <em>Select {field}</em>
                          </MenuItem>
                        </TextField>
                      </Grid>
                    ))} */}
                  </Grid>
                  <div className="div m-3">
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-secondary mt-2"
                      // onClick={closeCreateModal}
                      onClick={() => setShowForm(!showForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-primary mt-2"
                      style={{ float: "right" }}
                      onClick={handleCreate}
                    >
                      Create
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          )}
        </Container>
        {/* End RO Form */}

        {/* new table */}
        {!showForm && (
          <Row>
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
          </Row>
        )}

        {/* Employee List */}
        {/* {!showForm && (
          <div className="col-md-12">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Phone</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">State</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.state}</TableCell>

                        <TableCell align="center">
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={() => openEditModal(row)}
                          >
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() => deleteRow(row)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        )} */}
        {/* =========================================================================================== */}
        {/*End Employee List */}
      </div>
      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit SML"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit IOCL</h3>
          <form>
            <input type="hidden" name="name" value={selectedRow._id} readOnly />
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="name">Phone</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="name">Email</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="state">State</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      select
                      id="state"
                      name="state"
                      // value={newName.state}
                      value={stateId}
                      // onChange={handleNewNameChange}
                      onChange={(e) => setstateId(e.target.value)}
                      // disabled={!regionId}

                      // value={selectedState}
                      // onChange={handleStateChange}
                      // disabled={!selectedRegion}
                    >
                      {stateList.map((option, idx) => (
                        <MenuItem key={idx} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="territory">Territory</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      select
                      id="territory"
                      name="territory"
                      value={territoryId}
                      onChange={(e) => setterritoryId(e.target.value)}
                      disabled={!stateId}
                    >
                      {territoryList.map((option, idx) => (
                        <MenuItem key={idx} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="city">City</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      select
                      id="city"
                      name="city"
                      value={cityId}
                      onChange={(e) => setcityId(e.target.value)}
                      disabled={!territoryId}
                    >
                      {cityList.map((option, idx) => (
                        <MenuItem key={idx} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="name">Address</InputLabel>
                    <TextareaAutosize
                      minRows={3}
                      id="address"
                      name="address"
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}
    </div>
  );
};

export default IOCL;
