import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import LinearProgress from "@mui/material/LinearProgress";

import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  EMPLOYEE,
  SML,
  RETAIL_OUTLET,
  RO_SALES,
  OMC,
  RO_BY_OMC_ALLSTATUSONE,
} from "../helpers/url_helper.js";
import {
  Container,
  TextField,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Box,
  IconButton,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UseFetch from "../components/UseFetch.js";
Modal.setAppElement("#root"); // Required for accessibility

const RoSales = () => {
  // now RO sales is Franchise Sales
  const token = sessionStorage.getItem("token");
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NjZlZTgwNTRkMTZiMWI1YWE4MGQ1YSIsImlhdCI6MTcxODg4MDU3MywiZXhwIjoxNzE4OTY2OTczfQ.6NHZRmuAZ2KhGUFQkgQABCBRa85e6wkX2lLys8XyO50";

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  // const [newName, setNewName] = useState("");

  // edit by jaspal
  const [showForm, setShowForm] = useState(false);
  const [newName, setNewName] = useState({
    omc_id: "",
    ro_id: "",
    emp_id: "",
    sml_id: "",
    amt_received: "",
    payment_date: "",
    payment_mode: "",
    sales_type: "",
    payment_status: "",

  });
  
  const {
    omc_id,
    ro_id,
    payment_status,
    emp_id,
    sml_id,
    amt_received,
    payment_date,
    payment_mode,
    sales_type,
  } = newName;

  console.log("newName: ", newName);


  const [showSuccessMessage, setshowSuccessMessage] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + RO_SALES + LIST, {
        headers,
      });
      const list = response.data.data;
      console.log("ro sales list", list);

      setList(list);
      setLoading(false);

    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + RO_SALES + UPDATE +"/"+ id;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        
    payment_mode:payment_mode,
    sales_type:sales_type
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const { message } = response.data;

      if (response.data.message == "ro sale updated successfully") {
        toast.success(message);
      }
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + RO_SALES + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      // const requestBody = {
      //   name:"Prslsbh",
      //   omc_id:"223err5"

      // };

      // console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, newName, { headers });
      console.log("create new franchise Sales", response);
      
      const data = response.data.message;
      console.log("data: ", data);
      const { message } = response.data;
      if (response.data.message == "ro sale inserted successfully") {
        toast.success(message);
        setShowForm(false);
      }
      fetchList();
    } catch (error) {
      toast.error(error);
      console.error("Error connecting to API", error);
    }
  };

  //---------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + DEPARTMENT + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  
  const [roIdList, setroIdList] = useState([]);

  const [omcList, setomcList] = useState([]);

  const omcURL = BASE_URL + OMC + LIST;
  const [fetchedomcList] = UseFetch(omcURL);

  useEffect(() => {
    if (fetchedomcList) {
      setomcList(fetchedomcList);
    }
  }, [fetchedomcList]);
  // by:chandu

  useEffect(() => {
    if (omc_id) {
      // const stateURL = BASE_URL + STATE + STATEDATA + omc_id;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const getrombyomcList = async () => {
        try {
          const robyomcURL = BASE_URL + RO_BY_OMC_ALLSTATUSONE + omc_id;

          const data = await axios.get(robyomcURL, { headers });
          console.log("ro data LIST: ", data.data.data);
          // setstateList(data.data.data);
          setroIdList(data.data.data);
          // setrate(data.data.data.rate)
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getrombyomcList();
    }
  }, [omc_id]);
  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    setSelectedRow(data);
setNewName(data)
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  // const handleNewNameChange = (e) => {
  //   setNewName(e.target.value);
  // };
  const handleNewNameChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };

  const handleCreate = () => {
    createNew();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };

  const [employeIdList, setemployeIdList] = useState([]);
  const [smlIdList, setsmlIdList] = useState([]);

  const employeeURL = BASE_URL + EMPLOYEE + LIST;
  const smlURL = BASE_URL + SML + LIST;

  const [fetchedEmployeeIdList] = UseFetch(employeeURL);
  const [fetchedSmlIdList] = UseFetch(smlURL);
  // console.log("fetchedROIdList: ", fetchedROIdList);
  // console.log("fetchedSmlIdList: ", fetchedSmlIdList);
  // console.log("fetchEdemployeeList: ", fetchedEmployeeIdList);
  useEffect(() => {
    if (fetchedEmployeeIdList) {
      setemployeIdList(fetchedEmployeeIdList);
    }
  }, [fetchedEmployeeIdList]);
  useEffect(() => {
    if (fetchedSmlIdList) {
      setsmlIdList(fetchedSmlIdList);
    }
  }, [fetchedSmlIdList]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const rowData = list.map((row) => ({
      Retail_Name: row.retailName,
      SML_Name: row.smlName,
      Sales_Type: row.sales_type,
      Amt_Received: row.amt_received,
      Date_Added: row.date_added,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      Retail_Name: row.original.retailName,
      SML_Name: row.original.smlName,
      Sales_Type: row.original.sales_type,
      Amt_Received: row.original.amt_received,
      Date_Added: row.original.date_added,
    }));
    //const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("retailName", {
      header: "RO Name",
      size: 30,
    }),
    columnHelper.accessor("smlName", {
      header: "SML Name",
      size: 30,
    }),
    columnHelper.accessor("sales_type", {
      header: "Sale Type",
      size: 30,
    }),
    columnHelper.accessor("amt_received", {
      header: "Amount ",
      size: 30,
    }),
    columnHelper.accessor(`date_added`, {
      header: "Date",
      size: 30,
      Cell: (cell) => cell.row.original.date_added.split("T")[0],
    }),
  ];

  // {(row.loi_send_date).split('T')[0]}

  // columnHelper.accessor("Designation", {
  //   header: "Designation",
  //   size: 30,
  // }),
  // columnHelper.accessor("TaggedClient", {
  //   header: "Tagged Client",
  //   size: 60,
  //   Cell: (cell) => (
  //     <IconButton onClick={() => openViewModal(cell.row.original)}>
  //       <RemoveRedEye color="error" />
  //     </IconButton>
  //   ),
  // }),

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>
        {/* <Button
                              style={{ marginRight: "20px" }}
                              variant="contained"
                              // onClick={(e) => handleDelete(row._id)}
                              onClick={() => deleteRow(row)}

                            >
                              Delete
                            </Button> */}
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <div className="container-fluid">
      {/* {showSuccessMessage.length > 0 ? (
        <Alert variant="outlined" severity="success">
          {showSuccessMessage}
        </Alert>
      ) : (
          <></>
        )} */}

      <div className="row">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="col-sm-6">
          {/* ADD RO button */}
          <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            // onClick={openCreateModal}
            // edit by jaspal
            onClick={() => setShowForm(!showForm)}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            {showForm ? "Franchisee List" : "Add Franchisee"}
          </Button>
        </div>

        {/* RO Form */}
        <Container>
          {showForm && (
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="omc_id">OMC</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        type="number"
                        id="omc_id"
                        name="omc_id"
                        // value={newName.omc_id}
                        value={newName.omc_id}
                        // onChange={handleNewNameChange}
                        onChange={handleNewNameChange}
                      >
                        {omcList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            <em>{option.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="ro_id"> RO</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="ro_id"
                        name="ro_id"
                        value={newName.ro_id}
                        onChange={handleNewNameChange}
                        disabled={!omc_id}
                      >
                        {roIdList.map((e) => (
                          <MenuItem key={e._id} value={e._id}>
                            <em>{e.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="amt_received">
                        {" "}
                        Amount Received
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="amt_received"
                        name="amt_received"
                        value={newName.amt_received}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="payment_date"> Date</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="payment_date"
                        name="payment_date"
                        value={newName.payment_date}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="payment_mode">
                        {" "}
                        Payment Mode
                      </InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="payment_mode"
                        name="payment_mode"
                        value={newName.payment_mode}
                        onChange={handleNewNameChange}
                      >
                        <MenuItem value="Online">
                          <em>Online</em>
                        </MenuItem>
                        <MenuItem value="Offline">
                          <em>Offline</em>
                        </MenuItem>
                      </TextField>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="payment_status">
                        Payment Status
                      </InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        className="bor"
                        name="payment_status"
                        id="payment_status"
                        value={newName.payment_status}
                        onChange={handleNewNameChange}
                      >
                        <MenuItem value="Paid">
                          <em>Paid</em>
                        </MenuItem>
                        <MenuItem value="Unpaid">
                          <em>Unpaid</em>
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="sales_type"> Sales Type</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="sales_type"
                        name="sales_type"
                        value={newName.sales_type}
                        onChange={handleNewNameChange}
                      >
                        <MenuItem value="Rent">
                          <em>Rent</em>
                        </MenuItem>
                        <MenuItem value="Booking">
                          <em>Booking</em>
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="emp_id"> Employee</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="emp_id"
                        name="emp_id"
                        value={newName.emp_id}
                        onChange={handleNewNameChange}
                      >
                        {employeIdList.map((e) => (
                          <MenuItem value={e._id}>
                            <em>{e.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="sml_id"> SML</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="sml_id"
                        name="sml_id"
                        value={newName.sml_id}
                        onChange={handleNewNameChange}
                      >
                        {smlIdList.map((e) => (
                          <MenuItem value={e._id}>
                            <em>{e.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* for text fields */}
                    {/* {[
                      "amt_received",
                      "payment_date",
                      "payment_mode",
                      "sales_type",
                    ].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          type="text"
                          id={field}
                          name={field}
                          value={newName[field]}
                          onChange={handleNewNameChange}
                        />
                      </Grid>
                    ))} */}
                    {/* for select fields */}
                    {/* {["ro_id", "emp_id", "sml_id"].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          id={field}
                          name={field}
                          value={newName[field]}
                          onChange={handleNewNameChange}
                        >
                          <MenuItem value="">
                            <em>Select {field}</em>
                          </MenuItem>
                        </TextField>
                      </Grid>
                    ))} */}
                  </Grid>
                  <div className="div m-3">
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-secondary mt-2"
                      // onClick={closeCreateModal}
                      onClick={() => setShowForm(!showForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-primary mt-2"
                      style={{ float: "right" }}
                      onClick={handleCreate}
                    >
                      Create
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          )}
        </Container>
        {/* End RO Form */}

        {/* new table */}
        {!showForm && (
          <Row>
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
          </Row>
        )}
        {/* Employee List */}
        {!showForm && (
          <div className="col-md-12">
            {/* <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      <TableCell align="center">RO Name</TableCell>
                      <TableCell align="center">SML Name</TableCell>
                      <TableCell align="center">Sale Type</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{row.retailName}</TableCell>
                        <TableCell align="center">{row.smlName}</TableCell>
                        <TableCell align="center">{row.sales_type}</TableCell>
                        <TableCell align="center">{row.amt_received}</TableCell>
                        <TableCell align="center">{(row.date_added).split('T')[0]}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper> */}

            {/* =========================================================================================== */}
          </div>
        )}
        {/*End Employee List */}
      </div>
      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Franchise Sales"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Franchise Sales</h3>
          <form>
            
          <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="payment_mode">
                        {" "}
                        Payment Mode
                      </InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="payment_mode"
                        name="payment_mode"
                        value={newName.payment_mode}
                        onChange={handleNewNameChange}
                      >
                        <MenuItem value="Online">
                          <em>Online</em>
                        </MenuItem>
                        <MenuItem value="Offline">
                          <em>Offline</em>
                        </MenuItem>
                      </TextField>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="sales_type"> Sales Type</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="sales_type"
                        name="sales_type"
                        value={newName.sales_type}
                        onChange={handleNewNameChange}
                      >
                        <MenuItem value="Rent">
                          <em>Rent</em>
                        </MenuItem>
                        <MenuItem value="Booking">
                          <em>Booking</em>
                        </MenuItem>
                      </TextField>
                    </Grid>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}
    </div>
  );
};

export default RoSales;

// RO_SALE

// ro_sale/create
//             ro_id,
//             emp_id,
//             sml_id,
//             amt_received,
//             payment_date,
//             payment_mode,
//             sales_type,
//             status
