import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  EMPLOYEE,
  SML,
  RETAIL_OUTLET,
  RO_BOOKING,
  OMC,
  OMCTYPE,
  RO_BY_OMC,
} from "../helpers/url_helper.js";
import {
  Container,
  TextField,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Box,
  TextareaAutosize,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UseFetch from "../components/UseFetch.js";
Modal.setAppElement("#root"); // Required for accessibility

const RoBooking = () => {
  // RO Booking is SML Booking
  const token = sessionStorage.getItem("token");

  const [list, setList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [shiftModalIsOpen, setshiftModalIsOpen] = useState(false);
  const [selectedShiftButton, setselectedShiftButton] = useState(null);
  // console.log("selectedShiftButton: ", selectedShiftButton);
  const [selectedClosureButton, setselectedClosureButton] = useState(null);
  console.log("selectedClosureButton: ", selectedClosureButton);
  const [closureModalIsOpen, setclosureModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  // const [newName, setNewName] = useState("");

  // edit by jaspal
  const [showForm, setShowForm] = useState(false);
  const [newName, setNewName] = useState({
    ro_id: "",
    emp_id: "",
    sml_id: "",
    total_cost: "",
    monthly_rent: "",
    start_date: "",
    rent_due_date: "",
    expiry_date: "",
    status: "",
    // amount: "",
    DDNumber: "",
    date_of_DD: "",
    issuing_bank: "",
    paying_name: "",

    shift_date: "",
    shift_reason: "",
    
    closure_opening_date:"", 
    closure_date:"",
    closure_reason:""
  });
  const {
    ro_id,
    emp_id,
    sml_id,
    total_cost,
    DDNumber,
    date_of_DD,
    issuing_bank,
    paying_name,
    shift_date,
    shift_reason,

    
    closure_opening_date, 
    closure_date,
    closure_reason,
  } = newName;
  console.log("newName: ", newName);
  const [loading, setLoading] = useState(true);
  const [start_date, setstart_date] = useState("");
  // console.log("start_date: ", start_date);
  const [rent_due_date, setrent_due_date] = useState("");
  const [expiry_date, setexpiry_date] = useState("");

  const [monthly_rent, setmonthly_rent] = useState("");
  const [amount, setamount] = useState("");
  // console.log("amount: ", amount);

  const handleDateChange = (e) => {
    setstart_date(e.target.value);
  };

  useEffect(() => {
    if (start_date) {
      const date = new Date(start_date);

      // Set the rent due date (3 months later)
      const rentDueDate = new Date(date);
      rentDueDate.setMonth(rentDueDate.getMonth() + 3);
      const rentDueYear = rentDueDate.getFullYear();
      const rentDueMonth = String(rentDueDate.getMonth() + 1).padStart(2, "0");
      const rentDueDay = String(rentDueDate.getDate()).padStart(2, "0");
      setrent_due_date(`${rentDueYear}-${rentDueMonth}-${rentDueDay}`);

      // Set the expiry date (3 years later)
      const expiryDate = new Date(date);
      expiryDate.setFullYear(expiryDate.getFullYear() + 3);
      const expiryYear = expiryDate.getFullYear();
      const expiryMonth = String(expiryDate.getMonth() + 1).padStart(2, "0");
      const expiryDay = String(expiryDate.getDate()).padStart(2, "0");
      setexpiry_date(`${expiryYear}-${expiryMonth}-${expiryDay}`);
    }
  }, [start_date]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + RO_BOOKING + LIST, {
        headers,
      });
      const list = response.data.data;
      console.log("RO BOOKING LIST=", list);
      setLoading(false);
      setList(list);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + DEPARTMENT + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: Name,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + RO_BOOKING + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        ro_id: ro_id,
        emp_id: emp_id,
        sml_id: sml_id,
        total_cost: total_cost,
        monthly_rent: monthly_rent,
        start_date: start_date,
        rent_due_date: rent_due_date,
        expiry_date: expiry_date,
        amount: amount,
        DDNumber: DDNumber,
        date_of_DD: date_of_DD,
        issuing_bank: issuing_bank,
        paying_name: paying_name,
        omc_id: omcId,
        omc_type: omcType,
        omc_rate: rate,
      };

      // console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      console.log("create new Sml Booking", response);

      const { message } = response.data;

      if (response.data.message == "ro booking inserted successfully") {
        toast.success(message);

        setShowForm(false);
      }
      // const data = response.data.message;
      // console.log("data: ", data);
      // console.log(response.data.data);

      fetchList();
    } catch (error) {
      toast.error(error);

      console.error("Error connecting to API", error);
    }
  };

  //---------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + DEPARTMENT + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  const shiftUpdate = async (id) => {
    console.log("new id: ", id);

    try {
      const shiftUpdateURL = BASE_URL + RO_BOOKING + "/shift/" + id;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const requestBody = {
        ro_id: ro_id,
        shift_date: shift_date,
        shift_reason: shift_reason,
      };

      console.log("requestBody of shift update: ", requestBody);
      const response = await axios.post(shiftUpdateURL, requestBody, {
        headers,
      });
      console.log("update shift ", response);

      const { message } = response.data;

      if (message == "sml booking shift successfully updated") {
        toast.success(message);
        closeShiftModal();
      }
      fetchList();
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const closureUpdate = async (id) => {
    console.log("new id: ", id);

    try {
      const closureUpdateURL = BASE_URL + RO_BOOKING + "/closure/" + id;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const requestBody = {
        closure_opening_date:closure_opening_date, 
        closure_date:closure_date,
        closure_reason:closure_reason,
      };

      console.log("requestBody of closure update: ", requestBody);
      const response = await axios.post(closureUpdateURL, requestBody, {
        headers,
      });
      console.log("update closure ", response);

      const { message } = response.data;

      if (message == "sml booking closure successfully updated") {
        toast.success(message);
        closeClosureModal();
      }
      fetchList();
    } catch (error) {
      console.log("error: ", error);
    }
  };
  // ------------------------end---------------------------------
  const openShiftModal = (data) => {
    setomcId(data.omc_id);
    setselectedShiftButton(data);
    setshiftModalIsOpen(true);
    // setName(data.name);
    // setEditModalIsOpen(true);
  };
  const closeShiftModal = () => {
    setselectedShiftButton(null);
    setshiftModalIsOpen(false);
    // setEditModalIsOpen(false);
  };
  const openClosureModal = (data) => {
    // setselectedShiftButton(data);
    setselectedClosureButton(data);
    setclosureModalIsOpen(true);
    // setName(data.name);
    // setEditModalIsOpen(true);
  };
  const closeClosureModal = () => {
    setselectedClosureButton(null);

    setclosureModalIsOpen(false);

    // setselectedShiftButton(null);
    // setshiftModalIsOpen(false);
    // setEditModalIsOpen(false);
  };
  const openEditModal = (data) => {
    setSelectedRow(data);
    setName(data.name);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setSelectedRow(null);
    setEditModalIsOpen(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  // const handleNewNameChange = (e) => {
  //   setNewName(e.target.value);
  // };
  const handleNewNameChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };

  const handleCreate = () => {
    createNew();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };

  const [employeIdList, setemployeIdList] = useState([]);
  const [smlIdList, setsmlIdList] = useState([]);
  const [roIdList, setroIdList] = useState([]);
  const [omcList, setomcList] = useState([]);
  const [omcTypeList, setomcTypeList] = useState([]);
  // const [omcIdList, setomcIdList] = useState([]);
  // console.log("omcTypeList: ", omcTypeList);

  const [omcId, setomcId] = useState("");
  // console.log("omcId: ", omcId);
  const [omcType, setomcType] = useState("");
  const [rate, setrate] = useState("");
  // console.log("omcId: ", omcId);
  // console.log("omcType: ", omcType);
  // console.log("rate: ", rate);

  // console.log("omcIdList: ", omcIdList);

  const employeeURL = BASE_URL + EMPLOYEE + LIST;
  const smlURL = BASE_URL + SML + LIST;
  const omcURL = BASE_URL + OMC + LIST;
  // const roURL = BASE_URL + RETAIL_OUTLET + LIST;
  // const robyomcURL = BASE_URL + RO_BY_OMC ;

  // const [fetchedROIdList] = UseFetch(robyomcURL);
  const [fetchedEmployeeIdList] = UseFetch(employeeURL);
  const [fetchedSmlIdList] = UseFetch(smlURL);
  const [fetchedOmcIdList] = UseFetch(omcURL);
  // console.log("fetchedROIdList: ", fetchedROIdList);
  // console.log("fetchedSmlIdList: ", fetchedSmlIdList);
  // console.log("fetchEdemployeeList: ", fetchedEmployeeIdList);
  useEffect(() => {
    if (fetchedEmployeeIdList) {
      setemployeIdList(fetchedEmployeeIdList);
    }
  }, [fetchedEmployeeIdList]);
  useEffect(() => {
    if (fetchedSmlIdList) {
      setsmlIdList(fetchedSmlIdList);
    }
  }, [fetchedSmlIdList]);
  // useEffect(() => {
  //   if (fetchedROIdList) {
  //     setroIdList(fetchedROIdList);
  //   }
  // }, [fetchedROIdList]);

  useEffect(() => {
    if (fetchedOmcIdList) {
      setomcList(fetchedOmcIdList);
    }
  }, [fetchedOmcIdList]);

  useEffect(() => {
    if (omcId) {
      // const stateURL = BASE_URL + STATE + STATEDATA + omcId;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const getList = async () => {
        try {
          const omcTypeURL = BASE_URL + OMC + OMCTYPE + omcId;

          const data = await axios.get(omcTypeURL, { headers });
          console.log("omc data LIST: ", data.data.data);
          // setstateList(data.data.data);
          setomcTypeList(data.data.data);
          // setrate(data.data.data.rate)
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getList();

      const getrombyomcList = async () => {
        try {
          const robyomcURL = BASE_URL + RO_BY_OMC + omcId;

          const data = await axios.get(robyomcURL, { headers });
          console.log("ro data LIST: ", data.data.data);
          // setstateList(data.data.data);
          setroIdList(data.data.data);
          // setrate(data.data.data.rate)
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getrombyomcList();
    }
  }, [omcId]);

  const handleMonthlyRent = (e) => {
    const value = e.target.value;
    setmonthly_rent(value);
    setamount(value * 3);
  };
  const handleomcTypeChange = (e) => {
    const oT = e.target.value;
    setomcType(e.target.value);
    const omcrate = omcTypeList.find((a) => a._id === oT);
    setrate(omcrate.rate);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const rowData = list.map((row) => ({
      Retail_Name: row.retailName,
      SML_Name: row.smlName,
      Total_Cost: row.total_cost,
      Monthly_Rent: row.monthly_rent,
      Date_Added: row.date_added,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      Retail_Name: row.original.retailName,
      SML_Name: row.original.smlName,
      Total_Cost: row.original.total_cost,
      Monthly_Rent: row.original.monthly_rent,
      Date_Added: row.original.date_added,
    }));
    // const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("retailName", {
      header: "RO Name",
      size: 30,
    }),
    columnHelper.accessor("smlName", {
      header: "SML Name",
      size: 30,
    }),
    columnHelper.accessor("total_cost", {
      header: "Total Cost",
      size: 30,
    }),
    columnHelper.accessor("monthly_rent", {
      header: "Monthly Rent",
      size: 30,
    }),
    columnHelper.accessor("date_added", {
      header: "Date",
      size: 30,
      Cell: (cell) => cell.row.original.date_added.split("T")[0],
    }),

    // columnHelper.accessor("Designation", {
    //   header: "Designation",
    //   size: 30,
    // }),
    // columnHelper.accessor("TaggedClient", {
    //   header: "Tagged Client",
    //   size: 60,
    //   Cell: (cell) => (
    //     <IconButton onClick={() => openViewModal(cell.row.original)}>
    //       <RemoveRedEye color="error" />
    //     </IconButton>
    //   ),
    // }),
  ];
  const [employeeList, setEmployeeList] = useState([]);

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: false,
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          onClick={() => openShiftModal(row.original)}
          // onClick={() => openEditModal(row.original)}
        >
          Shift
        </Button>
        <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          onClick={() => openClosureModal(row.original)}

          // onClick={(e) => handleDelete(row._id)}
          // onClick={() => deleteRow(row.original)}
        >
          Closure
        </Button>
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* {showSuccessMessage.length > 0 ? (
        <Alert variant="outlined" severity="success">
          {showSuccessMessage}
        </Alert>
      ) : (
        <></>
      )} */}

      <div className="row">
        <div className="col-sm-6">
          {/* ADD RO Booking button */}
          <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            // onClick={openCreateModal}import Omc from './omc';

            // edit by jaspal
            onClick={() => setShowForm(!showForm)}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            {showForm ? "SML Booking List" : "Add SML Booking"}
          </Button>
        </div>
        {/* RO Booking Form */}
        {showForm && (
          <Container>
            <Card>
              <form>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="omc_id">OMC</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="omc_id"
                        name="omc_id"
                        value={omcId}
                        // onChange={handleNewNameChange}
                        onChange={(e) => setomcId(e.target.value)}
                      >
                        {omcList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="omcType">Type</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        id="omcType"
                        name="omcType"
                        value={omcType}
                        // onChange={(e) => {setomcType(e.target.value)}}
                        onChange={handleomcTypeChange}
                        disabled={!omcId}
                      >
                        {omcTypeList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            {option.area}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="rate">Rate</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        // select
                        id="rate"
                        name="rate"
                        value={rate}
                        onChange={(e) => setrate(e.target.value)}
                        disabled
                      ></TextField>
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="ro_id"> RO</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="ro_id"
                        name="ro_id"
                        value={newName.ro_id}
                        onChange={handleNewNameChange}
                        disabled={!omcId}
                      >
                        {roIdList.map((e) => (
                          <MenuItem value={e._id}>
                            <em>{e.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="emp_id"> Employee</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="emp_id"
                        name="emp_id"
                        value={newName.emp_id}
                        onChange={handleNewNameChange}
                      >
                        {employeIdList.map((e) => (
                          <MenuItem value={e._id}>
                            <em>{e.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="sml_id"> SML</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="sml_id"
                        name="sml_id"
                        value={newName.sml_id}
                        onChange={handleNewNameChange}
                      >
                        {smlIdList.map((e) => (
                          <MenuItem value={e._id}>
                            <em>{e.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="total_cost"> Total Cost</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        id="total_cost"
                        name="total_cost"
                        value={newName.total_cost}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="monthly_rent">
                        {" "}
                        Monthly Rent
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        id="monthly_rent"
                        name="monthly_rent"
                        value={monthly_rent}
                        // onChange={(e) => setmonthly_rent(e.target.value)}

                        onChange={handleMonthlyRent}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="start_date">
                        Commencement Date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={start_date}
                        onChange={handleDateChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="rent_due_date">
                        Rent Due Date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="rent_due_date"
                        name="rent_due_date"
                        value={rent_due_date}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="expiry_date">Expiry Date</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="expiry_date"
                        name="expiry_date"
                        value={expiry_date}
                        disabled
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="start_date">
                        Commencement Date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={start_date}
                        // onChange={(e) => setstart_date(e.target.value)}
                        onChange={handleDateChange}

                        // onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="rent_due_date">
                        Rent Due Date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="rent_due_date"
                        name="rent_due_date"
                        value={rent_due_date}
                        onChange={(e) => setrent_due_date(e.target.value)}
disabled
                        // onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="expiry_date">Expiry Date</InputLabel>
                      <TextField
                        fullWidth
                        type="date"
                        size="small"
                        id="expiry_date"
                        name="expiry_date"
                        value={expiry_date}
                        onChange={(e) => setexpiry_date(e.target.value)}
                        disabled

                        // onChange={handleNewNameChange}
                      ></TextField>
                    </Grid> */}
                  </Grid>
                </CardContent>
                <CardContent>
                  <div>
                    <h4>Security Deposit</h4>{" "}
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="amount">Amount</InputLabel>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        id="amount"
                        name="amount"
                        value={amount}
                        onChange={(e) => setamount(e.target.value)}
                        disabled={true}
                        // onChange={handleNewNameChange}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="DDNumber">DD Number</InputLabel>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        id="DDNumber"
                        name="DDNumber"
                        value={newName.DDNumber}
                        onChange={handleNewNameChange}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="date_of_DD">Date of DD</InputLabel>
                      <TextField
                        fullWidth
                        type="date"
                        size="small"
                        id="date_of_DD"
                        name="date_of_DD"
                        value={newName.date_of_DD}
                        onChange={handleNewNameChange}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="paying_name">Paying Name</InputLabel>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        id="paying_name"
                        name="paying_name"
                        value={newName.paying_name}
                        onChange={handleNewNameChange}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="issuing_bank">
                        Issuing Bank
                      </InputLabel>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        id="issuing_bank"
                        name="issuing_bank"
                        value={newName.issuing_bank}
                        onChange={handleNewNameChange}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <div className="div m-3">
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-secondary mt-2"
                      // onClick={closeCreateModal}
                      onClick={() => setShowForm(!showForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-primary mt-2"
                      style={{ float: "right" }}
                      onClick={handleCreate}
                    >
                      Create
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          </Container>
        )}

        {/* {[
                      "total_cost",
                      "monthly_rent",
                      "start_date",
                      "rent_due_date",
                    ].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          type="text"
                          id={field}
                          name={field}
                          value={newName[field]}
                          onChange={handleNewNameChange}
                        />
                      </Grid>
                    ))} */}
        {/* for select fields */}
        {/* {["ro_id", "emp_id", "sml_id"].map((field, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <InputLabel key={index} htmlFor={field}>
                          {transformString(field)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          id={field}
                          name={field}
                          value={newName[field]}
                          onChange={handleNewNameChange}
                        >
                          <MenuItem value="">
                            <em>Select {field}</em>
                          </MenuItem>
                        </TextField>
                      </Grid>
                      ))} */}
        {/* End RO Booking Form */}
        {/* new table */}
        {!showForm && (
          <Row>
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
          </Row>
        )}

        {/* Employee List */}
        {!showForm && (
          <div className="col-md-12">
            {/* <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      <TableCell align="center">RO Name</TableCell>
                      <TableCell align="center">SML Name</TableCell>
                      <TableCell align="center">Total Cost</TableCell>
                      <TableCell align="center">Monthly Rent</TableCell>
                      <TableCell align="center">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      ><TableCell align="center">{row.retailName}</TableCell>
                      <TableCell align="center">{row.smlName}</TableCell>
                      <TableCell align="center">{row.total_cost}</TableCell>
                      <TableCell align="center">{row.monthly_rent}</TableCell>
                      <TableCell align="center">{(row.date_added).split('T')[0]}</TableCell>


                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper> */}

            {/* =========================================================================================== */}
          </div>
        )}
        {/*End Employee List */}
      </div>
      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Department"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Department</h3>
          <form>
            <input type="hidden" name="name" value={selectedRow._id} readOnly />

            <div className="form-group p-2">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}

      {/* new shift modal  */}
      {selectedShiftButton && (
        <Modal
          isOpen={shiftModalIsOpen}
          onRequestClose={closeShiftModal}
          contentLabel="Shift"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Shift</h3>
          <form>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel htmlFor="ro_id"> RO</InputLabel>
              <TextField
                fullWidth
                select
                size="small"
                id="ro_id"
                name="ro_id"
                value={newName.ro_id}
                onChange={handleNewNameChange}
              >
                {roIdList.map((e) => (
                  <MenuItem value={e._id}>
                    <em>{e.name}</em>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel htmlFor="shift_date">Shift Date</InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                id="shift_date"
                name="shift_date"
                value={newName.shift_date}
                onChange={handleNewNameChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <InputLabel htmlFor="shift_reason">Reason</InputLabel>
              <TextareaAutosize
                minRows={3}
                id="shift_reason"
                name="shift_reason"
                value={newName.shift_reason}
                onChange={handleNewNameChange}
                // onChange={(e) => setaddress(e.target.value)}

                style={{
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                }}
              />
            </Grid>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeShiftModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => shiftUpdate(selectedShiftButton._id)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {selectedClosureButton && (
        <Modal
          isOpen={closureModalIsOpen}
          onRequestClose={closeClosureModal}
          contentLabel="Shift"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Closure</h3>
          <form>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel htmlFor="closure_date">Closure Date</InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                id="closure_date"
                name="closure_date"
                value={newName.closure_date}
                onChange={handleNewNameChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel htmlFor="closure_opening_date">Opening Date</InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                id="closure_opening_date"
                name="closure_opening_date"
                value={newName.closure_opening_date}
                onChange={handleNewNameChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <InputLabel htmlFor="closure_reason">Reason</InputLabel>
              <TextareaAutosize
                minRows={3}
                id="closure_reason"
                name="closure_reason"
                value={newName.closure_reason}
                onChange={handleNewNameChange}
                // onChange={(e) => setaddress(e.target.value)}

                style={{
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                }}
              />
            </Grid>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeClosureModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              // onClick={() => handleSave(selectedRow)}
              onClick={() => closureUpdate(selectedClosureButton._id)}

            >
              Update
            </Button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default RoBooking;

// RO_BOOKING

// ro_booking/create
//             ro_id,
//             emp_id,
//             sml_id,
//             total_cost,
//             monthly_rent,
//             start_date,
//             rent_due_date,
//             status
