import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

const Inprogresstable = () => {
  const [metal_type, setMetalType] = useState("");
  const Token = sessionStorage.getItem("token");
  const [progressData, setprogressData] = useState();
  const [openProgressModel, setopenProgressModel] = useState(false);
  const [quantity, setquantity] = useState("");
  const [amount, setAmount] = useState("");
  const [editModalIsOpen, seteditModalIsOpen] = useState(false);
  const [purposalDataSpecific, setpurposalDataSpecific] = useState(null);

  useEffect(() => {
    GetInprogressClient();
  }, []);

  const GetInprogressClient = async () => {
    try {
      const url = "https://app.batuk.in/getProposal";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      // const employees = response.data.data;
      console.log("In Progress Api data", response.data.data.PendingProposals);
      setprogressData(response.data.data.PendingProposals);

      // console.log("employee")
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("data input search", query);
  };

  // useEffect(() => {
  //   editPurposal();
  // }, []);

  const editPurposal = async (row) => {
    setpurposalDataSpecific(row);

    setMetalType(row.metal_type);
    setquantity(row.quantity);
    setAmount(row.amount);
    seteditModalIsOpen(true);

    //  await handleSave(row);
  };

  const handleSave = async () => {
    if (purposalDataSpecific !== null) {
      try {
        const url = `https://app.batuk.in/proposalUpdate/${purposalDataSpecific._id}`;
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await axios.post(
          url,
          { metal_type: metal_type },
          { headers }
        );
        // const employees = response.data.data;
        console.log("In Progress update Api data", response);
        if (response.status === 200) {
          Swal.fire({
            title: "Thank You!",
            text: "Purposal updated Successfully!",
            icon: "success",
          });
          // setprogressData(response.data.data);

          response.data.data?.map((item) => {
            console.log("item", item);
            setMetalType(item.metal_type);
            setquantity(item.quantity);
            setAmount(item.amount);
          });
          GetInprogressClient();
          seteditModalIsOpen(false);
        }
      } catch (error) {
        console.error("Error connecting to API", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    } else {
      console.log("purposal data is none");
    }
  };

  const closeEditModal = () => {
    seteditModalIsOpen(false);
  };
  //   const handleSave = async (row) => {
  // console.log("row edit",row)
  //     try {
  //       const url = `https://app.batuk.in/proposalUpdate/${row._id}`;
  //       const headers = {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization: `Bearer ${Token}`,
  //       };
  //       const response = await axios.post(url,{ "metal_type":metal_type}, { headers });
  //       // const employees = response.data.data;
  //       console.log("In Progress update Api data", response);
  //       seteditModalIsOpen(false);

  //       // console.log("employee")
  //     } catch (error) {
  //       console.error("Error connecting to API", error);
  //     }

  //   };

  const deletePurposal = async (row) => {
    console.log("row", row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // =============================================================================

        try {
          const url = `https://app.batuk.in/proposalDelete/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In Progress update Api data", response);
          GetInprogressClient();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }

        // =============================================================================
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",

          customClass: {
            confirmButton: "my-custom-button-class",
          },
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error",
        });
      }
    });
  };
  return (
    <div className="conatiner-fluid  p-2">
      <div className="row">
        <div className="col-sm-12 client">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                  <TableRow>
                  <TableCell align="center">#</TableCell>

                    <TableCell align="center">Proposal Number</TableCell>
                    <TableCell align="center">Client</TableCell>
                    <TableCell align="center">Metal Type</TableCell>

                    <TableCell align="center">Status</TableCell>

                    <TableCell align="center">Amount</TableCell>

                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {progressData &&
                    progressData.map((row, index) => {
                      return (
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: "#ffff",
                          }}>
                          <TableCell align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {row.proposal_no}
                          </TableCell>
                          <TableCell align="center">
                            {row.client_name}
                          </TableCell>
                          <TableCell align="center">
                            {row.metal_type
}
                          </TableCell>
                          <TableCell align="center">
                            <span
                              style={{
                                backgroundColor: "rgb(74 42 125)",
                                padding: " 7px 14px",
                                borderRadius: "12px",
                                fontSize: "13px",
                                color: "white",
                              }}>
                              {row.status}
                            </span>
                          </TableCell>
                          <TableCell align="center">{"₹ "+row.amount}</TableCell>

                          <TableCell align="center">
                            {/* <Button
                              variant="contained"
                              onClick={() => editPurposal(row)}
                              sx={{ marginRight: "10px" }}>
                              Edit
                            </Button> */}
                            <Button
                              variant="contained"
                              onClick={() => deletePurposal(row)}>
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                                 

                  {console.log("progressData", progressData)}
                  {/* ================================================================ */}
                </TableBody>
              </Table>
            </TableContainer>
            {editModalIsOpen && (
                    <Modal
                      isOpen={editModalIsOpen}
                      onRequestClose={closeEditModal}
                      contentLabel="Edit Employee"
                      style={{
                        overlay: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        },
                        content: {
                          top: "50%",
                          left: "50%",
                          right: "auto",
                          bottom: "auto",
                          marginRight: "-50%",
                          transform: "translate(-50%, -50%)",
                          width: "50%",
                        },
                      }}>
                      <h3 className="text-center fw-bold form_header">
                        Edit Purposal
                      </h3>
                      <form>
                        <div className="form-group p-2">
                          <label>Metal Type</label>

                          <input
                            type="text"
                            name="name"
                            value={metal_type}
                            onChange={(e) => setMetalType(e.target.value)}
                          />
                        </div>
                        <div className="form-group p-2">
                          <label>Quantity</label>
                          <input
                            type="text"
                            name="Email"
                            value={quantity}
                            onChange={(e) => setquantity(e.target.value)}
                          />
                        </div>
                        <div className="form-group p-2">
                          <label>Amount</label>
                          <input
                            type="text"
                            name="designation"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>

                        <Button
                          variant="contained"
                          type="button"
                          className="btn btn-secondary mt-2"
                          style={{ float: "left" }}
                          onClick={closeEditModal}>
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          type="button"
                          className="btn btn-primary mt-2"
                          style={{ float: "right" }}
                          onClick={() => handleSave()}>
                          Update
                        </Button>
                      </form>
                    </Modal>
                  )}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={15}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default Inprogresstable;
