import React, { useState } from "react";
import { Card, CardBody, CardHeader, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import Button from "@mui/material/Button";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";


function ChangePassword() {
    const resettoken = useParams(); 
  const Token = sessionStorage.getItem("token");
  const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');


    const handleSubmit = async () => {
      

        console.log({
            "resettoken": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2M2YwYjRkNTEwNDYwMTNkYjEzNzkwMCIsImlhdCI6MTcxNjk4NTc4OH0.eDqwbyIn0Bl5qMtVu74Vhkp8Uk0ILvaaUTF5i4OM3LA',
            "newpassword": password
        })
      if (password == confirmPassword) {
        try {
            const response = await axios.post("https://app.batuk.in/resetPassword", {
              "resettoken":'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2M2YwYjRkNTEwNDYwMTNkYjEzNzkwMCIsImlhdCI6MTcxNjk4Njk0NH0.7HAc4lxeQ6x30bjbp5WAsUVvIVVuBqH03tnUXUVHR5k',
              "newpassword":password
            });
            
            console.log(response);
            if (response.status == 200) {
            
                Swal.fire({
                    title: "Thank You!",
                    text: "Password reset Successfully!",
                    icon: "success"
                  });
            
            
            }    // Optionally log the response
            // Redirect or handle successful registration here
            // navigate("/login"); // Assuming you're using react-router-dom
          } catch (error) {
            console.error("Error in reset passsword ", error);
            // Handle error (display message, etc.)
          }
      } else {
        Swal.fire("password mismatch!");
}
   
  };

  // Inside the Register component

  return (
    <div classNameName="container-fluid">
          <div classNameName="col-lg-4 col-md-4 mx-auto" style={{ marginTop: "10rem" }}>
          <Link to="/forgotpassword" style={{fontSize:'16px',marginBottom:"20px"}}><Button variant="contained">Go Back</Button></Link>

        <Card style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.2)",marginTop:"20px" }}>
          <CardHeader
            style={{
              backgroundColor: "#4a2a7d",
              display: "flex",
              color: "white",
              alignItems: "center",
            }}>
            <h6
              style={{ marginTop: "6px", fontSize: "15px", fontWeight: "400" }}>
              Forget Password
            </h6>
          </CardHeader>
          <CardBody>
            <form classNameName="mt-2">
              <div className="mb-3">
                <label
                  style={{ color: "#4a2a7d" }}
                  for="name"
                  className="form-label">
                  Password
                </label>
                <input
                  style={{
                    border: "2px solid #4a2a7d",
                    borderRadius: "8px",
                    fontSize: "15px",
                  }}
                  type="email"
                  placeholder="Enter email"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="name"
                />
              </div>
              <div className="mb-3">
                <label
                  style={{ color: "#4a2a7d" }}
                  for="name"
                  className="form-label">
                Confirm  Password
                </label>
                <input
                  style={{
                    border: "2px solid #4a2a7d",
                    borderRadius: "8px",
                    fontSize: "15px",
                  }}
                  type="email"
                  placeholder="Enter email"
                  value={confirmPassword}
                  onChange={(e) => setconfirmPassword(e.target.value)}
                  id="name"
                />
              </div>

              <div classNameName="mt-4">
                <Button
                  type="button"
                  onClick={handleSubmit}
                  handleSubmit
                  variant="contained"
                  classNameName="btn text-light"
                  style={{ backgroundColor: "#10A37F" }}>
                  Submit
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ChangePassword;
