import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  REGION,
  RETAIL_OUTLET,
  RETAIL_OUTLET_FEASIBILITY,
  STATE,
  CITY,
  TERRITORY,
  EMPLOYEE,
  SML,
  STATEDATA,
  CITYDATA,
  TERRITORYDATA,
  OMC,
  LISTSOTCTMRIRHQ,
  OMC_INVOICE,
  RO_BY_OMC,
  RO_BY_OMC_ALLSTATUSONE,
} from "../helpers/url_helper.js";
import {
  Container,
  TextField,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Box,
  TextareaAutosize,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { mkConfig, generateCsv, download } from "export-to-csv";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import UseFetch from "../components/UseFetch.js";
import { IconButton } from "rsuite";
Modal.setAppElement("#root"); // Required for accessibility

const OmcInvoice = () => {
  const token = sessionStorage.getItem("token");
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2NjZlZTgwNTRkMTZiMWI1YWE4MGQ1YSIsImlhdCI6MTcxODg4MDU3MywiZXhwIjoxNzE4OTY2OTczfQ.6NHZRmuAZ2KhGUFQkgQABCBRa85e6wkX2lLys8XyO50";

  const [list, setList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  // edit by jaspal
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [newName, setNewName] = useState({
    omc_id: "",
    ro_id: "",
    invoice_date: "",
    invoice_number: "",
    amount: "",
    payment_status: "",
  });
  console.log("newName: ", newName);

  const {
    omc_id,
    ro_id,
    invoice_date,
    invoice_number,
    amount,
    payment_status,
  } = newName;

  console.log(omc_id);
  const [showSuccessMessage, setshowSuccessMessage] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + OMC + OMC_INVOICE + LIST, {
        headers,
      });
      const list = response.data.data;
      console.log("OMC_Invoice =", list);
      setLoading(false);
      setList(list);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    console.log(id);
    try {
      //const url = BASE_URL + DEPARTMENT + UPDATE;
      const url = `${BASE_URL + OMC + OMC_INVOICE + UPDATE}/${id}`;
      // const url = BASE_URL + OMC + OMC_INVOICE + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        // id: id,
        omc_id: omc_id,
        ro_id: ro_id,
        payment_status: payment_status,
        invoice_date: invoice_date,
        invoice_number: invoice_number,
        amount: amount,
      };

      console.log("request body of update", requestBody);
      const response = await axios.put(url, requestBody, { headers });
      const data = response.data;
      if (response.data.message == "Omc invoice updated successfully") {
        toast.success(response.data.message);
        // setShowForm(false);
      }
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + OMC + OMC_INVOICE + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        omc_id: omc_id,
        ro_id: ro_id,
        payment_status: payment_status,
        invoice_date: invoice_date,
        invoice_number: invoice_number,
        amount: amount,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      console.log("create new RO response", response);
      if (response.data.message == "Omc invoice saved successfully") {
        toast.success(response.data.message);
        setShowForm(false);
      }
      const data = response.data.message;
      console.log("data: ", data);

      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  //---------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + OMC + OMC_INVOICE + DELETE}/${row._id}`;
          //  const url = BASE_URL + OMC + OMC_INVOICE + CREATE;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };
  // const feasibilityUpdate = (row) => {
  //   console.log(row);
  //   const swalWithBootstrapButtons = Swal.mixin({
  //     customClass: {
  //       confirmButton: "btn btn-success",
  //       cancelButton: "btn btn-danger",
  //     },
  //     buttonsStyling: false,
  //   });
  //   Swal.fire({
  //     title: "Are you sure this RO is feasible?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#4A2A7D",
  //     cancelButtonColor: "#d33",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const url = `${
  //           BASE_URL + RETAIL_OUTLET + RETAIL_OUTLET_FEASIBILITY
  //         }/${row._id}`;
  //         const headers = {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         };
  //         const requestBody = {
  //           feasibility_status: 1,
  //         };
  //         const response = await axios.post(url, requestBody, { headers });
  //         // const employees = response.data.data;
  //         console.log("In excuteds update Api data", response);
  //         swalWithBootstrapButtons.fire({
  //           title: "Updated!",
  //           text: "RO Feasibility is updated.",
  //           icon: "success",
  //           customClass: {
  //             confirmButton: "my-custom-button-class",
  //           },
  //         });
  //         fetchList();

  //         // console.log("employee")
  //       } catch (error) {
  //         console.error("Error connecting to API", error);
  //       }
  //     } else if (
  //       /* Read more about handling dismissals below */
  //       result.dismiss === Swal.DismissReason.cancel
  //     ) {
  //       swalWithBootstrapButtons.fire({
  //         title: "Cancelled",
  //         text: "Data is safe",
  //         icon: "error",
  //       });
  //     }
  //   });
  // };
  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    console.log(data);
    setSelectedRow(data);
    setNewName(data);
    //setName(data.name);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewNameChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };

  const handleCreate = () => {
    createNew();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };

  const [roIdList, setroIdList] = useState([]);

  const [omcList, setomcList] = useState([]);

  const omcURL = BASE_URL + OMC + LIST;
  const [fetchedomcList] = UseFetch(omcURL);

  useEffect(() => {
    if (fetchedomcList) {
      setomcList(fetchedomcList);
    }
  }, [fetchedomcList]);
  // by:chandu

  useEffect(() => {
    if (omc_id) {
      // const stateURL = BASE_URL + STATE + STATEDATA + omc_id;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const getrombyomcList = async () => {
        try {
          const robyomcURL = BASE_URL + RO_BY_OMC_ALLSTATUSONE + omc_id;

          const data = await axios.get(robyomcURL, { headers });
          console.log("ro data LIST: ", data.data.data);
          // setstateList(data.data.data);
          setroIdList(data.data.data);
          // setrate(data.data.data.rate)
        } catch (error) {
          console.log("error: ", error);
        }
      };
      getrombyomcList();
    }
  }, [omc_id]);

  const handleRegionChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const rowData = list.map((row) => ({
      Omc_Name: row.omc_name,
      Ro_Name: row.ro_name,
      Invoice_Number: row.invoice_number,
      Amount: row.amount,
      Payment_Status: row.payment_status,
      Invoice_Date: row.invoice_date,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      Omc_Name: row.original.omc_name,
      Ro_Name: row.original.ro_name,
      Invoice_Number: row.original.invoice_number,
      Amount: row.original.amount,
      Payment_Status: row.original.payment_status,
      Invoice_Date: row.original.invoice_date,
    }));
    //  const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("omc_name", {
      header: "OMC Name",
      size: 30,
    }),
    columnHelper.accessor("ro_name", {
      header: "RO",
      size: 30,
    }),
    columnHelper.accessor("invoice_number", {
      header: "Invoice no.",
      size: 30,
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      size: 30,
    }),
    columnHelper.accessor("payment_status", {
      header: "Status",
      size: 30,
    }),
    columnHelper.accessor("invoice_date", {
      header: "Date",
      size: 30,
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box style={{ display: "inline-flex" }}>
        <IconButton
          style={{
            border: "white",
            backgroundColor: "transparent",
          }}
          onClick={() => openEditModal(row.original)}
        >
          <EditIcon color="error" />
        </IconButton>
        <IconButton
          style={{
            border: "white",
            backgroundColor: "transparent",
          }}
          onClick={() => deleteRow(row.original)}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Box>,
    ],

    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className="row">
        <div className="col-sm-6">
          {/* ADD RO button */}
          <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            onClick={() => setShowForm(!showForm)}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            {showForm ? "OMC INVOICE List" : "Add OMC INVOICE"}
          </Button>
        </div>
        {/* RO Form */}
        <Container>
          {showForm && (
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="omc_id">OMC</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        type="number"
                        id="omc_id"
                        name="omc_id"
                        // value={newName.omc_id}
                        value={newName.omc_id}
                        // onChange={handleNewNameChange}
                        onChange={handleNewNameChange}
                      >
                        {omcList.map((option, idx) => (
                          <MenuItem key={idx} value={option._id}>
                            <em>{option.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="ro_id"> RO</InputLabel>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        id="ro_id"
                        name="ro_id"
                        value={newName.ro_id}
                        onChange={handleNewNameChange}
                      >
                        {roIdList.map((e) => (
                          <MenuItem key={e._id} value={e._id}>
                            <em>{e.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="invoice_date">
                        Invoice Date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        id="invoice_date"
                        name="invoice_date"
                        value={newName.invoice_date}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="invoice_number">
                        Invoice Number
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        id="invoice_number"
                        name="invoice_number"
                        value={newName.invoice_number}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="amount">Amount</InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        id="amount"
                        name="amount"
                        value={newName.amount}
                        onChange={handleNewNameChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputLabel htmlFor="payment_status">
                        Payment Status
                      </InputLabel>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        className="bor"
                        name="payment_status"
                        id="payment_status"
                        value={newName.payment_status}
                        onChange={handleNewNameChange}
                      >
                        <MenuItem value="Paid">
                          <em>Paid</em>
                        </MenuItem>
                        <MenuItem value="Unpaid">
                          <em>Unpaid</em>
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                  <div className="div m-3">
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-secondary mt-2"
                      // onClick={closeCreateModal}
                      onClick={() => setShowForm(!showForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-primary mt-2"
                      style={{ float: "right" }}
                      onClick={handleCreate}
                    >
                      Create
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          )}
        </Container>

        {/* new TABLE */}
        {!showForm && (
          <Row>
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
          </Row>
        )}

        {/* =========================================================================================== */}
        {/*End Employee List */}
      </div>
      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Department"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit OMC Invoice</h3>
          <form>
            <input type="hidden" name="name" value={selectedRow._id} readOnly />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel htmlFor="omc_id">OMC</InputLabel>
                <TextField
                  fullWidth
                  select
                  size="small"
                  type="number"
                  id="omc_id"
                  name="omc_id"
                  // value={newName.omc_id}
                  value={newName.omc_id}
                  // onChange={handleNewNameChange}
                  onChange={handleNewNameChange}
                >
                  {omcList.map((option, idx) => (
                    <MenuItem key={idx} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputLabel htmlFor="ro_id"> RO</InputLabel>
                <TextField
                  fullWidth
                  select
                  size="small"
                  id="ro_id"
                  name="ro_id"
                  value={newName.ro_id}
                  onChange={handleNewNameChange}
                >
                  {roIdList.map((e) => (
                    <MenuItem value={e._id}>
                      <em>{e.name}</em>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel htmlFor="invoice_date">Invoice Date</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  type="date"
                  id="invoice_date"
                  name="invoice_date"
                  value={newName.invoice_date}
                  onChange={handleNewNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel htmlFor="invoice_number">Invoice Number</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  id="invoice_number"
                  name="invoice_number"
                  value={newName.invoice_number}
                  onChange={handleNewNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  id="amount"
                  name="amount"
                  value={newName.amount}
                  onChange={handleNewNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel htmlFor="payment_status">Payment Status</InputLabel>
                <TextField
                  select
                  fullWidth
                  size="small"
                  className="bor"
                  name="payment_status"
                  id="payment_status"
                  value={newName.payment_status}
                  onChange={handleNewNameChange}
                >
                  <MenuItem value="Paid">
                    <em>Paid</em>
                  </MenuItem>
                  <MenuItem value="Unpaid">
                    <em>Unpaid</em>
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}
    </div>
  );
};

export default OmcInvoice;
