import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from "sweetalert2";
import {
  BASE_URL,
  OMC,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
  RETAIL_OUTLET,
  LOICREATE,
  LOISAVE,
  LOILIST,
  RETAIL_OUTLET_FEASIBILITY,
  LOI_SIDE_LETTER_UPLOAD,
} from "../helpers/url_helper.js";

import AddIcon from "@mui/icons-material/Add";

import Checkbox from "@mui/material/Checkbox";
import { Link, Outlet, NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Box,
  IconButton,
} from "@mui/material";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";

import { Col, Row } from "react-bootstrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UseFetch from "../components/UseFetch.js";

Modal.setAppElement("#root"); // Required for accessibility

const LOI = () => {
  const token = sessionStorage.getItem("token");

  const [loading, setLoading] = useState([]);
  const [list, setList] = useState([]);
  //console.log("list: ", list);
  const [pdfResponseURL, setpdfResponseURL] = useState("");
  const [pdfShowModal, setpdfShowModal] = useState(false);

  // console.log("list: ", list);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [nextLoiForm, setnextLoiForm] = useState(false);
  // const [newName, setNewName] = useState("");
  // const [File, setFile] = useState({});
  // console.log("File: ", File);

  const [newName, setNewName] = useState({
    name: "",
    email: "",
    designation: "",
  });
  const { name, email, designation } = newName;
  // console.log("newName: ", newName);

  // onchange data handle
  const handleNewNameChange = (e) => {
    const { name, value } = e.target;
    setNewName({
      ...newName,
      [name]: value,
    });
  };
  const [selectedArea, setselectedArea] = useState([]);
  // console.log("selectedAreachangw: ", selectedArea);
  const selectedAreaChangeHandler = (e) => {
    const { name, value } = e.target;
    setselectedArea((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const [RoSelectedRows, setRoSelectedRows] = useState([]);

  const [selectedAreas, setSelectedAreas] = useState(
    new Array(RoSelectedRows.length).fill("")
  );
  // console.log("selectedAreas: ", selectedAreas);

  const handleAreaChange = (index, value) => {
    // const ar=[]
    console.log(value);
    // ar.push(value)
    const newSelectedAreas = [...selectedAreas];
    newSelectedAreas[index] = value;
    setSelectedAreas(newSelectedAreas);
  };

  // selected rows data
  // console.log("RoSelectedRows: ", RoSelectedRows);

  const receivedSelectedData = (rows) => {
    const rowData = rows.map((row) => row.original);
    setRoSelectedRows(rowData);
    setnextLoiForm(true);
  };

  const getIdsFromSelectedRows = (RoSelectedRows) => {
    return RoSelectedRows.map((row) => row._id);
  };

  const roIds = getIdsFromSelectedRows(RoSelectedRows);
  // console.log("selected Ro IDs",roIds);

  // const requestBody = {
  //   name: name,
  //   email: email,
  //   designation: designation,
  //   ro_ids:roIds,
  //   area:selectedAreas
  // };

  // console.log("request body of create new employee", requestBody);
  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + LOILIST, { headers });
      const list = response.data.data;
      // console.log("LOI new  list =", list);

      setList(list);
      setLoading(false);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + OMC + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: Name,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // -------------------------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + LOICREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        to_name: name,
        to_email: email,
        to_designation: designation,
        ro_ids: roIds,
        area: selectedAreas,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      console.log("create new employye response", response);
      if (response.data.path) {
        const pdfLink = response.data.path;
        console.log("data: ", pdfLink);
        setpdfResponseURL(pdfLink);
        setpdfShowModal(true);
      }

      // fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  //-----------------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + OMC + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  const handleSendLoi = async () => {
    try {
      const url = BASE_URL + LOISAVE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        to_name: name,
        to_email: email,
        to_designation: designation,
        ro_ids: roIds,
        area: selectedAreas,
        loi_path: pdfResponseURL,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      console.log("create new employye response", response);

      if (response.data.message == "LOI sent successfully!") {
        toast.success("LOI sent successfully !");
        setpdfShowModal(false);
        setTimeout(() => {
          setShowForm(false);
          setnextLoiForm(false);
        }, 1000);
      }
      fetchList();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadLOI = (pdfPath) => {
    window.open(pdfPath, "_blank");

    // Create a hidden link element to trigger the download
    // const link = document.createElement('a');
    // link.href = pdfPath;
    // link.download = pdfPath.split('/').pop(); // Set the file name
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  // const handleDownloadLOI = (pdfPath) => {
  //   window.open(pdfPath, '_blank');

  // }

  const sideLetterReceived = (row) => {
    // console.log(row);
    const side_letter_received_status = 1;
    // this is Ro id
    const id = row.ro_id;
    // console.log("id: ", id);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Have you Received Side Letter ?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      html: `
    <label for="file-input" style="margin-left: 0px; width:8vw">Please Upload Side Letter</label>
    <input type="file" id="file-input" class="swal2-input" style="width: 12vw; height: 1.625em;">
    <br/>
    <label for="date-input">Side Letter Date</label>
    <input type="date" id="date-input" class="swal2-input" style="height: 1.625em;">
  `,

      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",

      preConfirm: () => {
        const file = document.getElementById("file-input").files[0];
        const date = document.getElementById("date-input").value;
        return { file, date };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { file, date } = result.value;
          // setFile(file)
          console.log("file: ", file);
          // console.log("date: ", date);

          const url = BASE_URL + LOI_SIDE_LETTER_UPLOAD;
          const headers = {
            // "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          // const requestBody = {
          //   id:id,
          //   date:date,
          //   file:File,
          // };
          const formData = new FormData();
          formData.append("id", id);
          formData.append("date", date);
          formData.append("file", file);
          formData.append(
            "side_letter_received_status",
            side_letter_received_status
          );
          console.log("formData: ", formData);
          // console.log("requestBody: ", requestBody);
          const response = await axios.post(url, formData, { headers });
          console.log("response link: ", response.data.path);

          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Updated!",
            text: "Side letter uploaded.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };
  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    setSelectedRow(data);
    setName(data.name);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  // const handleNewNameChange = (e) => {
  //   setNewName(e.target.value);
  // };

  const handleCreate = () => {
    createNew();
    closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  // const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [selectedRows, setSelectedRows] = useState([]);
  // console.log("selectedRows: ", selectedRows);

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.some(
      (selectedRow) => selectedRow.name === row.name
    );
    if (isSelected) {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.name !== row.name)
      );
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const transformString = (input) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(" ");
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const rowData = list.map((row) => ({
      RO_Name: row.ro_name,
      Omc_Name: row.omc_name,
      Date_Added: row.date_added,
      Feasibility_Status: row.feasibility_status,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    console.log("rows: ", rows);
    const rowData = rows.map((row) => ({
      RO_Name: row.original.ro_name,
      Omc_Name: row.original.omc_name,
      Date_Added: row.original.date_added,
      Feasibility_Status: row.original.feasibility_status,
    }));
    //const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("ro_name", {
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("omc_name", {
      header: "OMC Name",
      size: 30,
    }),
    columnHelper.accessor("date_added", {
      header: "Date",
      size: 30,
      Cell: (cell) => cell.row.original.date_added.split("T")[0],
    }),
    columnHelper.accessor("feasibility_status", {
      header: "Side Letter Received ?",
      size: 30,
      Cell: (cell) =>
        // cell.row.original.feasibility_status == 0 ? (<>
        cell.row.original.ro_side_letter_status == 1 ? (
          "Yes"
        ) : (
          <Button
            style={{ marginRight: "10px" }}
            variant="contained"
            onClick={() => sideLetterReceived(cell.row.original, 1)}
          >
            Yes
          </Button>
        ),
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          onClick={(e) => handleDownloadLOI(row.original.loi_path)}
          // onClick={() => deleteRow(row.original)}
        >
          Download LOI
        </Button>
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const [roList, setroList] = useState([]);
  // console.log("roList: ", roList);
  const roURL = BASE_URL + RETAIL_OUTLET + LIST;
  // const [fetchedroList] = UseFetch(roURL);
  const [fetchedroList] = UseFetch(
    "https://development.batukit.com/retail_outlet/feasibleRo"
  );

  useEffect(() => {
    if (fetchedroList) {
      setroList(fetchedroList);
    }
  }, [fetchedroList]);

  const ROcolumnHelper = createMRTColumnHelper();

  const RoColumns = [
    ROcolumnHelper.accessor("name", {
      header: "RO Name",
      size: 30,
    }),
    ROcolumnHelper.accessor("cityName", {
      header: "City",
      size: 30,
    }),
    ROcolumnHelper.accessor("outlet_code", {
      header: "Outlet Code",
      size: 30,
    }),
    ROcolumnHelper.accessor("omcName", {
      header: "OMC Name",
      size: 30,
    }),
    ROcolumnHelper.accessor("address", {
      header: "Address",
      size: 30,
    }),
  ];

  const RoTable = useMaterialReactTable({
    columns: RoColumns,
    data: roList,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    // enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [<Box></Box>],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "18px",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="contained"
          sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => receivedSelectedData(table.getSelectedRowModel().rows)}
          // startIcon={<FileDownloadIcon />}
        >
          Send LOI
        </Button>
      </Box>
    ),
  });

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="row">
        {/*  Button */}
        {!nextLoiForm && (
          <div className="col-sm-6">
            <Button
              variant="contained"
              sx={{ marginBottom: "20px", textTransform: "capitalize" }}
              endIcon={<AddIcon sx={{ color: "white !important" }} />}
              onClick={() => setShowForm(!showForm)}
            >
              {showForm ? "LOI List" : "Create LOI"}
            </Button>
          </div>
        )}

        {/* new table */}
        {!showForm && (
          <Row>
            <Col xs={12} className="index">
              {/* Conditional rendering of loader */}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  {/* <CircularProgress color="success"/> */}
                  <LinearProgress />
                </Box>
              ) : (
                <MaterialReactTable table={table} />
              )}
            </Col>
          </Row>
        )}
        {/* it will show when i click on create loi */}

        {showForm && !nextLoiForm && (
          <Row>
            <Col xs={12}>
              <MaterialReactTable table={RoTable} />
            </Col>
          </Row>
        )}

        {/* it will show when i click on create loi */}
        {/* Ro list  */}
        {showForm && !nextLoiForm && (
          <div className="col-md-12">
            {/* <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      {["select", "RO name", "City", "Address"].map(
                        (field, index) => (
                          <TableCell align="center">
                            {" "}
                            {transformString(field)}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: "#ffff",
                          }}
                        >
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              checked={selectedRows.some(
                                (selectedRow) => selectedRow.name === row.name
                              )}
                              onChange={() => handleCheckboxChange(row)}
                            />
                          </TableCell>

                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper> */}
            {/* Next Button  */}
            {/* <div>
              {selectedRows.length > 0 ? (
                <Button
                  style={{ float: "right" }}
                  variant="contained"
                  onClick={() => setnextLoiForm(!nextLoiForm)}
                >
                  Next
                </Button>
              ) : (
                ""
              )}
            </div> */}
            {/* <Link to="/loi/selectedloi">
               </Link> */}
            {/* <Outlet></Outlet> */}
          </div>
        )}

        {/* nextLoiForm show */}

        {nextLoiForm && (
          <div className="col-md-12">
            <Paper>
              <form style={{ margin: "0px 20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="name">To Name</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      id="name"
                      name="name"
                      value={newName.name}
                      onChange={handleNewNameChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="email">To Email</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      id="email"
                      name="email"
                      value={newName.email}
                      onChange={handleNewNameChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel htmlFor="designation">
                      To Designation
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      id="designation"
                      name="designation"
                      value={newName.designation}
                      onChange={handleNewNameChange}
                    />
                  </Grid>
                </Grid>
              </form>

              <TableContainer style={{ marginTop: "15px" }}>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      {["RO name", "City", "Address", "Area sq ft"].map(
                        (field, index) => (
                          <TableCell align="center" key={index}>
                            {" "}
                            {transformString(field)}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {RoSelectedRows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.cityName}</TableCell>
                        <TableCell align="center">{row.address}</TableCell>
                        <TableCell align="center">
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth
                              size="small"
                              required
                              select
                              id="area"
                              name="area"
                              // value=""
                              // onChange={selectedAreaChangeHandler}

                              value={selectedAreas[index] || ""}
                              onChange={(e) =>
                                handleAreaChange(index, e.target.value)
                              }
                            >
                              {["30", "40", "50", "60"].map((option) => (
                                <MenuItem key={option} value={option}>
                                  <em>{option}</em>
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={RoSelectedRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <div>
              <Button
                style={{ float: "left" }}
                variant="contained"
                onClick={() => {
                  setShowForm(showForm);
                  setnextLoiForm(!nextLoiForm);
                }}
              >
                Back
              </Button>
              <Button
                style={{ float: "right" }}
                variant="contained"
                // onClick={() => setnextLoiForm(!nextLoiForm)}
                onClick={() => createNew()}
              >
                Preview
              </Button>
            </div>
          </div>
        )}

        {/* {!showForm && (
          <div className="col-md-12">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}
                  >
                    <TableRow>
                      <TableCell align="center">RO Name</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>

                        <TableCell align="center">
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={() => openEditModal(row)}
                          >
                            Download LOI
                          </Button>

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        )} */}

        {/* pdf show modal  */}

        {/* Edit Region Modal */}
        {pdfShowModal && (
          <Modal
            isOpen={pdfShowModal}
            // onRequestClose={closeEditModal}
            contentLabel="Preview LOI"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                // top: "30%",
                // left: "50%",
                // right: "auto",
                // bottom: "10%",
                // marginRight: "-50%",
                // transform: "translate(-50%, -50%)",
                // width: "50%",
                position: "absolute",
                top: "50%",
                left: "55%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                height: "75%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              },
            }}
          >
            <iframe
              src={pdfResponseURL + `#toolbar=0&navpanes=0&scrollbar=0`}
              title="PDF Viewer"
              width="100%"
              height="95%"
              style={{ border: "none" }}
            ></iframe>
            <div>
              <Button
                style={{ float: "left" }}
                variant="contained"
                onClick={() => setpdfShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                style={{ float: "right" }}
                variant="contained"
                // onClick={() => setnextLoiForm(!nextLoiForm)}
                onClick={() => handleSendLoi()}
              >
                Send
              </Button>
            </div>
          </Modal>
        )}
        {/* End Edit Region Modal */}
      </div>
    </div>
  );
};

export default LOI;
