import React, { useEffect, useState } from "react";
import axios from "axios";
import GoldLogo from "../images/agmond_gold_logo01.png";

import SilverLogo from "../images/agmond_gold_logo02.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const FinalCreate = () => {
  const clientData = useSelector((state) => state);
  console.log("proposal", clientData);
  const Token = sessionStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  let client = location.state?.client;
  console.log("data of client in final create", client);

  const [selectedMetal, setSelectedMetal] = useState("gold"); // default selected metal is gold
  const [goldPrice, setGoldPrice] = useState("");
  const [silverPrice, setSilverPrice] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [amountInputEmpty, setAmountInputEmpty] = useState(true);
  const [weightInputEmpty, setWeightInputEmpty] = useState(true);
  const [clientId, setclientId] = useState(client._id);
  useEffect(() => {
    getLivePrices();
  }, []);

  const getLivePrices = async () => {
    await getGoldLive();
    await getSilverLive();
  };

  const getGoldLive = async () => {
    try {
      const url = "https://app.batuk.in/liveprice/gold";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      console.log("gold ptice api", response);
      const liveValue = response.data.data;
      setGoldPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const getSilverLive = async () => {
    try {
      const url = "https://app.batuk.in/liveprice/silver";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });

      const liveValue = response.data.data;
      setSilverPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleInputChange = (inputId) => {
    const num1 = parseFloat(document.getElementById("num1").value);
    const num2 = parseFloat(document.getElementById("num2").value);
    const num3 = parseFloat(document.getElementById("num3").value);

    if (inputId === "num1") {
      const result = isNaN(num1) || isNaN(num2) ? "" : num2 / num1;
      document.getElementById("num3").value = result;
    }
    if (inputId === "num3") {
      const amount = isNaN(num3) || isNaN(num1) ? "" : num3 * num1;
      var amountFormatted = amount === "" ? "" : amount.toFixed(2);
      document.getElementById("num2").value = amountFormatted;
    } else if (inputId === "num2") {
      setAmountValue(num2);
      var weight =
        isNaN(num2) || isNaN(num1) || num1 === 0 || num2 === ""
          ? ""
          : num2 / num1;
      var weightFormatted = weight === "" ? "" : weight.toFixed(4);
      document.getElementById("num3").value = weightFormatted;
    }
    if (inputId === "num2" || inputId === "num3") {
      const inputValue = document.getElementById(inputId).value;
      const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
      const parts = sanitizedValue.split(".");
      document.getElementById(inputId).value = parts.join(".");
    }

    if (inputId === "num2") {
      setAmountInputEmpty(num2 === "");
    } else if (inputId === "num3") {
      setWeightInputEmpty(num3 === "");
    }
  };

  // const createPurposal = async () => {
  //   console.log(selectedMetal);
  //   const weight = document.getElementById("num3").value;
  //   console.log(`https://app.batuk.in/proposal_create/${clientId}`);
  //   try {
  //     const url = `https://app.batuk.in/proposal_create/${clientId}`;
  //     const headers = {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${Token}`,
  //     };
  //     const body = {
  //       metal_type: selectedMetal,
  //       quantity: weight,
  //       amount: amountValue,
  //     };
  //     console.log("aftetr url 1");

  //     const response = await axios.post(
  //       url,
  //       {
  //         metal_type: goldPrice ? goldPrice : silverPrice,
  //         quantity: weight,
  //         amount: amountValue,
  //       },
  //       { headers }
  //     );
  //     console.log("aftetr url");

  //     console.log("body", body);
  //     console.log("response of create purposal", response);
  //   } catch (error) {
  //     console.error("Error creating purposal to API", error);
  //   }
  // };

  const createPurposal3 = async () => {
    const weight = document.getElementById("num3").value;
    let data = JSON.stringify({
      metal_type: selectedMetal,
      amount: amountValue,
      quantity: weight,
    });
    console.log({
      _id: clientId,
      _id: Token,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://app.batuk.in/proposal_create/${clientId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        return JSON.stringify(response.data);
      })
      .then((d) => {
        navigate();
        console.log(d.json());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createPurposal = async () => {
    const weight = document.getElementById("num3").value;
    let data = JSON.stringify({
      metal_type: selectedMetal,
      amount: amountValue,
      quantity: weight,
    });

    console.log({
      _id: clientId,
      _id: Token,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://app.batuk.in/proposal_create/${clientId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        return response.data;
      })
      .then((d) => {
        console.log(d);
        if (d.message == "The proposal was created successfully") {
          Swal.fire({
            title: "Thank You!",
            text: "The proposal was created successfully!",
            icon: "success",
          });
          navigate("/purposal");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: '<a href="#">Why do I have this issue?</a>',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid" style={{ color: "#880E4F" }}>
      <div className="row">
        <div
          className="col-md-7 card"
          style={{
            borderRadius: "10px",
            boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
            padding: "25px 34px",
          }}>
          <div className="row">
            <div className="col-md-6 mb-2">
              <lable className="form-label" style={{color: "#4a2a7d"}}>Client Name</lable>
              <input type="text" value={client.name} />
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <div className="div">
                {/* ========================================================== */}
                <div className="row">
                  <div className="col-sm-6">
                 
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group">
                    <FormControlLabel
                      value="gold"
                      control={<Radio />}
                      label="Gold"
                      name="live"
                      id="gold"
                      checked={selectedMetal === "gold"}
                      onChange={() => setSelectedMetal("gold")}
                    />
                   
                  </RadioGroup>
                
                  </div>
                  <div className="col-sm-6">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group">
                  <FormControlLabel
                      value="silver"
                      control={<Radio />}
                      label="Silver"
                      checked={selectedMetal === "silver"}
                      onChange={() => setSelectedMetal("silver")}
                      />
                       </RadioGroup>
                  </div>
                </div>
               

                {/* '===============================================' */}
              </div>
            </div>
          </div>

          {selectedMetal === "gold" && (
            <div
              className="row mt-2"
              style={{
                borderRadius: "10px",
                border: "2px solid #E3B041",
                color: "#4A2A7D",
                padding: "20px 15px",
              }}>
              <div className="row d-flex justify-content-between">
                <div
                  className="col-md-1 d-flex justify-content-start my-auto p-0"
                  style={{ height: "auto" }}>
                  <img
                    src={GoldLogo}
                    className="img-fluid p-0 mx-2"
                    style={{ height: "auto", width: "150px" }}
                  />
                </div>
                <div className="col-md-4 p-2 text-center">
                  <h5 className="fw-bold">AUGMONT Gold</h5>
                  <h6>24k 999.9 Purity</h6>
                </div>
                <div className="col-sm-2 col-2 d-flex align-items-center">
                  {/* <div className="col-sm-2 col-2  d-flex align-items-center justify-content-center"> */}
                  {/* &#8377;&nbsp; */}
                  {console.log("goldPrice", goldPrice)}
                  ₹
                  <input
                    type="text"
                    id="num1"
                    value={goldPrice}
                    onInput={() => handleInputChange("num1")}
                    className="live fw-bold"
                    // style={{
                    //   width: "100px",
                    //   border: "none",
                    //   background: "transparent",
                    //   outline: "none",
                    //   color: "#4a2a7d",
                    // }}
                    style={{
                      width: "80px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      color: "#4A2A7D",
                    }}
                    readOnly
                  />
                </div>
                <div className="col-md-2 mt-4 ">
                  <h6
                    className=""
                    style={{ color: "#4A2A7D", fontSize: "20px" }}>
                    <span className="blinking-circle"></span> &nbsp;Live
                  </h6>
                </div>
              </div>
            </div>
          )}

          {selectedMetal === "silver" && (
            <div
              className="row mt-2"
              style={{
                borderRadius: "10px",
                border: "2px solid #E3B041",
                color: "#4A2A7D",
                padding: "20px 15px",
              }}>
              <div className="row d-flex justify-content-around">
                <div
                  className="col-md-1 d-flex justify-content-start my-auto p-0"
                  style={{ height: "auto" }}>
                  <img
                    src={SilverLogo}
                    className="img-fluid p-0 mx-2"
                    style={{ height: "auto", width: "150px" }}
                  />
                </div>
                <div className="col-md-4  p-2 text-center">
                  <h5 className="fw-bold">AUGMONT Silver</h5>
                  <h6>24k 999.9 Purity</h6>
                </div>
                <div className="col-md-2 my-auto ">
                  &#8377;&nbsp;
                  <input
                    type="text"
                    id="num1"
                    value={silverPrice}
                    onInput={() => handleInputChange("num1")}
                    className="live fw-bold"
                    style={{
                      width: "80px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      color: "#4A2A7D",
                    }}
                    readOnly
                  />
                </div>
                <div className="col-md-2 mt-4">
                  <h6
                    className=""
                    style={{ color: "#4A2A7D", fontSize: "20px" }}>
                    <span className="blinking-circle"></span> &nbsp;Live
                  </h6>
                </div>
              </div>
            </div>
          )}

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-md-5 mt-2">
              <div className="col-md-4 mt-3">
                <h5 style={{ color: "#4A2A7D" }}>Amount</h5>
              </div>
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    &#8377;
                  </span>
                  <input
                    type="text"
                    className="form-control text-dark"
                    aria-describedby="basic-addon1"
                    id="num2"
                    onInput={() => handleInputChange("num2")}
                    style={{ width: "70%" }}
                  />
                </div>
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}></p>
              </div>
            </div>
            <div
              className="col-md-1 py-3 mt-5 mx-2"
              style={{ color: "#4A2A7D" }}>
              <i className="fa-solid fa-arrows-left-right fs-5"></i>
            </div>
            <div className="col-md-5 mt-2">
              <div className="col-md-4 mt-3">
                <h5 style={{ color: "#4A2A7D" }}>Weight</h5>
              </div>
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    gm
                  </span>
                  <input
                    type="text"
                    className="form-control text-dark"
                    aria-describedby="basic-addon1"
                    id="num3"
                    onInput={() => handleInputChange("num3")}
                    style={{ width: "70%" }}
                  />
                </div>
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}></p>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn p-2 mt-3 text-light"
              onClick={createPurposal}
              style={{
                minWidth: "300px",
                borderRadius: "20px",
                fontSize: "20px",
                backgroundColor: "#4A2A7D",
              }}>
              Create Proposal
            </button>
          </div>
          <div className="col-md-12 mt-3" style={{ color: "#4A2A7D" }}>
            <p className="text-center">Exclusive of 3% GST</p>
            <h6 className="text-center mt-1" style={{ color: "#4A2A7D" }}>
              Bharat Batuk Pvt Ltd (BBPL)
            </h6>
            <p className="text-center mt-2">
              Gold bought in last 7 days can not be sold.
              <br />
              BBPL offers gold products through our partners.
              <br />
              Refer to{" "}
              <Link target="blank" to="https://batuk.in/terms-conditions/">
                {" "}
                Terms & Conditions
              </Link>{" "}
              of Gold for details.
            </p>
          </div>
        </div>
        <div
          className="col-sm-4 py-3 ms-5"
          style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)" }}>
          <h4 className="text-center mb-4 top_Header">FAQ</h4>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              <div class="summarry">
                <h2>
                  <span>01.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header">
              <div class="summarry">
                <h2>
                  <span>02.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header">
              <div class="summarry">
                <h2>
                  <span>03.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header">
              <div class="summarry">
                <h2>
                  <span>04.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header">
              <div class="summarry">
                <h2>
                  <span>05.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header">
              <div class="summarry">
                <h2>
                  <span>06.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header">
              <div class="summarry">
                <h2>
                  <span>07.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className="description">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header">
              <div class="summarry">
                <h2>
                  <span>08.</span> Non consectetur a erat nam at lectus urna
                  duis?{" "}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FinalCreate;
