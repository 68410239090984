import axios from "axios";
import { useState, useEffect } from "react";

const UseFetch = (url) => {
  const token = sessionStorage.getItem("token");
  const [data, setData] = useState(null);
  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const list = response.data.data;
      console.log(`${url} data list`, list);
      setData(list);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    fetchList();
  }, [url]);

  return [data];
};

export default UseFetch;
