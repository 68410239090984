import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import "../assets/css/signup.css";
import Ci from "../assets/images/Curated Icons.png";
import Cd from "../assets/images/Completely digitalised.png";
import Bs from "../assets/images/Bank Grade Security.png";
import Hc from "../assets/images/Help Center.png";
import image1 from "../assets/images/Homepage 1.png";
import image2 from "../assets/images/Homepage 2 copy.png";
import image3 from "../assets/images/Hompage web.png";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { BASE_URL, FORGOT } from "../helpers/url_helper";

const ForgotPassword = () => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.post(
        BASE_URL + FORGOT,
        {
          email: email,
        },
        { headers }
      );

      const { error, message, token } = response.data;
      console.log("response: ", response);
      if (message == "Email sent successfully") {
        toast.success(message);
      }
      if (message == "User with this email does not exist") {
        toast.error(message);
      }
      console.log("message: ", message);

      if (!error) {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("message", message);
        if (message === "login successful") {
          toast.success(message);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      } else {
        console.log("Authentication failed:", message);
        // Handle authentication failure (display error message, etc.)
      }
    } catch (error) {
      console.log("Error:", error);
      // toast.error(error);

      // Handle error (display error message, etc.)
    }
  };

  useEffect(() => {
    if (token) {
      handleShow();
    }
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Container fluid className="otp-form-container">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Row
        className="d-flex align-items-center justify-content-center"
        style={{ height: "36.5rem" }}
      >
        <Col
          md={4}
          className="text-center"
          style={{ backgroundColor: "#DCDAFF", height: "100vh" }}
        >
          <Carousel
            controls={false}
            indicators={false}
            interval={3000}
            pause={false}
            style={{ marginTop: "10rem" }}
          >
            <Carousel.Item>
              <img
                className="d-block w-100 img-height"
                src={image1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 img-height"
                src={image2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 img-height"
                src={image3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={8}>
          <div className="otp-form">
            <div className="form-center">
              <h2>Forgot Password</h2>
              <p>Enter your register email!</p>
              <div className="p-4 mx-auto" style={{ width: "60%" }}>
                <form onSubmit={submitHandler}>
                  <div className="mb-3">
                    <input
                      style={{
                        border: "2px solid #4a2a7d",
                        borderRadius: "8px",
                        fontSize: "15px",
                      }}
                      type="text"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="name"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn py-2 w-100"
                    style={{
                      backgroundColor: "#4a2a7d",
                      color: "white",
                      marginTop: "20px",
                    }}
                  >
                    Forgot Password
                  </button>

                  <Link
                    to="/login"
                    // className="btn py-2 w-100"
                    style={{
                      // backgroundColor: "#4a2a7d",
                      color: "#4a2a7d",
                      marginTop: "20px",
                      display: "flex",

                      border: "none",
                      background: "#FFFFFF",
                    }}
                  >
                    Login Now
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
