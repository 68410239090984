import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  BASE_URL,
  OMC,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
} from "../helpers/url_helper.js";

import AddIcon from "@mui/icons-material/Add";

import Checkbox from "@mui/material/Checkbox";

Modal.setAppElement("#root"); // Required for accessibility

const SelectedLOI = () => {
  const token = sessionStorage.getItem("token");

  const [list, setList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + OMC + LIST, { headers });
      const list = response.data.data;
      console.log("Employee list=", list);

      setList(list);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + OMC + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: Name,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // -------------------------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + OMC + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: newName,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("create new employye response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  //-----------------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + OMC + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    setSelectedRow(data);
    setName(data.name);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleCreate = () => {
    createNew();
    closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.some(
      (selectedRow) => selectedRow.name === row.name
    );
    if (isSelected) {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.name !== row.name)
      );
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  return (
    <div className="container-fluid">
      {/* Employee List */}
      <div className="row">
        {/* <div className="col-sm-6">
          <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            onClick={openCreateModal}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            Send LOI
          </Button>
        </div> */}

        <div className="col-md-12">
          {/* list of RO */}
          <Paper>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                  <TableRow>
                    {/* <TableCell align="center">Sr No</TableCell> */}
                    <TableCell align="center">RO Name</TableCell>
                    <TableCell align="center">Feasibility</TableCell>
                    <TableCell align="center">Send LOI</TableCell>
                    <TableCell align="center">Download LOI</TableCell>
                    <TableCell align="center">Upload Side Letter</TableCell>
                    <TableCell align="center">Download Side Letter</TableCell>
                    {/* <TableCell align="center">Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: "#ffff",
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>

                        <TableCell align="center">
                          <Button variant="contained">Feasibility</Button>
                        </TableCell>

                        <TableCell align="center">
                          {/* <Button variant="contained">Send LOI</Button>
                           */}
                          <Button
                            variant="contained"
                            onClick={() => setShowForm(!showForm)}
                          >
                            {showForm ? "Send LOI" : "Send LOI"}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" disabled>
                            Download LOI
                          </Button>
                        </TableCell>

                        <TableCell align="center">
                          <Button variant="contained">
                            Upload Side Letter
                          </Button>
                        </TableCell>

                        <TableCell align="center">
                          <Button variant="contained" disabled>
                            Download Side Letter
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>

                <TableFooter>
                  {/* style={{display:"flex", justifyContent:"end",alignItems:"end"}} */}
                  {/* <Button
                    variant="contained"
                    onClick={() =>
                      console.log("Next clicked for:", selectedRows)
                    }
                  >
                    back
                  </Button> */}
                </TableFooter>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          {/* =========================================================================================== */}
        </div>
      </div>
      {/* End Region List */}

      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit OMC"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit OMC</h3>
          <form>
            <input type="hidden" name="name" value={selectedRow._id} readOnly />

            <div className="form-group p-2">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}

      {/* Create New Region Modal */}
      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeCreateModal}
        contentLabel="Create Region"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
          },
        }}
      >
        <h3 className="text-center fw-bold form_header">Create OMC</h3>
        <form>
          <div className="row">
            <div className="col">
              <div className="form-group p-2">
                <label>OMC Name</label>
                <input type="text" onChange={handleNewNameChange} required />
              </div>
            </div>
          </div>

          <div className="div m-3">
            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              onClick={closeCreateModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={handleCreate}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal>
      {/* End Create New Region Modal */}
    </div>
  );
};

export default SelectedLOI;
