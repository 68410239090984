import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import { DataGrid } from "@mui/x-data-grid";
import { DateRangePicker } from "rsuite";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import AddIcon from "@mui/icons-material/Add";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
Modal.setAppElement("#root"); // Required for accessibility

// const columns = [
//   { field: 'id', headerName: 'ID',  },
//   { field: 'Name', headerName: 'Name', },
//   { field: 'Email', headerName: 'Email', },
//   { field: 'Mobile No', headerName: 'Mobile No', },
//   { field: 'Edit', headerName: 'Edit',},
//   { field: 'Tag', headerName: 'Tag',},
//   { field: 'Tagged Employee', headerName: 'Tagged Employee', },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];
const Client = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const token = sessionStorage.getItem("token");
  console.log("token", token);
  const [employeeList, setEmployeeList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [tagModalIsOpen, setTagModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [taggedClients, setTaggedClients] = useState([]);
  const [mobile, setMobile] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [newEmployeeName, setNewEmployeeName] = useState("");
  const [newEmployeeEmail, setNewEmployeeEmail] = useState("");
  const [clientCreateData, setclientCreateData] = useState("");
  const [clientCreatedDate, setclientCreatedDate] = useState([]);
  // -------------create new employee----------------

  const [fullName, setFullName] = useState("");
  const [NewEmail, setNewEmail] = useState("");
  const [NewMobile, setNewMobile] = useState("");
  const [NewDesign, setNewDesign] = useState("");

  useEffect(() => {
    fetchEmployees();
  }, []);

  console.log("clientCreatedDate", clientCreatedDate);

  const convertToMMDDYYYY = (isoDateString) => {
    const date = new Date(isoDateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-based month
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    setclientCreateData(`${month}/${day}/${year}`);
    return `${month}/${day}/${year}`;
  };

  console.log("convert", clientCreateData); // Output: 05/28/2024

  const fetchEmployees = async () => {
    try {
      const url = "https://app.batuk.in/Client_List";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const employees = response.data.data;
      console.log("Client list=", employees);
      employees?.map((item) => {
        setclientCreatedDate(item.createdAt);
      });
      setEmployeeList(employees);
      console.log("employee");
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const fetchClients = async () => {
    try {
      const url = "https://app.batuk.in/Employee_List";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      console.log("Employee list=", clients);
      clients?.map((item) => {
        setclientCreatedDate(item.createdAt);
        console.log(item.createdAt);
      });
      setClientList(clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  console.log("taggedClients", taggedClients);
  const TagclientApi = async () => {
    try {
      const url = "https://app.batuk.in/tag";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        client_name: selectedEmployee.name,
        employee_name: selectedClient,
      };

      console.log("request body of client tag", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("client tag api response", data);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const employeeClienttagged = async (employee_id) => {
    console.log("employee _id", employee_id);
    try {
      const url = `https://app.batuk.in/employeetagged/${employee_id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const taggedClientsData = response.data.data;
      console.log("Tagged employee data:", taggedClientsData);
      setTaggedClients(taggedClientsData);
    } catch (error) {
      console.error("Error fetching tagged clients data:", error);
    }
  };

  const updateEmployee = async (employeeId) => {
    console.log("client id id in update", employeeId);
    try {
      const url = `https://app.batuk.in/client_update/${employeeId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: Name,
        Email: email,
        // Designation: mobile // Changed from phone to designation
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // -------------------------------create new employee----------------------
  const CreateNewEmp = async () => {
    try {
      const url = "https://app.batuk.in/client_create";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: newEmployeeName,
        Email: newEmployeeEmail,
        mobileNo: NewMobile,
      };

      console.log("request body of create new client", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("create new Client response", response);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleNewEmployeeMobileChange = (e) => {
    setNewMobile(e.target.value);
  };

  const handlenewDesignantion = (e) => {
    setNewDesign(e.target.value);
  };

  // ------------------------end---------------------------------

  const openEditModal = (employee) => {
    setSelectedEmployee(employee);
    setName(employee.name);
    setEmail(employee.Email);
    // setDesignation(employee.Designation);
    setMobile(employee.mobileNo); // Set the designation from employee data
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const handleMobilechange = (e) => {
    setMobile(e.target.value);
  };


  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
  };

  const handleSave = (employee) => {
    updateEmployee(employee._id);
    // console.log('Updated employee details:', selectedEmployee);
    closeEditModal();
    fetchEmployees();
  };

  const openTagModal = async (employee) => {
    setSelectedEmployee(employee);
    await fetchClients();
    setTagModalIsOpen(true);
  };

  const closeTagModal = () => {
    setTagModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleTag = async () => {
     TagclientApi();
    closeTagModal();
    fetchEmployees();
  };

  const openViewModal = async (employee) => {
    setSelectedEmployee(employee);
    await employeeClienttagged(employee._id);
    setViewModalIsOpen(true);
  };

  const closeViewModal = () => {
    console.log("Closing view modal");
    setViewModalIsOpen(false);
    setTaggedClients([]);
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewEmployeeNameChange = (e) => {
    setNewEmployeeName(e.target.value);
  };

  const handleNewEmployeeEmailChange = (e) => {
    setNewEmployeeEmail(e.target.value);
  };

  const handleCreateEmployee = () => {
    CreateNewEmp();
    closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("data input search", employeeList);
    const filteredDates = employeeList?.filter((dateString) => {
      console.log("created at=======================", dateString);
      return query == dateString.name || dateString.Email || dateString.PhoneNo;
    });

    console.log("filtered name", filteredDates);
    setfilteredClients(filteredDates);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const dateRangePickerRef = useRef();

  useEffect(() => {
    // Example of using querySelector to get the value when component mounts or updates
    const getDateRangeValue = () => {
      const dateRangeElement = document.querySelector("#date-range-picker");
      if (dateRangeElement) {
        console.log(
          "Selected Date Range via querySelector:",
          dateRangeElement.value
        );
      }
    };

    getDateRangeValue();
  }, [dateRange]);

  const [value, setValue] = React.useState([new Date(""), new Date("")]);

  const datearr = [
    "2024-05-28T08:04:06.028Z",
    "2024-07-28T08:04:06.028Z",
    "2024-01-28T08:04:06.028Z",
    "2024-09-28T08:04:06.028Z",
  ];
  console.log("clientCreatedDate", clientCreatedDate);
  var firstDate;
  var lastDate;
  const [filteredClients, setfilteredClients] = useState(employeeList);

  useEffect(() => {
    firstDate = convertToMMDDYYYY(value[0]);
    lastDate = convertToMMDDYYYY(value[1]);
    console.log("date range", firstDate, lastDate);
    console.log(
      "clientList==================================================",
      clientList
    );
    const filteredDates = employeeList?.filter((dateString) => {
      console.log("created at=======================", dateString);
      const date = convertToMMDDYYYY(dateString.createdAt);
      console.log("new da", date);
      return date >= firstDate && date <= lastDate;
    });

    console.log("filteredDates", filteredDates);
    setfilteredClients(filteredDates);
  }, [value]);

  return (
    <>
      <div className="container">
        {/* <div className="row d-flex justify-content-start" >
          <div className="col-sm-12 ">
            <div>
            <Button
              variant="contained"
              endIcon={<AddIcon sx={{ color: "white !important" }} />}
              onClick={openCreateModal}>
              Create New client
            </Button>

            </div>
       
          </div>
        </div> */}

        {/* Employee List */}

        {selectedEmployee && (
          <Modal
            isOpen={editModalIsOpen}
            onRequestClose={closeEditModal}
            contentLabel="Edit Employee"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
              },
              padding: "20px",
              paddingBottom: "31px",
            }}>
            <h3 className="text-center  form_header">Edit Client</h3>
            <form>
              <div className="form-group p-2">
                <label>User ID</label>
                <input
                  type="text"
                  name="name"
                  value={selectedEmployee._id}
                  readOnly
                />
              </div>

              <div className="form-group p-2">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={Name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="form-group p-2">
                <label>Email</label>
                <input
                  type="email"
                  name="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              {/* <div className="form-group p-2">
                            <label>Mobile No</label>
                            <input
                                type="text"
                                className="form-control"
                                name="designation"
                                value={mobile}
                                onChange={handleDesignationChange}
                            />
                        </div> */}
              <div className="div p-2">
                <Button
                  type="button"
                  variant="contained"
                  className="btn btn-secondary mt-2"
                  style={{ float: "left" }}
                  onClick={closeEditModal}>
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className="btn btn-primary mt-2"
                  style={{ float: "right" }}
                  onClick={() => handleSave(selectedEmployee)}>
                  Update
                </Button>
              </div>
            </form>
          </Modal>
        )}

        {selectedEmployee && (
          <Modal
            isOpen={tagModalIsOpen}
            onRequestClose={closeTagModal}
            contentLabel="Tag Employee"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
              },
            }}>
            <h3 className="text-center fw-bold">Tag Client</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleTag();
              }}>
              <div className="form-group p-2">
                <label>Client Name</label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="name"
                  value={selectedEmployee.name}
                  readOnly
                />
              </div>

              <div className="form-group p-2">
                <label>Employee Name</label>
                <select
                  className="form-control"
                  value={selectedClient}
                  onChange={handleClientChange}>
                  <option value="">Select Client</option>
                  {clientList.map((client) => (
                    <option key={client.id} value={client.name}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="button"
                className="btn btn-secondary mt-2"
                style={{ float: "left" }}
                onClick={closeTagModal}>
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary mt-2"
                style={{ float: "right" }}>
                Tag
              </button>
            </form>
          </Modal>
        )}

        <Modal
          isOpen={viewModalIsOpen}
          onRequestClose={closeViewModal}
          contentLabel="View Tagged Clients"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}>
          <h3 className="text-center  form_header">Tagged Employee</h3>
          {taggedClients.length > 0 ? (
            taggedClients.map((taggedClient, index) => (
              <div className="row">
                <div className="col-sm-6 text-center">
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}>
                    Employee Name:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}>
                    Employee Mobile:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}>
                    Employee Email:{" "}
                  </p>
                </div>
                <div className="col-sm-5 text-left">
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}>
                    {taggedClient.name}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}>
                    {taggedClient.PhoneNo}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}>
                    {taggedClient.Email}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              No Emolpyee tagged with this Client.
            </div>
          )}
          <Button
            variant="contained"
            type="button"
            className="btn btn-secondary mt-2"
            onClick={closeViewModal}>
            Close
          </Button>
        </Modal>

        <Modal
          isOpen={createModalIsOpen}
          onRequestClose={closeCreateModal}
          contentLabel="Create New Employee"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}>
          <h3 className="text-center fw-bold form_header">Create New Client</h3>
          <form>
            <div className="row">
              <div className="col">
                <div className="form-group p-2">
                  <label>Full Name</label>
                  <input
                    type="text"
                    onChange={handleNewEmployeeNameChange}
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group p-2">
                  <label>Email</label>
                  <input
                    type="email"
                    onChange={handleNewEmployeeEmailChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group p-2">
                  <label>Mobile No</label>
                  <input
                    type="number"
                    // value={newEmployeeName}
                    onChange={handleNewEmployeeMobileChange}
                    required
                  />
                </div>
              </div>
              {/* <div className='col'>
                            <div className="form-group p-2">
                                <label>Designation</label>
                                <input
                                    type="email"
                                    className="form-control"
                                  
                                    onChange={handlenewDesignantion}
                                    required
                                />
                            </div>
                        </div> */}
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeCreateModal}>
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={handleCreateEmployee}>
              Create
            </Button>
          </form>
        </Modal>
      </div>
      <div className="conainer">
        <div className="row">
          <div className="col-sm-6">
            <Button
              variant="contained"
              endIcon={<AddIcon sx={{ color: "white !important" }} />}
              onClick={openCreateModal}
              sx={{ marginBottom: "20px", textTransform: "capitalize" }}>
              Create New Client
            </Button>
          </div>
          <div className="col-sm-6 d-flex justify-content-end">
            <form>
              {/* <DateRangePicker
                character=" – "
                style={{
                  color: "#4a2a7d !important",
                  border: " solid 2px #4a2a7d",
                  marginRight: "20px",
                }}
                value={value >= 1 ? value : null}
                onChange={setValue}
                placeholder="select date"
              /> */}
              {/* <input
                type="text"
                placeholder="Search..."
                value={query}
                onChange={handleInputChange}
                id="searchInput"
              />
              <Button
                variant="contained"
                type="button"
                className="btn btn-primary"
                id="searchSubmit"
                onClick={handleSearch}>
                Search
              </Button> */}
            </form>
          </div>
          <div className="col-sm-12 client">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                    <TableRow>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Mobile No</TableCell>
                      <TableCell align="center">Tagged Employee</TableCell>
                      <TableCell align="center">Action</TableCell>
                     
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* =============================================================== */}

                    {query !== ""
                      ? filteredClients.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#ffff",
                            }}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.Email}</TableCell>
                          <TableCell align="center">{row.mobileNo}</TableCell>
                          <TableCell align="center">


                            {/* ============================== */}


                            {
                             console.log("taggedClients",taggedClients.length == 0 ) 
                            }
                         {/* {  taggedClients.length === 0 ? "No Emolpyee tagged with this Client.":<Button
                                id="demo-positioned-button"
                                variant="contained"
                                aria-controls={
                                  open ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={() => openViewModal(row)}
                                disableElevation
                               >
                                View Tagged Employee
                              </Button>} */}
                              <Button
                                id="demo-positioned-button"
                                variant="contained"
                                aria-controls={
                                  open ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={() => openViewModal(row)}
                                disableElevation
                               >
                                View
                              </Button>
                            {/* ============================== */}
                             
                            </TableCell>
                          <TableCell align="center">
                            <Button
                              style={{marginRight:"20px"}}
                                variant="contained"
                                onClick={() => openTagModal(row)}>
                              Tag Employee
                              
                              </Button>
                            <Button
                                                            style={{marginRight:"20px"}}

                                variant="contained"
                                onClick={() => openEditModal(row)}>
                                Edit 
                              </Button>
                            </TableCell>
                          
                          
                          </TableRow>
                        ))
                      : employeeList.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: "#ffff",
                            }}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.Email}</TableCell>
                          <TableCell align="center">{row.mobileNo}</TableCell>
                          <TableCell align="center">

{/* 
                          {   taggedClients.length === 0 ? "No Emolpyee tagged with this Client.":<Button
                                id="demo-positioned-button"
                                variant="contained"
                                aria-controls={
                                  open ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={() => openViewModal(row)}
                                disableElevation
                               >
                                View Tagged Employee
                              </Button>} */}


                              <Button
                                id="demo-positioned-button"
                                variant="contained"
                                aria-controls={
                                  open ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={() => openViewModal(row)}
                                disableElevation
                               >
                                View
                              </Button>
                            </TableCell>
                          <TableCell align="center">
                            <Button
                                                            style={{marginRight:"20px"}}

                                variant="contained"
                                onClick={() => openTagModal(row)}>
                              Tag Employee
                              
                              </Button>

                              <Button
                                variant="contained"
                                onClick={() => openEditModal(row)}>
                                Edit
                              </Button>
                            
                             
                            </TableCell>
                          
                          </TableRow>
                        ))}

                    {/* ================================================================ */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;
