// import React, { useEffect, useState } from "react";
// import { Col, Button, Row, Card, CardHeader, CardBody } from "react-bootstrap";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.css";
// import BatukLogo from "../images/Batuk-logo.png";

// const SignIn = () => {
//   const navigate = useNavigate();

//   const [emailOrUsername, setEmailOrUsername] = useState("");
//   const [password, setPassword] = useState("");

//   const [index, setIndex] = useState(0);
//   const handleSelect = (selectedIndex) => {
//     setIndex(selectedIndex);
//   };

//   const isEmail = (input) => {
//     // Regex to check if input is an email
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(input);
//   };

//   const submitHandler = async (e) => {
//     e.preventDefault();

//     let loginIdentifier = emailOrUsername;
//     if (isEmail(emailOrUsername)) {
//       loginIdentifier = emailOrUsername.toLowerCase();
//     } else {
//       loginIdentifier = emailOrUsername.toUpperCase();
//     }

//     try {
//       const response = await axios.post("https://app.batuk.in/login", {
//         email: loginIdentifier,
//         password: password,
//       });

//       const { auth, message, token } = response.data;

//       if (auth) {
//         sessionStorage.setItem("token", token);
//         sessionStorage.setItem("message", message);
//         if (message === "partner login successful") {
//           navigate("/");
//         } else if (message === "employee login successful") {
//           navigate("/");
//         }
//       } else {
//         console.log("Authentication failed:", message);
//       }
//     } catch (error) {
//       console.log("Error:", error);
//     }
//   };

//   const token = sessionStorage.getItem("token");

//   useEffect(() => {
//     if (token) {
//       handleShow();
//     }
//   }, []);

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   return (
//     <div className="p-4 mx-auto">
//       <form onSubmit={submitHandler}>
//         <div className="mb-3">
//           <input
//             style={{
//               border: "2px solid #4a2a7d",
//               borderRadius: "8px",
//               fontSize: "15px",
//             }}
//             type="text"
//             placeholder="Enter email/username"
//             value={emailOrUsername}
//             onChange={(e) => setEmailOrUsername(e.target.value)}
//             id="name"
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             style={{
//               border: "2px solid #4a2a7d",
//               borderRadius: "8px",
//               fontSize: "15px",
//             }}
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             type="password"
//             placeholder="Password"
//             id="password"
//           />
//         </div>

//         <button
//           className="btn py-2 w-100"
//           style={{
//             backgroundColor: "#4a2a7d",
//             color: "white",
//             marginTop: "20px",
//           }}
//         >
//           Login
//         </button>
//       </form>
//     </div>
//   );
// };

// export default SignIn;

import React, { useEffect, useState } from "react";
// import { Col, Button, Row, Card, CardHeader, CardBody } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
// import BatukLogo from "../images/Batuk-logo.png";
import "../helpers/url_helper.js";
import { BASE_URL, LOGIN } from "../helpers/url_helper.js";

const SignIn = () => {
  // const navigate = useNavigate();

  // const [emailOrUsername, setEmailOrUsername] = useState("");
  // const [password, setPassword] = useState("");

  // const [index, setIndex] = useState(0);
  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };

  // const isEmail = (input) => {
  //   // Regex to check if input is an email
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(input);
  // };

  // const submitHandler = async (e) => {
  //   e.preventDefault();

  //   let loginIdentifier = emailOrUsername;
  //   if (isEmail(emailOrUsername)) {
  //     loginIdentifier = emailOrUsername.toLowerCase();
  //   } else {
  //     loginIdentifier = emailOrUsername.toUpperCase();
  //   }

  //   try {
  //     const response = await axios.post("https://app.batuk.in/login", {
  //       email: loginIdentifier,
  //       password: password,
  //     });

  //     const { auth, message, token } = response.data;

  //     if (auth) {
  //       sessionStorage.setItem("token", token);
  //       sessionStorage.setItem("message", message);
  //       if (message === "partner login successful") {
  //         navigate("/");
  //       } else if (message === "employee login successful") {
  //         navigate("/");
  //       }
  //     } else {
  //       console.log("Authentication failed:", message);
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  // const token = sessionStorage.getItem("token");

  // useEffect(() => {
  //   if (token) {
  //     handleShow();
  //   }
  // }, []);

  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(email, password);
    try {
      const response = await axios.post(BASE_URL + LOGIN, {
        email: email,
        password: password,
      });

      const { error, message, token, role } = response.data;
      console.log("response: ", response.data);

      console.log("message: ", message);

      if (!error) {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("role", role);
        sessionStorage.setItem("message", message);
        if (message === "login successful") {
          toast.success(message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } else {
        console.log("Authentication failed:", message);
        toast.error(message);
        // Handle authentication failure (display error message, etc.)
      }
    } catch (error) {
      console.log("Error:", error);
      // Handle error (display error message, etc.)
    }
  };

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (token) {
      handleShow();
    }
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="p-4 mx-auto" style={{ width: "60%" }}>
        <form onSubmit={submitHandler}>
          <div className="mb-3">
            <input
              style={{
                border: "2px solid #4a2a7d",
                borderRadius: "8px",
                fontSize: "15px",
              }}
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="name"
            />
          </div>
          <div className="mb-3">
            <input
              style={{
                border: "2px solid #4a2a7d",
                borderRadius: "8px",
                fontSize: "15px",
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              id="password"
            />
          </div>

          <button
            className="btn py-2 w-100"
            style={{
              backgroundColor: "#4a2a7d",
              color: "white",
              marginTop: "20px",
            }}
          >
            Login
          </button>
          <Link
            to="/forgot-password"
            // className="btn py-2 w-100"
            style={{
              // backgroundColor: "#4a2a7d",
              color: "#4a2a7d",
              marginTop: "20px",
              display: "flex",

              border: "none",
              background: "#FFFFFF",
            }}
          >
            Forgot Password ?
          </Link>
        </form>
      </div>
    </>
  );
};

export default SignIn;
