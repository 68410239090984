import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from "sweetalert2";
import {
  BASE_URL,
  DEPARTMENT,
  CREATE,
  UPDATE,
  DELETE,
  LIST,
} from "../helpers/url_helper.js";

import {
  Container,
  TextField,
  Card,
  CardContent,
  MenuItem,
  TextareaAutosize,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";

import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AddIcon from "@mui/icons-material/Add";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

Modal.setAppElement("#root"); // Required for accessibility

const Department = () => {
  const token = sessionStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Name, setName] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [newName, setNewName] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(BASE_URL + DEPARTMENT + LIST, {
        headers,
      });
      const list = response.data.data;
      console.log("Employee list=", list);
      setList(list);
      setLoading(false);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateData = async (id) => {
    try {
      const url = BASE_URL + DEPARTMENT + UPDATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        id: id,
        name: Name,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
      fetchList();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // -------------------------------create new region----------------------
  const createNew = async () => {
    try {
      const url = BASE_URL + DEPARTMENT + CREATE;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: newName,
      };

      console.log("request body of create new employee", requestBody);

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (response.data.message == "department inserted successfully") {
        toast.success(response.data.message);
      }

      fetchList();
    } catch (error) {
      toast.error(error);
      console.error("Error connecting to API", error);
    }
  };

  //-----------------------delete region---------------------------------
  const deleteRow = (row) => {
    console.log(row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL + DEPARTMENT + DELETE}/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          fetchList();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Data is safe",
          icon: "error",
        });
      }
    });
  };

  // ------------------------end---------------------------------

  const openEditModal = (data) => {
    setSelectedRow(data);
    setName(data.name);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedRow(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (data) => {
    updateData(data._id);

    closeEditModal();
  };

  const handleTag = async () => {
    fetchList();
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleCreate = () => {
    createNew();
    closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = (rows) => {
    const rowData = list.map((row) => ({
      Name: row.name,
      // email: row.original.Email,
      // mobileNo: row.original.mobileNo,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      Name: row.original.name,
      // email: row.original.Email,
      // mobileNo: row.original.mobileNo,
    }));
    // const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      size: 30,
    }),

    // columnHelper.accessor("Designation", {
    //   header: "Designation",
    //   size: 30,
    // }),
    // columnHelper.accessor("TaggedClient", {
    //   header: "Tagged Client",
    //   size: 60,
    //   Cell: (cell) => (
    //     <IconButton onClick={() => openViewModal(cell.row.original)}>
    //       <RemoveRedEye color="error" />
    //     </IconButton>
    //   ),
    // }),
  ];
  const [employeeList, setEmployeeList] = useState([]);

  const table = useMaterialReactTable({
    columns,
    // data: employeeList,
    data: list,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>
        <IconButton onClick={() => deleteRow(row.original)}>
          <DeleteIcon color="error" />
        </IconButton>
        {/* <Button
          style={{ marginRight: "20px" }}
          variant="contained"
          // onClick={(e) => handleDelete(row._id)}
          onClick={() => deleteRow(row.original)}
        >
          Delete
        </Button> */}
        {/* <IconButton onClick={() => openEditModal(row.original)}>
          <Link to="/client_kyc" state={row.original}>
            <Button style={{ marginRight: "30px" }} variant="contained">
              KYC
            </Button>
          </Link>
        </IconButton> */}
      </Box>,
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* Employee List */}
      <div className="row">
        <div className="col-sm-6">
          <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            onClick={openCreateModal}
            sx={{ marginBottom: "20px", textTransform: "capitalize" }}
          >
            Create Department
          </Button>
        </div>
        {/* new table */}
        <Row>
          <Col xs={12} className="index">
            {/* Conditional rendering of loader */}
            {loading ? (
              <Box sx={{ width: "100%" }}>
                {/* <CircularProgress color="success"/> */}
                <LinearProgress />
              </Box>
            ) : (
              <MaterialReactTable table={table} />
            )}
          </Col>
        </Row>
        <div className="col-md-12">
          {/* <Paper>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                  <TableRow>
                    <TableCell align="center">Sr No</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>


                  {list.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: "#ffff",
                          }}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                     
                          <TableCell align="center">
                            <Button
                              style={{ marginRight: "20px" }}
                              variant="contained"
                              onClick={() => openEditModal(row)}>
                              Edit
                            </Button>

                            <Button
                              variant="contained"
                              onClick={() => deleteRow(row)}>
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> */}

          {/* =========================================================================================== */}
        </div>
      </div>
      {/* End Region List */}

      {/* Edit Region Modal */}
      {selectedRow && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Department"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Department</h3>
          <form>
            <input type="hidden" name="name" value={selectedRow._id} readOnly />

            <div className="form-group p-2">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedRow)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Region Modal */}

      {/* Create New Region Modal */}
      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeCreateModal}
        contentLabel="Create Region"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
          },
        }}
      >
        <h3 className="text-center fw-bold form_header">Create Department</h3>
        {/* my form code strat here */}
        <form>
          <div className="row">
            <div className="col">
              <div className="form-group p-2">
                <label>Department Name</label>
                <input type="text" onChange={handleNewNameChange} required />
              </div>
            </div>
          </div>

          <div className="div m-3">
            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              onClick={closeCreateModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={handleCreate}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal>
      {/* End Create New Region Modal */}
    </div>
  );
};

export default Department;
