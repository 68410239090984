import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./comman.scss";
import Overview from "./pages/Overview";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Login from "./pages/Login";
import Client from "./pages/Client";
import Region from "./pages/region";
import Department from "./pages/department";
import Ro from "./pages/Ro";
import RoSales from "./pages/RoSales";
import Omc from "./pages/omc";
import Designation from "./pages/designation";
import OmcEmployee from "./pages/omc_employee";
import State from "./pages/state";
import City from "./pages/city";
import Territory from "./pages/territory";
import Employee from "./pages/Employee";
import Purposal from "./pages/Purposal";
import CreateNewPurposal from "./pages/createNewPurposal";
import FinalCreate from "./pages/FinalCreate";
import AboutBatukPartener from "./pages/AboutBatukPartener";
import PersonalDetails from "./pages/PersonalDetails";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import Register from "./pages/Register";
import IndRegister from "./pages/IndiRegister";
import "sweetalert2/src/sweetalert2.scss";
import Header from "./components/Header";
import RoBooking from "./pages/RoBooking";
import LOI from "./pages/LOI";
import SelectedLOI from "./pages/SelectedLOI";
import DashBoard from './pages/DashBoard';
import SignUp from "./pages/SignUp";
import SMLs from "./pages/SMLs";
import FeasibleRO from "./pages/FeasibleRO";
import SideLetterRo from "./pages/SideLetterRo";
import ForgotPassword from "./pages/ForgotPassword";
import BPCL from "./pages/BPCL";
import HPCL from "./pages/HPCL";
import IOCL from "./pages/IOCL";
import OmcInvoice from './pages/OmcInvoice';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

    const token = sessionStorage.getItem("token");
  // useEffect(() => {
  //   const currentPath = location.pathname;

  //   if (!token && currentPath !== "/login" && currentPath !== "/forgotpassword" && currentPath !== "/reset-password" && currentPath !== "/register" && currentPath !== "/IndRegister") {
  //     navigate("/login");
  //   } else if (token && currentPath === "/login") {
  //     navigate("/");
  //   }
  // }, [navigate, location]);

  useEffect(() => {
    const publicRoutes = [
      "/login",
      "/forgot-password",
      "/register",
      "/IndRegister"
    ];
    const currentPath = location.pathname;

    if (!token && !publicRoutes.includes(currentPath)) {
      navigate("/login");
    }
    if (token && publicRoutes.includes(currentPath)) {
      navigate("/");
    }
  }, [token, location, navigate]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ResetPassword />} />
        {/* <Route path="/reset-password/:id" element={<ChangePassword />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/IndRegister" element={<IndRegister />} />
        <Route path="/" element={<DashBoard />}>
          <Route index element={<Overview />} />
          <Route path="client_table" element={<Client />} />
          <Route path="employee_table" element={<Employee />} />
          <Route path="region" element={<Region />} />
          <Route path="department" element={<Department />} />
          <Route path="ro" element={<Ro />} />
          <Route path="feasiblero" element={<FeasibleRO />} />
          <Route path="rosales" element={<RoSales />} />
          <Route path="robooking" element={<RoBooking />} />
          <Route path="sml" element={<SMLs />} />
          <Route path="omc" element={<Omc />} />
          <Route path="omc_employee" element={<OmcEmployee />} />
          <Route path="designation" element={<Designation />} />
          <Route path="state" element={<State />} />
          <Route path="city" element={<City />} />
          <Route path="territory" element={<Territory />} />
          <Route path="purposal" element={<Purposal />} />
          <Route path="create_purposal" element={<CreateNewPurposal />} />
          <Route path="final_create" element={<FinalCreate />} />
          <Route path="profile" element={<PersonalDetails />} />
          <Route path="aboutbatukpartener" element={<AboutBatukPartener />} />
          <Route path="personaldetails" element={<PersonalDetails />} />
          <Route path="loi" element={<LOI />} />
          <Route path="sideletterro" element={<SideLetterRo />} />
          <Route path="/loi/selectedloi" element={<SelectedLOI />} />
          <Route path="/bpcl" element={<BPCL />} />
          <Route path="/hpcl" element={<HPCL />} />
          <Route path="/iocl" element={<IOCL />} />
          <Route path="/omcinvoice" element={<OmcInvoice />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

// import logo from "./logo.svg";
// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
// // import 'rsuite/DateRangePicker/styles/index.css';
// import "./comman.scss";
// // import Dashboard from "./pages/Dasboard";
// import Overview from "./pages/Overview";
// import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
// import { useEffect } from "react";
// import Login from "./pages/Login";
// import Client from "./pages/Client";
// import Region from "./pages/region";
// import Department from "./pages/department";
// import Ro from "./pages/Ro";
// import RoSales from "./pages/RoSales";
// // import SML from "./pages/SML";
// import Omc from "./pages/omc";
// import Designation from "./pages/designation";
// import OmcEmployee from "./pages/omc_employee";
// import State from "./pages/state";
// import City from "./pages/city";
// import Territory from "./pages/territory";
// import Employee from "./pages/Employee";
// import Purposal from "./pages/Purposal";
// import CreateNewPurposal from "./pages/createNewPurposal";
// import FinalCreate from "./pages/FinalCreate";
// import AboutBatukPartener from "./pages/AboutBatukPartener";
// import PersonalDetails from "./pages/PersonalDetails";
// import ResetPassword from "./pages/ResetPassword";
// import ChangePassword from "./pages/ChangePassword";
// import Register from "./pages/Register";
// import IndRegister from "./pages/IndiRegister";
// import "sweetalert2/src/sweetalert2.scss";
// // import SignIn from "./pages/SignIn"
// import Header from "./components/Header";
// import RoBooking from "./pages/RoBooking";
// import LOI from "./pages/LOI";
// // import Cards from "./components/Cards"
// // import selectedLOI from "./pages/SelectedLOI";
// import SelectedLOI from "./pages/SelectedLOI";
// import DashBoard from './pages/DashBoard';
// import SignUp from "./pages/SignUp";
// import SMLs from "./pages/SMLs";
// import FeasibleRO from "./pages/FeasibleRO";
// // import LoiRo from './pages/LoiRo';
// import SideLetterRo from "./pages/SideLetterRo";
// import ForgotPassword from "./pages/ForgotPassword";
// import BPCL from "./pages/BPCL";
// import HPCL from "./pages/HPCL";
// import IOCL from "./pages/IOCL";
// import OmcInvoice from './pages/OmcInvoice';

// function App() {
//   const navigate = useNavigate();

//   const token = sessionStorage.getItem("token");
//   useEffect(() => {
//     if (!token) {
//       navigate("/login");
//     } else {
//       navigate("/");
//     }
//   }, []);

//   return (
//     <>
//       <Routes>
//         {/* <SignIn/> */}

//         {/* <Route path="/login" element={<SignIn />} /> */}
//         {/* <Route path="/login" element={<Header />} /> */}
//         <Route path="/login" element={<SignUp />} />
//         {/* <Route path="/login" element={<Login />} /> */}
//         <Route path="/forgotpassword" element={<ResetPassword />} />
//         <Route path="/reset-password/:id" element={<ChangePassword />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/forgot-password" element={<ForgotPassword/>} />
//         <Route path="/IndRegister" element={<IndRegister />} />
//         {/* <Route path="/" element={<Dashboard />}> */}
//         <Route path="/" element={<DashBoard />}>
//           <Route index element={<Overview />} />
//           <Route path="client_table" element={<Client />} />
//           <Route path="employee_table" element={<Employee />} />
//           <Route path="region" element={<Region />} />
//           <Route path="department" element={<Department />} />
//           <Route path="ro" element={<Ro />} />
//           <Route path="feasiblero" element={<FeasibleRO />} />

//           <Route path="rosales" element={<RoSales />} />
//           <Route path="robooking" element={<RoBooking />} />
//           <Route path="sml" element={<SMLs />} />
//           <Route path="omc" element={<Omc />} />
//           <Route path="omc_employee" element={<OmcEmployee />} />
//           <Route path="designation" element={<Designation />} />
//           <Route path="state" element={<State />} />
//           <Route path="city" element={<City />} />
//           <Route path="territory" element={<Territory />} />
//           <Route path="purposal" element={<Purposal />} />
//           <Route path="create_purposal" element={<CreateNewPurposal />} />
//           <Route path="final_create" element={<FinalCreate />} />
//           <Route path="profile" element={<PersonalDetails />} />
//           <Route path="aboutbatukpartener" element={<AboutBatukPartener />} />
//           <Route path="personaldetails" element={<PersonalDetails />} />
//           <Route path="loi" element={<LOI />} />
//           <Route path="sideletterro" element={<SideLetterRo />} />
//           <Route path="/loi/selectedloi" element={<SelectedLOI />} />
//           <Route path="/bpcl" element={<BPCL />} />
//           <Route path="/hpcl" element={<HPCL />} />
//           <Route path="/iocl" element={<IOCL />} />
//           <Route path="/omcinvoice" element={<OmcInvoice />} />
//           <Route path="/reset-password" element={<ResetPassword />} />

//           {/* <Route path="loi" element={<LoiRo />} /> */}
//         </Route>
//         {/* <Route path="overview" element={<Overview />} /> */}
//       </Routes>
//     </>
//   );
// }

// export default App;
