// src/SignUp.js
import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import "../assets/css/signup.css";
import Ci from "../assets/images/Curated Icons.png";
import Cd from "../assets/images/Completely digitalised.png";
import Bs from "../assets/images/Bank Grade Security.png";
import Hc from "../assets/images/Help Center.png";
import image1 from "../assets/images/Homepage 1.png";
import image2 from "../assets/images/Homepage 2 copy.png";
import image3 from "../assets/images/Hompage web.png";
import SignIn from "./SignIn";

const SignUp = () => {
  return (
    <Container fluid className="otp-form-container">
      <Row
        className="d-flex align-items-center justify-content-center"
        style={{height:"36.5rem" }}
      >
        <Col md={4} className="text-center" style={{backgroundColor:"#DCDAFF", height:"100vh" }}>
          <Carousel
            controls={false}
            indicators={false}
            interval={3000}
            pause={false}
            style={{marginTop:"10rem"}}
          >
            <Carousel.Item>
              <img
                className="d-block w-100 img-height"
                src={image1}
                alt="First slide"

              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 img-height"
                src={image2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 img-height"
                src={image3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={8} >
          <div className="otp-form">
            <div className="form-center">
              <h2>Get Started</h2>
              <p>Enter your email or username to login to your account.</p>
              <SignIn />
            </div>
            {/* <Row className="features" style={{ marginTop: "10rem" }}>
              <Col>
                <img src={Ci} className="bottom-logo" alt="Curated Investing" />
              </Col>
              <Col>
                <img
                  src={Cd}
                  className="bottom-logo"
                  alt="Completely Digitalised"
                />
              </Col>
              <Col>
                <img
                  src={Bs}
                  className="bottom-logo"
                  alt="Bank Grade Security"
                />
              </Col>
              <Col>
                <img src={Hc} className="bottom-logo" alt="Help Center" />
              </Col>
            </Row> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
