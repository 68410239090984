import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import batukLogo from "../assets/images/Batuk Logo.png";
import BatukLogo from "../images/Batuk-logo.png";
import MechmilesFabicon from "../assets/Icons/Mechmiles Fab icon.png";
import MechmilesLogo from "../assets/Icons/Mechmiles logo.png";

import notificationLogo from "../assets/Icons/Notification.png";
import faqLogo from "../assets/Icons/Faq.png";
import MenuBar from "../assets/Icons/menuicon.png";
import SignOutBlueLogo from "../assets/Icons/Sign out 2.png";
import ProfileLogo from "../assets/Icons/ProfileIcon.png";
import Earning from "../assets/Icons/Earnings.png";
import EarningHover from "../assets/Icons/Earnings 2.png";
import Client from "../assets/Icons/Client lists.png";
import ClientHover from "../assets/Icons/Client Lists 2.png"; // Add hover image
import Employees from "../assets/Icons/Employees.png";
import EmployeesHover from "../assets/Icons/Employees 2.png"; // Add hover image
import Proposal from "../assets/Icons/Proposal.png";
import ProposalHover from "../assets/Icons/Proposal 2.png"; // Add hover image
import Reports from "../assets/Icons/Report.png";
import ReportsHover from "../assets/Icons/Report 2.png";
import { IoBag, IoHomeOutline } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
// import { Mechmiles } from "srcassetsIcons";
import HomeLogo from "../assets/Icons/Home.png";
import HomeHover from "../assets/Icons/Home 2.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ROLogo from "../assets/Icons/newicons/Retails Outlet.png";
import ROHover from "../assets/Icons/newicons/Retails Outlet 1.png";
import SMLLogo from "../assets/Icons/newicons/SML.png";
import SMLHover from "../assets/Icons/newicons/SML 1.png";
import EmployeeLogo from "../assets/Icons/newicons/Employees.png";
import EmployeeHover from "../assets/Icons/newicons/Employees 2.png";
import DepartmentLogo from "../assets/Icons/newicons/Department.png";
import DepartmentHover from "../assets/Icons/newicons/Department 1.png";
import LOILogo from "../assets/Icons/newicons/LOI.png";
import LOIHover from "../assets/Icons/newicons/LOI 1.png";
import DesignationLogo from "../assets/Icons/newicons/LOI.png";
import DesignationHover from "../assets/Icons/newicons/LOI 1.png";
import ROsalesLogo from "../assets/Icons/newicons/Sales and ro sales.png";
import ROSalesHover from "../assets/Icons/newicons/Sales and ro sales 1.png";
import ROBookingLogo from "../assets/Icons/newicons/RO booking.png";
import ROBookingHover from "../assets/Icons/newicons/RO booking 1.png";
import OMCLogo from "../assets/Icons/newicons/OMC.png";
import OMCHover from "../assets/Icons/newicons/OMC 2.png";
import OMCEmployeeLogo from "../assets/Icons/newicons/OMC employee.png";
import OMCEmployeeHover from "../assets/Icons/newicons/OMC employee 2.png";
import RegionLogo from "../assets/Icons/newicons/Region.png";
import RegionHover from "../assets/Icons/newicons/Region 1.png";
import StateLogo from "../assets/Icons/newicons/State.png";
import StateHover from "../assets/Icons/newicons/State 1.png";
import CityLogo from "../assets/Icons/newicons/City.png";
import CityHover from "../assets/Icons/newicons/City 1.png";
import TerritoryLogo from "../assets/Icons/newicons/Teritory.png";
import TerritoryHover from "../assets/Icons/newicons/Teritory 2.png";
import { useState } from "react";
// import "../assets/css/customScrollbar.css";
import { IoMdLogOut } from "react-icons/io";
import axios from "axios";
import { BASE_URL, LOGOUT } from "./../helpers/url_helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const settings = ["Profile", "Reset Password", "Logout"];
const settings = [ "Reset Password", "Logout"];

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  "&:hover .icon": {
    display: "none",
  },
  "&:hover .icon-hover": {
    display: "block",
  },
  ".icon-hover": {
    display: "none",
  },
}));

const defaultTheme = createTheme();

export default function DashBoard() {
  const [open, setOpen] = React.useState(true);
  // const [role, setRole] = React.useState(0);
  const userRole = sessionStorage.getItem("role");

  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleOpenUserMenu = (event) => {
  //   navigate("/dashboard/profile");
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  // const callLogoutAPI = async () => {
  //   try {
  //     const url = "https://app.batuk.in/logout";
  //     const headers = {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     };

  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: headers,
  //     });

  //     const data = await response.json();
  //     console.log("data for logout : ", data.message);

  //     console.log("Logout API", data);
  //     // if (data.message === "logout successful") {
  //     navigate("/login");
  //     // } else {
  //     //   // alert("Issue in logout");
  //     // }
  //     return data;
  //   } catch (error) {
  //     console.error("Error connecting to API", error);
  //     throw error;
  //   }
  // };

  const callLogoutAPI = async () => {
    try {
      const url = BASE_URL + LOGOUT;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      console.log("logout response: ", response);
      if (response.data.message == "logout successful") {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("role");
        toast.success(response.data.message);
        navigate("/login");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const handleLogout = () => {
    callLogoutAPI();
    // sessionStorage.removeItem("token");
    // navigate("/");
  };

  const [hoverStates, setHoverStates] = useState({
    home: false,
    RO: false,
    FeasibleRO: false,
    SML: false,
    employees: false,
    department: false,
    LOI: false,
    designation: false,
    rosales: false,
    robooking: false,
    omc: false,
    omcemployee: false,
    location: false,
    region: false,
    state: false,
    city: false,
    territory: false,
    settings: false,
    sideLetterRo: false,
    BPCL: false,
    iocl: false,
    hpcl: false,
    omcinvoice: false,
  });

  const [activeItem, setActiveItem] = React.useState("");

  const handleListItemClick = (item) => {
    setActiveItem(item);
  };

  const handleMouseEnter = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };

  const handleMouseLeave = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: false,
    }));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar position="fixed" open={open} onClose={toggleDrawer}>
          <Toolbar
            disableGutters
            sx={{
              backgroundColor: "#4a2a7d",
              // width: "100vw"
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                
              >
                <img
                  src={MenuBar}
                  alt="batuk"
                  height="30px"
                  width="45px"
                  style={{ display: "flex", marginRight: 1 }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              ></Menu>
              {!open && (
                <img
                  // src={BatukLogo}
                  // alt="batuk"
                  src={MechmilesFabicon}
                  alt="MechmilesFabicon"
                  width="62px"
                  style={{ display: "flex", marginRight: "1rem" }}
                />
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex", justifyContent: "flex-end" },
              }}
            ></Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {/* <div className="welcome-name">
                    <Typography>Welcome</Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      {profileData.name}
                      jaspal
                    </Typography>
                  </div> */}
                  <IconButton onClick={handleOpenUserMenu} sx={{ paddingRight: "2rem" }}>
                    <img src={ProfileLogo} alt="Profile Logo" width="37px" />
                  </IconButton>
                </div>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* <div style={{ padding: "8px" }}>
                  <Typography>
                    {profileData.name}
                    jaspal
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Partner Code
                  </Typography>
                </div> */}
                {/* <hr style={{ padding: "0px", margin: "0px" }} /> */}
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#4d16a4",
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      if (setting === "Logout") {
                        handleLogout();
                      } else if (setting === "Profile") {
                        navigate("/profile");
                        handleCloseUserMenu();
                      } else if (setting === "Reset Password") {
                        navigate("/reset-password");
                        handleCloseUserMenu();
                      } else {
                        handleCloseUserMenu();
                      }
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>

              {/* <Link to="/profile">
                <IconButton
                  // onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  <img
                    src={ProfileLogo}
                    alt="Profile Logo"
                    width="35px"
                    style={{ marginRight: "1rem" }}
                  />
                </IconButton>
              </Link> */}
              {/* <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  handleLogout();
                }}
              >
                <img
                  src={SignOutBlueLogo}
                  alt="SIgn Out Logo"
                  width="35px"
                  style={{ marginRight: "2rem" }}
                />
              </IconButton> */}
              {/* <Link to="/faq">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img
                    src={faqLogo}
                    alt="faq"
                    width="35px"
                    style={{ marginRight: "1rem" }}
                  />
                </IconButton>
              </Link>
              <Link to="/notification">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img
                    src={notificationLogo}
                    alt="notification"
                    width="35px"
                    style={{ marginRight: "1rem" }}
                  />
                </IconButton>
              </Link> */}
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open} sx={{ height: "100vh" }}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
              height: "50px",
              backgroundColor: "#4a2a7d",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <img
                // src={batukLogo}
                // alt="batuk"
                src={MechmilesLogo}
                alt="MechmilesLogo"
                width="160px"
                style={{ display: "flex", marginRight: "2rem" }}
              />
            </IconButton>
          </Toolbar>
          <Divider />

          <List
            style={{ scrollbarWidth: "none" }}
            sx={{ marginTop: "5px", overflowY: "auto", height: "100vh" }}
          >
            <Link
              to="/"
              onMouseEnter={() => handleMouseEnter("home")}
              onMouseLeave={() => handleMouseLeave("home")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    hoverStates.home || activeItem == "Dashboard"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  onClick={() => handleListItemClick("Dashboard")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    className={
                      hoverStates.home || activeItem === "Dashboard"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={
                        hoverStates.home || activeItem === "Dashboard"
                          ? HomeHover
                          : HomeLogo
                      }
                      alt="Home Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="Dashboard"
                    // className={activeItem === "Dashboard" ? "active" : ""}
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.home || activeItem === "Dashboard"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Home
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/ro"
              onMouseEnter={() => handleMouseEnter("RO")}
              onMouseLeave={() => handleMouseLeave("RO")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor: activeItem == "RO" ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("RO")}
                >
                  <ListItemIcon
                    className={activeItem === "RO" ? "activecolor" : ""}
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "RO" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.RO || activeItem === "RO" ? ROHover : ROLogo
                      }
                      alt="RO Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.RO || activeItem === "RO"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Retail Outlets{" "}
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/feasiblero"
              onMouseEnter={() => handleMouseEnter("FeasibleRO")}
              onMouseLeave={() => handleMouseLeave("FeasibleRO")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    activeItem == "Feasible RO" ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("Feasible RO")}
                >
                  <ListItemIcon
                    className={
                      activeItem === "Feasible RO" ? "activecolor" : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "Feasible RO" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.FeasibleRO || activeItem === "Feasible RO"
                          ? ROHover
                          : ROLogo
                      }
                      alt="Feasible RO Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.FeasibleRO ||
                            activeItem === "Feasible RO"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Feasible RO
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            <Link
              to="/rosales"
              onMouseEnter={() => handleMouseEnter("rosales")}
              onMouseLeave={() => handleMouseLeave("rosales")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: activeItem == "RO Sales" ? "white" : "white",
                  backgroundColor:
                    hoverStates.rosales || activeItem == "RO Sales"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("RO Sales")}
                >
                  <ListItemIcon
                    className={
                      hoverStates.rosales || activeItem === "RO Sales"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "RO Sales" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.rosales || activeItem === "RO Sales"
                          ? ROSalesHover
                          : ROsalesLogo
                      }
                      alt="clients Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="RO Sales"
                    // className={activeItem === "RO Sales" ? "active" : ""}
                    // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.rosales || activeItem === "RO Sales"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Franchisee Sales
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/robooking"
              onMouseEnter={() => handleMouseEnter("robooking")}
              onMouseLeave={() => handleMouseLeave("robooking")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: activeItem == "RO Booking" ? "white" : "white",
                  backgroundColor:
                    hoverStates.robooking || activeItem == "RO Booking"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("RO Booking")}
                >
                  <ListItemIcon
                    className={
                      hoverStates.robooking || activeItem === "RO Booking"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "RO Booking" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.robooking || activeItem === "RO Booking"
                          ? ROBookingHover
                          : ROBookingLogo
                      }
                      alt="ROBooking Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="RO Booking"
                    // className={activeItem === "RO Booking" ? "active" : ""}
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.robooking || activeItem === "RO Booking"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        SML Booking
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            <Link
              to="/loi"
              onMouseEnter={() => handleMouseEnter("LOI")}
              onMouseLeave={() => handleMouseLeave("LOI")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: activeItem == "LOI" ? "white" : "white",
                  backgroundColor:
                    hoverStates.LOI || activeItem == "LOI"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("LOI")}
                >
                  <ListItemIcon
                    className={
                      hoverStates.LOI || activeItem === "LOI"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "LOI" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.LOI || activeItem === "LOI"
                          ? LOIHover
                          : LOILogo
                      }
                      alt="LOI Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="LOI"
                    // className={activeItem === "LOI" ? "active" : ""}
                    // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.LOI || activeItem === "LOI"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        LOI
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            <Link
              to="/sideletterro"
              onMouseEnter={() => handleMouseEnter("sidelatter")}
              onMouseLeave={() => handleMouseLeave("sidelatter")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: activeItem == "sidelatter" ? "white" : "white",
                  backgroundColor:
                    hoverStates.sidelatter || activeItem == "sidelatter"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("sidelatter")}
                >
                  <ListItemIcon
                    className={
                      hoverStates.sidelatter || activeItem === "sidelatter"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "sidelatter" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.sidelatter || activeItem === "sidelatter"
                          ? LOIHover
                          : LOILogo
                      }
                      alt="sidelatter Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="sidelatter"
                    // className={activeItem === "sidelatter" ? "active" : ""}
                    // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.sidelatter ||
                            activeItem === "sidelatter"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Side Letter RO
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            <Link
              to="/sml"
              onMouseEnter={() => handleMouseEnter("SML")}
              onMouseLeave={() => handleMouseLeave("SML")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: activeItem == "SML" ? "white" : "white",
                  backgroundColor:
                    hoverStates.SML || activeItem == "SML"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("SML")}
                >
                  <ListItemIcon
                    className={
                      hoverStates.SML || activeItem === "SML"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "SML" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.SML || activeItem === "SML"
                          ? SMLHover
                          : SMLLogo
                      }
                      alt="Home Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="SML"
                    // className={activeItem === "SML" ? "active" : ""}
                    // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.SML || activeItem === "SML"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        SML
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/employee_table"
              onMouseEnter={() => handleMouseEnter("employees")}
              onMouseLeave={() => handleMouseLeave("employees")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    hoverStates.employees || activeItem == "Employees"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  onClick={() => handleListItemClick("Employees")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    className={
                      hoverStates.employees || activeItem === "Employees"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "Employees" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.employees || activeItem === "Employees"
                          ? EmployeeHover
                          : EmployeeLogo
                      }
                      alt="clients Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="Employees"
                    // className={activeItem === "Employees" ? "active" : ""}

                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.employees || activeItem === "Employees"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        Employees
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            {/* out side invoice */}

            <Link
              to="/omcinvoice"
              onMouseEnter={() => handleMouseEnter("OMCINVOICE")}
              onMouseLeave={() => handleMouseLeave("OMCINVOICE")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: activeItem == "OMCINVOICE" ? "white" : "white",
                  backgroundColor:
                    hoverStates.OMCINVOICE || activeItem == "OMCINVOICE"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                  onClick={() => handleListItemClick("OMCINVOICE")}
                >
                  <ListItemIcon
                    className={
                      hoverStates.OMCINVOICE || activeItem === "OMCINVOICE"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "OMCINVOICE" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.OMCINVOICE || activeItem === "OMCINVOICE"
                          ? DesignationHover
                          : DesignationLogo
                      }
                      alt="OMCINVOICE Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />{" "}
                  </ListItemIcon>

                  <ListItemText
                    // primary="Designation"
                    // className={activeItem === "Designation" ? "active" : ""}
                    // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.OMCINVOICE ||
                            activeItem === "OMCINVOICE"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        OMC Invoice
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>

            <Link
              to="/bpcl"
              onMouseEnter={() => handleMouseEnter("BPCL")}
              onMouseLeave={() => handleMouseLeave("BPCL")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    hoverStates.BPCL || activeItem == "BPCL"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  onClick={() => handleListItemClick("BPCL")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    className={
                      hoverStates.BPCL || activeItem === "BPCL"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "BPCL" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.BPCL || activeItem === "BPCL"
                          ? EmployeeHover
                          : EmployeeLogo
                      }
                      alt="clients Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="BPCL"
                    // className={activeItem === "BPCL" ? "active" : ""}

                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.BPCL || activeItem === "BPCL"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        BPCL MIS
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            {/* <Link
              to="/hpcl"
              onMouseEnter={() => handleMouseEnter("HPCL")}
              onMouseLeave={() => handleMouseLeave("HPCL")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    hoverStates.HPCL || activeItem == "HPCL"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  onClick={() => handleListItemClick("HPCL")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    className={
                      hoverStates.HPCL || activeItem === "HPCL"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "HPCL" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.HPCL || activeItem === "HPCL"
                          ? EmployeeHover
                          : EmployeeLogo
                      }
                      alt="clients Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="BPCL"
                    // className={activeItem === "BPCL" ? "active" : ""}

                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.HPCL || activeItem === "HPCL"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        HPCLMIS
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link>
            <Link
              to="/iocl"
              onMouseEnter={() => handleMouseEnter("IOCL")}
              onMouseLeave={() => handleMouseLeave("IOCL")}
            >
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    hoverStates.IOCL || activeItem == "IOCL"
                      ? "#4a2a7d"
                      : "inherit",
                }}
              >
                <ListItemButtonStyled
                  onClick={() => handleListItemClick("IOCL")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    className={
                      hoverStates.IOCL || activeItem === "IOCL"
                        ? "activecolor"
                        : ""
                    }
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: activeItem == "IOCL" ? "white" : "white",
                    }}
                  >
                    <img
                      src={
                        hoverStates.IOCL || activeItem === "IOCL"
                          ? EmployeeHover
                          : EmployeeLogo
                      }
                      alt="clients Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    // primary="BPCL"
                    // className={activeItem === "BPCL" ? "active" : ""}

                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        style={{
                          color:
                            hoverStates.IOCL || activeItem === "IOCL"
                              ? "white"
                              : "#4A2A7D",
                        }}
                      >
                        IOCLMIS
                      </Typography>
                    }
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link> */}
            {userRole == 0 && (
              <Accordion
                sx={{
                  "&:before": { display: "none" },
                  boxShadow: "none",
                  padding: "0",
                  margin: "0",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    "&.Mui-expanded": { borderBottom: "none" },
                    borderBottom: "none",
                    margin: "0",
                    padding: "0",
                    backgroundColor: "#f5f5f5",
                    height: "0",
                  }}
                >
                  <ListItem
                    onMouseEnter={() => handleMouseEnter("settings")}
                    onMouseLeave={() => handleMouseLeave("settings")}
                    disablePadding
                    sx={{
                      my: "0",
                      margin: "0",
                      color: "white",
                      backgroundColor:
                        hoverStates.settings || activeItem == "Settings"
                          ? "#4a2a7d"
                          : "inherit",
                    }}
                  >
                    <ListItemButtonStyled
                      onClick={() => handleListItemClick("Settings")}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        className={
                          hoverStates.settings || activeItem === "Settings"
                            ? "activecolor"
                            : ""
                        }
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                          color: activeItem == "Settings" ? "white" : "white",
                        }}
                      >
                        <img
                          src={
                            hoverStates.settings || activeItem === "Settings"
                              ? EmployeeHover
                              : EmployeeLogo
                          }
                          alt="clients Logo"
                          width="35px"
                          // style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                          style={{
                            marginLeft: "1.82rem",
                            marginRight: "0.5rem",
                          }}
                        />
                      </ListItemIcon>

                      <ListItemText
                        // primary="Settings"
                        // className={activeItem === "Settings" ? "active" : ""}

                        disableTypography
                        primary={
                          <Typography
                            sx={{ display: open ? "block" : "none" }}
                            variant="body2"
                            style={{
                              color:
                                hoverStates.settings ||
                                activeItem === "Settings"
                                  ? "white"
                                  : "#4A2A7D",
                            }}
                          >
                            Settings
                          </Typography>
                        }
                      />
                    </ListItemButtonStyled>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderBottom: "none",
                    marginLeft: "0",
                    margin: "0",
                    padding: "0",
                    backgroundColor: "#f5f5f5",
                    my: "0",
                  }}
                >
                  <Link
                    to="/omc"
                    onMouseEnter={() => handleMouseEnter("omc")}
                    onMouseLeave={() => handleMouseLeave("omc")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "OMC" ? "white" : "white",
                        backgroundColor:
                          hoverStates.omc || activeItem == "OMC"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("OMC")}
                      >
                        <ListItemIcon
                          className={
                            hoverStates.omc || activeItem === "OMC"
                              ? "activecolor"
                              : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color: activeItem == "OMC" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.omc || activeItem === "OMC"
                                ? OMCHover
                                : OMCLogo
                            }
                            alt="OMC Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          // primary="OMC"
                          // className={activeItem === "OMC" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}

                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.omc || activeItem === "OMC"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              OMC
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>
                  <Link
                    to="/omc_employee"
                    onMouseEnter={() => handleMouseEnter("omcemployee")}
                    onMouseLeave={() => handleMouseLeave("omcemployee")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "OMC Employee" ? "white" : "white",
                        backgroundColor:
                          hoverStates.omcemployee ||
                          activeItem == "OMC Employee"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("OMC Employee")}
                      >
                        <ListItemIcon
                          className={
                            hoverStates.omcemployee ||
                            activeItem === "OMC Employee"
                              ? "activecolor"
                              : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color:
                              activeItem == "OMC Employee" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.omcemployee ||
                              activeItem === "OMC Employee"
                                ? OMCEmployeeHover
                                : OMCEmployeeLogo
                            }
                            alt="OMC Employee Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          // primary="OMC Employee"
                          // className={activeItem === "OMC Employee" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}

                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.omcemployee ||
                                  activeItem === "OMC Employee"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              OMC Employee
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>
                  {/* 
                <Link
                  to="/omcinvoice"
                  onMouseEnter={() => handleMouseEnter("OMCINVOICE")}
                  onMouseLeave={() => handleMouseLeave("OMCINVOICE")}
                >
                  <ListItem
                    disablePadding
                    sx={{
                      color: activeItem == "OMCINVOICE" ? "white" : "white",
                      backgroundColor:
                        hoverStates.OMCINVOICE || activeItem == "OMCINVOICE"
                          ? "#4a2a7d"
                          : "inherit",
                    }}
                  >
                    <ListItemButtonStyled
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                      onClick={() => handleListItemClick("OMCINVOICE")}
                    >
                      <ListItemIcon
                        className={
                          hoverStates.OMCINVOICE || activeItem === "OMCINVOICE"
                            ? "activecolor"
                            : ""
                        }
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                          color: activeItem == "OMCINVOICE" ? "white" : "white",
                        }}
                      >
                        <img
                          src={
                            hoverStates.OMCINVOICE ||
                            activeItem === "OMCINVOICE"
                              ? DesignationHover
                              : DesignationLogo
                          }
                          alt="OMCINVOICE Logo"
                          width="35px"
                          style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                        />{" "}
                      </ListItemIcon>

                      <ListItemText
                        // primary="Designation"
                        // className={activeItem === "Designation" ? "active" : ""}
                        // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                        disableTypography
                        primary={
                          <Typography
                            variant="body2"
                            style={{
                              color:
                                hoverStates.OMCINVOICE ||
                                activeItem === "OMCINVOICE"
                                  ? "white"
                                  : "#4A2A7D",
                            }}
                          >
                            OMC INVOICE
                          </Typography>
                        }
                      />
                    </ListItemButtonStyled>
                  </ListItem>
                </Link> */}

                  <Link
                    to="/region"
                    onMouseEnter={() => handleMouseEnter("region")}
                    onMouseLeave={() => handleMouseLeave("region")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "Region" ? "white" : "white",
                        backgroundColor:
                          hoverStates.region || activeItem == "Region"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("Region")}
                      >
                        <ListItemIcon
                          className={
                            hoverStates.region || activeItem === "Region"
                              ? "activecolor"
                              : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color: activeItem == "Region" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.region || activeItem === "Region"
                                ? RegionHover
                                : RegionLogo
                            }
                            alt="Region Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          // primary="Region"
                          // className={activeItem === "Region" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.region || activeItem === "Region"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              Region
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>
                  <Link
                    to="/state"
                    onMouseEnter={() => handleMouseEnter("state")}
                    onMouseLeave={() => handleMouseLeave("state")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "State" ? "white" : "white",
                        backgroundColor:
                          hoverStates.state || activeItem == "State"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("State")}
                      >
                        <ListItemIcon
                          className={
                            hoverStates.state || activeItem === "State"
                              ? "activecolor"
                              : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color: activeItem == "State" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.state || activeItem === "State"
                                ? StateHover
                                : StateLogo
                            }
                            alt="State Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          // primary="State"
                          // className={activeItem === "State" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.state || activeItem === "State"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              State
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>
                  <Link
                    to="/city"
                    onMouseEnter={() => handleMouseEnter("city")}
                    onMouseLeave={() => handleMouseLeave("city")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "City" ? "white" : "white",
                        backgroundColor:
                          hoverStates.city || activeItem == "City"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("City")}
                      >
                        <ListItemIcon
                          className={
                            hoverStates.city || activeItem === "City"
                              ? "activecolor"
                              : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color: activeItem == "City" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.city || activeItem === "City"
                                ? CityHover
                                : CityLogo
                            }
                            alt="city Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          // primary="City"
                          // className={activeItem === "City" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.city || activeItem === "City"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              City
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>
                  <Link
                    to="/territory"
                    onMouseEnter={() => handleMouseEnter("territory")}
                    onMouseLeave={() => handleMouseLeave("territory")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "Territory" ? "white" : "white",
                        backgroundColor:
                          hoverStates.territory || activeItem == "Territory"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("Territory")}
                      >
                        <ListItemIcon
                          className={
                            activeItem === "Territory" ? "activecolor" : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color:
                              activeItem == "Territory" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.territory ||
                              activeItem === "Territory"
                                ? TerritoryHover
                                : TerritoryLogo
                            }
                            alt="State Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          // primary="Territory"
                          // className={activeItem === "Territory" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.territory ||
                                  activeItem === "Territory"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              Territory
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>

                  <Link
                    to="/department"
                    onMouseEnter={() => handleMouseEnter("department")}
                    onMouseLeave={() => handleMouseLeave("department")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "Department" ? "white" : "white",
                        backgroundColor:
                          hoverStates.department || activeItem == "Department"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("Department")}
                      >
                        <ListItemIcon
                          className={
                            hoverStates.department ||
                            activeItem === "Department"
                              ? "activecolor"
                              : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color:
                              activeItem == "Department" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.department ||
                              activeItem === "Department"
                                ? DepartmentHover
                                : DepartmentLogo
                            }
                            alt="Department Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          // primary="Department"
                          // className={activeItem === "Department" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.department ||
                                  activeItem === "Department"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              Department
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>
                  <Link
                    to="/designation"
                    onMouseEnter={() => handleMouseEnter("designation")}
                    onMouseLeave={() => handleMouseLeave("designation")}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        color: activeItem == "Designation" ? "white" : "white",
                        backgroundColor:
                          hoverStates.designation || activeItem == "Designation"
                            ? "#4a2a7d"
                            : "inherit",
                      }}
                    >
                      <ListItemButtonStyled
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          color: "white",
                        }}
                        onClick={() => handleListItemClick("Designation")}
                      >
                        <ListItemIcon
                          className={
                            hoverStates.designation ||
                            activeItem === "Designation"
                              ? "activecolor"
                              : ""
                          }
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color:
                              activeItem == "Designation" ? "white" : "white",
                          }}
                        >
                          <img
                            src={
                              hoverStates.designation ||
                              activeItem === "Designation"
                                ? DesignationHover
                                : DesignationLogo
                            }
                            alt="Designation Logo"
                            width="35px"
                            style={{
                              marginLeft: "1.5rem",
                              marginRight: "1rem",
                            }}
                          />{" "}
                        </ListItemIcon>

                        <ListItemText
                          // primary="Designation"
                          // className={activeItem === "Designation" ? "active" : ""}
                          // sx={{ color: activeItem == 'Client' ? '#fff' : '#fff' }}
                          disableTypography
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  hoverStates.designation ||
                                  activeItem === "Designation"
                                    ? "white"
                                    : "#4A2A7D",
                              }}
                            >
                              Designation
                            </Typography>
                          }
                        />
                      </ListItemButtonStyled>
                    </ListItem>
                  </Link>
                </AccordionDetails>
              </Accordion>
            )}
          </List>

          <Divider />
          {/* <Button
            variant="text"
            onClick={() => {
              handleLogout();
            }}
            style={{
              color: "#2c1256",
              justifyContent: "flex-start",
              padding: "10px",
              paddingBottom: "2rem",
            }}
            fullWidth
          >
            <img
              src={SignOutBlueLogo}
              alt="SignOut Logo"
              width="35px"
              style={{ marginLeft: "0.5rem", marginRight: "1rem" }}
            />
            <Typography style={{ fontWeight: "bold", marginLeft: "1.6rem" }}>
              LOGOUT
            </Typography>
          </Button> */}
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[0]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Main open={open} className="main" sx={{ marginLeft: "1vw" }}>
            <Outlet />
          </Main>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
