import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";


const PersonalDetails = () => {
  const Token = sessionStorage.getItem("token");
  useEffect(() => {
    getProfileData();
  }, []);


  const [ProfileData, setProfileData] = useState("");

  const getProfileData = async () => {
    try {
      const url = "https://app.batuk.in/profile";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const profile = response.data.data;
      console.log("Profile data", profile[0]);
      setProfileData(profile[0]);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  
  // =====================================================
  //     Address
  // :
  // "bharat delhi"
  // EntityType
  // :
  // "NA"
  // Entityemail
  // :
  // ""
  // EntityidentificationNo
  // :
  // ""
  // Entityname
  // :
  // ""
  // Ifsccode
  // :
  // "bharat11111"
  // aadharNo
  // :
  // "121212121212"
  // accHoldername
  // :
  // "bharat"
  // accNo
  // :
  // "1212121212"
  // authpersonName
  // :
  // "bharat"
  // city
  // :
  // "Basar"
  // companyAddress
  // :
  // ""
  // createdAt
  // :
  // "2024-05-29T07:50:17.309Z"
  // dob
  // :
  // "20/07/2001"
  // email
  // :
  // "bharat@gmail.com"
  // is_deleted
  // :
  // 0
  // mobileNo
  // :
  // "9090909090"
  // name
  // :
  // "bharat"
  // panNo
  // :
  // "BHGFTR5544"
  // registerType
  // :
  // "individual"
  // state
  // :
  // "Arunachal Pradesh"
  // updatedAt
  // :
  // "2024-05-29T07:50:17.309Z"
  // userName
  // :
  // "B001"
  // =====================================================
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-sm-12">
            <Paper elevation={3} sx={{ padding: "25px" }}>
              <div class="row g-4 mb-4">
                <h3 className="profileHeader">Profile Details</h3>
                <div class="col">
                  <label for="name" className="mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    aria-label="First name"
                    id="name"
                    value={ProfileData.name}
                  />
                </div>
                <div class="col">
                  <label for="email" className="mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    value={ProfileData.email}
                  />
                </div>
              </div>
              <div class="row g-4 mb-4">
                <div class="col">
                  <label for="name" className="mb-2">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    aria-label="First name"
                    id="name"
                    value={ProfileData.mobileNo}
                  />
                </div>
                <div class="col">
                  <label for="email" className="mb-2">
                    City
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    value={ProfileData.city}
                  />
                </div>
              </div>
              <div class="row g-4 mb-4">
                <div class="col">
                  <label for="name" className="mb-2">
                    State
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    aria-label="First name"
                    id="name"
                    value={ProfileData.state}
                  />
                </div>
                <div class="col">
                  <label for="email" className="mb-2">
                    Address
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    value={ProfileData.Address}
                  />
                </div>
              </div>
              <div class="row g-4 mb-4">
                <div class="col">
                  <label for="name" className="mb-2">
                    PAN Number
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    aria-label="First name"
                    id="name"
                    value={ProfileData.panNo}
                  />
                </div>
                <div class="col">
                  <label for="name" className="mb-2">
                    Aadhar Number
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    aria-label="First name"
                    id="name"
                    value={ProfileData.aadharNo}
                  />
                </div>
              </div>

              <div className="row g-4 mb-4">
                <div class="col">
                  <label for="email" className="mb-2">
                    Account Holder Number
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    value={ProfileData.accHoldername}
                  />
                </div>
                <div class="col">
                  <label for="email" className="mb-2">
                    Account Number
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    value={ProfileData.accNo}
                  />
                </div>
              </div>

              <div class="row g-4 mb-4">
                <div class="col">
                  <label for="name" className="mb-2">
                    Register Type
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    aria-label="First name"
                    id="name"
                    value={ProfileData.registerType}
                  />
                </div>
                <div class="col">
                  <label for="email" className="mb-2">
                    IFSC Code
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    value={ProfileData.Ifsccode}
                  />
                </div>
              </div>
              <div class="row g-4 mb-4">
                <div class="col">
                  <label for="name">Company Address</label>
                  <input
                    type="text"
                    aria-label="First name"
                    id="name"
                    value={ProfileData.companyAddress}
                  />
                </div>
                <div class="col">
                  <label for="email">Username</label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    value={ProfileData.userName}
                  />
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
